<template>
  <!-- Add Wallet -->
  <div
    id="add_wallet"
    class="modal new-modal fade"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Wallet</h5>
          <button type="button" class="close-btn" data-bs-dismiss="modal">
            <span>×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="amt-wrap">
                <div class="form-wrap">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter the Amount"
                  />
                </div>
                <ul class="amt-list">
                  <li>Or</li>
                  <li>
                    <a href="#" class="vary-amt">$50</a>
                  </li>
                  <li>
                    <a href="#" class="vary-amt">$100</a>
                  </li>
                  <li>
                    <a href="#" class="vary-amt">$150</a>
                  </li>
                </ul>
              </div>
              <div class="buyer-method">
                <h6>How are you planning to work with the Buyer? *</h6>
                <label class="custom_radio">
                  <input type="radio" name="payment" />
                  <span class="checkmark"></span>Stripe
                </label>
                <label class="custom_radio">
                  <input type="radio" name="payment" checked />
                  <span class="checkmark"></span>Card
                </label>
              </div>
              <div class="form-wrap form-item">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Name on the Card"
                />
              </div>
              <div class="form-wrap form-item">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Card Number"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-wrap form-item">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Expiry Date"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-wrap form-item">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Security Number"
                />
              </div>
            </div>
            <div class="col-md-12">
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#success_credit"
                data-bs-dismiss="modal"
                class="btn btn-primary w-100"
                >Add to Wallet</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Wallet -->

  <!-- Services Publish -->
  <div id="success_credit" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon">
              <img src="@/assets/img/icons/happy-icon.svg" alt="icon" />
            </div>
            <div class="success-content">
              <h4>Credit Successfully</h4>
              <p>
                Amount of <span>“$200”</span> has been successfully Credited to
                your account with transaction ID of
                <span>“#124454487878874”</span>
              </p>
            </div>
            <div class="col-lg-12 text-center">
              <router-link to="/user/user-wallet" class="btn btn-primary"
                >Back to Wallet</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Services Publish -->
</template>
