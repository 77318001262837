<template>
  <!-- Popular -->
  <section class="popular-section">
    <div class="popular-img">
      <div class="popular-img-left">
        <img src="@/assets/img/bg/banner-bg-04.png" alt="Shape" />
      </div>
      <div class="popular-img-right">
        <img src="@/assets/img/bg/shape-08.png" alt="Shape" />
      </div>
    </div>
    <div class="container">
      <div class="section-header aos" data-aos="fade-up">
        <h2 class="text-white"><span>Popular</span> Categories.</h2>
      </div>
      <div
        class="row row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-2 row-cols-1 align-items-center"
      >
        <!-- Category Grid -->
        <div
          v-for="item in Categories"
          :key="item.slug"
          class="col d-flex aos"
          data-aos="fade-up"
        >
          <div class="category-grid flex-fill">
            <!-- <div class="popular-icon">
              <span>
                <img
                  :src="require(`@/assets/img/icons/${item.Icon}`)"
                  alt="Icon"
                />
              </span>
            </div> -->
            <div class="popular-content">
              <h5>{{ item.category }}</h5>
              <p>{{ item.services.length }} Service</p>
            </div>
            <div class="category-overlay">
              <router-link :to="'/categories/' + item.slug">
                <div class="category-overlay-img">
                  <!-- <img
                    :src="require(`@/assets/img/service/${item.Image}`)"
                    class="img-fluid"
                    alt="Service"
                  /> -->
                  <div class="category-overlay-content">
                    <h5>{{ item.Text }}</h5>
                    <i class="feather-arrow-up-right"></i>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <!-- /Category Grid -->
      </div>
    </div>
  </section>
  <!-- /Popular -->
</template>

<script>
import Categories from "@/data/categories.json";

export default {
  data() {
    return {
      Categories,
    };
  },
};
</script>
