<template>
  <!-- Hero Section -->
  <section class="hero-section">
    <div class="banner-bg-imgs">
      <img
        src="@/assets/img/bg/banner-bg-03.png"
        class="banner-bg-three"
        alt="img"
      />
      <img
        src="@/assets/img/bg/banner-bg-04.png"
        class="banner-bg-four"
        alt="img"
      />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="banner-content aos" data-aos="fade-up">
            <div class="banner-head">
              <h1>Your Gateway to Quality <span>Services.</span></h1>
              <p>
                Connect with professionals to create stunning content and
                achieve your creative goals.
              </p>
            </div>
            <div class="banner-form">
              <form @submit.prevent="submitForm">
                <div class="banner-search-list">
                  <div class="input-block">
                    <label>Category</label>
                    <vue-select
                      id="bannercategory"
                      :options="Category"
                      placeholder="Select"
                    />
                  </div>

                  <div class="input-block border-0">
                    <label>Keyword</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Need Graphic Designer"
                    />
                  </div>
                </div>
                <div class="input-block-btn">
                  <button class="btn btn-primary" type="submit">
                    <i class="fas fa-magnifying-glass"></i> Search
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="banner-img">
            <!-- <div class="banner-img-right">
              <img
                src="@/assets/img/banner-img.png"
                class="img-fluid"
                alt="img"
              />
            </div> -->
            <!-- <img
              src="@/assets/img/bg/banner-small-bg-01.svg"
              class="banner-small-bg-one"
              alt="img"
            /> -->
            <!-- <img
              src="@/assets/img/bg/banner-small-bg-02.png"
              class="banner-small-bg-two"
              alt="img"
            /> -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Hero Section -->
</template>

<script>
import Categories from "@/data/categories.json";

export default {
  data() {
    return {
      Category: Categories[0].services,
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/services");
    },
  },
};
</script>

<style scoped>
.banner-head h1::after {
  display: none !important;
}
</style>
