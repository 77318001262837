<template>
  <div class="user-sidebar">
    <div class="user-head">
      <span class="flex-shrink-0">
        <img
          :src="'https://gravatar.com/avatar/' + emailHash + '?d=identicon'"
          class="img-fluid"
          alt="img"
        />
      </span>
      <div class="user-information">
        <div>
          <h6>{{ user.firstName + " " + user.lastName }}</h6>
          <p>@{{ user.username }}</p>
        </div>
      </div>
    </div>
    <div class="user-body">
      <ul>
        <li>
          <router-link to="/user/user-dashboard">
            <img
              src="@/assets/img/icons/dashboard-icon-01.svg"
              class="img-fluid"
              alt="img"
            />Dashboard
          </router-link>
        </li>
        <li>
          <router-link to="/user/user-services">
            <img
              src="@/assets/img/icons/dashboard-icon-02.svg"
              class="img-fluid"
              alt="img"
            />Manage Services
          </router-link>
        </li>
        <li>
          <router-link to="/user/user-purchase">
            <img
              src="@/assets/img/icons/dashboard-icon-03.svg"
              class="img-fluid"
              alt="img"
            />Purchase
          </router-link>
        </li>
        <li>
          <router-link to="/user/user-sales">
            <img
              src="@/assets/img/icons/dashboard-icon-04.svg"
              class="img-fluid"
              alt="img"
            />Sales
          </router-link>
        </li>
        <li>
          <router-link to="/user/user-files">
            <img
              src="@/assets/img/icons/dashboard-icon-05.svg"
              class="img-fluid"
              alt="img"
            />Files
          </router-link>
        </li>
        <li>
          <router-link to="/user/user-reviews">
            <img
              src="@/assets/img/icons/dashboard-icon-06.svg"
              class="img-fluid"
              alt="img"
            />My Reviews
          </router-link>
        </li>
        <li>
          <router-link to="/user/user-wishlist">
            <img
              src="@/assets/img/icons/dashboard-icon-07.svg"
              class="img-fluid"
              alt="img"
            />Wishlist
          </router-link>
        </li>
        <li>
          <router-link to="/user/user-message">
            <img
              src="@/assets/img/icons/dashboard-icon-08.svg"
              class="img-fluid"
              alt="img"
            />Messages
          </router-link>
        </li>
        <li>
          <router-link to="/user/user-wallet">
            <img
              src="@/assets/img/icons/dashboard-icon-09.svg"
              class="img-fluid"
              alt="img"
            />Wallet
          </router-link>
        </li>
        <li>
          <router-link to="/user/user-payments">
            <img
              src="@/assets/img/icons/dashboard-icon-10.svg"
              class="img-fluid"
              alt="img"
            />Payments
          </router-link>
        </li>
        <li>
          <router-link to="/user/user-settings">
            <img
              src="@/assets/img/icons/dashboard-icon-11.svg"
              class="img-fluid"
              alt="img"
            />Settings
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: null,
      emailHash: "",
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.computeHash();
  },
  methods: {
    async hash() {
      const utf8 = new TextEncoder().encode(
        this.user.email.trim().toLowerCase(),
      );
      const hashBuffer = await crypto.subtle.digest("SHA-256", utf8);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map((bytes) => bytes.toString(16).padStart(2, "0"))
        .join("");

      console.log(hashHex);

      return hashHex;
    },
    async computeHash() {
      this.emailHash = await this.hash(); // Set the computed hash to the data property
    },
  },
};
</script>
