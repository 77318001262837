<template>
  <div class="recent-works">
    <div class="row">
      <div class="col-md-12">
        <div class="title-sec">
          <div class="row align-items-center">
            <div class="col-md-8">
              <h3>Recent Works</h3>
            </div>
          </div>
        </div>
        <div
          class="services-card-slider profile-work-slide listing-services owl-carousel"
        >
          <Carousel
            :wrap-around="true"
            :settings="settings"
            :breakpoints="breakpoints"
          >
            <Slide v-for="item in Profile_Work" :key="item.id">
              <div class="services-grid">
                <div class="services-img">
                  <div class="img-slider owl-carousel">
                    <Carousel
                      :wrap-around="true"
                      :settings-one="settingsOne"
                      :breakpoints-one="breakpointsOne"
                    >
                      <Slide
                        v-for="slideItem in item.Slider"
                        :key="slideItem.id"
                      >
                        <div class="slide-images">
                          <router-link to="/services/service-details">
                            <img
                              :src="
                                require(
                                  `@/assets/img/services/list/${slideItem.Image}`,
                                )
                              "
                              class="img-fluid"
                              alt="Services"
                            />
                          </router-link>
                        </div>
                      </Slide>
                      <template #addons> </template>
                    </Carousel>
                  </div>
                  <div class="card-overlay-badge">
                    <span class="badge bg-info"
                      ><i class="fa-solid fa-circle"></i
                      >{{ item.Category }}</span
                    >
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-info">
                    <div class="star-rate">
                      <span
                        ><i class="fa-solid fa-star"></i>{{ item.Rating }}
                        <span class="fw-semibold ms-1">
                          {{ item.Count }}</span
                        ></span
                      >
                    </div>
                  </div>
                  <div class="services-title text-start">
                    <h3>
                      <router-link to="/services/service-details">
                        {{ item.Title }}
                      </router-link>
                    </h3>
                  </div>
                  <div class="services-card-footer">
                    <h5>{{ item.Price }}</h5>
                    <div class="services-share">
                      <a href="javascript:void(0);">
                        <i class="feather-share-2"></i>
                      </a>
                      <span class="badge">{{ item.DeliveryTime }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
            <template #addons>
              <Navigation />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import Profile_Work from "@/assets/json/profile-work.json";
import "vue3-carousel/dist/carousel.css";

export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      Profile_Work: Profile_Work,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },

      breakpoints: {
        575: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
      settingsOne: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpointsOne: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 1,
          snapAlign: "start",
        },
      },
    };
  },
};
</script>
