<template>
  <!-- Popular Lists -->
  <section class="top-listing-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-header aos" data-aos="fade-up">
            <h2><span>Top</span> Popular Location Based Listings</h2>
          </div>

          <!-- Listing Tabs -->
          <div class="listing-tab">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a
                  href="javascript:void(0);"
                  class="nav-link"
                  :class="{ active: activeTab === 'algiers' }"
                  @click="activeTab = 'algiers'"
                >
                  Algiers <span>(200)</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="javascript:void(0);"
                  class="nav-link"
                  :class="{ active: activeTab === 'oran' }"
                  @click="activeTab = 'oran'"
                >
                  Oran <span>(250)</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="javascript:void(0);"
                  class="nav-link"
                  :class="{ active: activeTab === 'constantine' }"
                  @click="activeTab = 'constantine'"
                >
                  Constantine <span>(340)</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="javascript:void(0);"
                  class="nav-link"
                  :class="{ active: activeTab === 'tizi' }"
                  @click="activeTab = 'tizi'"
                >
                  Tizi Ouzou <span>(230)</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="javascript:void(0);"
                  class="nav-link"
                  :class="{ active: activeTab === 'batna' }"
                  @click="activeTab = 'batna'"
                >
                  Batna <span>(210)</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="javascript:void(0);"
                  class="nav-link"
                  :class="{ active: activeTab === 'annaba' }"
                  @click="activeTab = 'annaba'"
                >
                  Annaba <span>(400)</span>
                </a>
              </li>
            </ul>
          </div>
          <!-- /Listing Tabs -->

          <div class="tab-content country-lists">
            <!-- Algiers -->
            <div
              v-if="activeTab === 'algiers'"
              id="algiers"
              class="tab-pane"
              :class="{ active: activeTab === 'algiers' }"
            >
              <div class="row aos" data-aos="fade-up" data-aos-delay="500">
                <div class="col-md-12">
                  <popular-algiers></popular-algiers>
                </div>
              </div>
            </div>
            <!-- /Algiers -->

            <!-- Oran -->
            <div
              v-if="activeTab === 'oran'"
              id="oran"
              class="tab-pane"
              :class="{ active: activeTab === 'oran' }"
            >
              <div class="row aos" data-aos="fade-up" data-aos-delay="500">
                <div class="col-md-12">
                  <popular-oran></popular-oran>
                </div>
              </div>
            </div>
            <!-- /Oran -->

            <!-- Constantine -->
            <div
              v-if="activeTab === 'constantine'"
              id="constantine"
              class="tab-pane"
              :class="{ active: activeTab === 'constantine' }"
            >
              <div class="row aos" data-aos="fade-up" data-aos-delay="500">
                <div class="col-md-12">
                  <popular-constantine></popular-constantine>
                </div>
              </div>
            </div>
            <!-- /Constantine -->

            <!-- Tizi Ouzou -->
            <div
              v-if="activeTab === 'tizi'"
              id="tizi"
              class="tab-pane"
              :class="{ active: activeTab === 'tizi' }"
            >
              <div class="row aos" data-aos="fade-up" data-aos-delay="500">
                <div class="col-md-12">
                  <popular-tizi></popular-tizi>
                </div>
              </div>
            </div>
            <!-- /Tizi Ouzou -->

            <!-- Batna -->
            <div
              v-if="activeTab === 'batna'"
              id="batna"
              class="tab-pane"
              :class="{ active: activeTab === 'batna' }"
            >
              <div class="row aos" data-aos="fade-up" data-aos-delay="500">
                <div class="col-md-12">
                  <popular-batna></popular-batna>
                </div>
              </div>
            </div>
            <!-- /Batna -->

            <!-- Annaba -->
            <div
              v-if="activeTab === 'annaba'"
              id="annaba"
              class="tab-pane"
              :class="{ active: activeTab === 'annaba' }"
            >
              <div class="row aos" data-aos="fade-up" data-aos-delay="500">
                <div class="col-md-12">
                  <popular-annaba></popular-annaba>
                </div>
              </div>
            </div>
            <!-- /Annaba -->
          </div>

          <div class="cta-section">
            <div class="cta-wrap">
              <div class="row gx-0 align-items-center">
                <div class="col-md-8">
                  <div class="cta-info">
                    <span class="badge"
                      >Explore unique audiovisual services for your needs</span
                    >
                    <h3>Looking to offer or provide a service?</h3>
                  </div>
                </div>
                <div class="col-md-4 text-md-end">
                  <div class="cta-btn">
                    <router-link to="/services" class="btn btn-primary">
                      Find out more<i class="fa-solid fa-caret-right"></i>
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="cta-bg">
                <div class="cta-bg1">
                  <img src="@/assets/img/bg/contact-bg-01.png" alt="Shape" />
                </div>
                <div class="cta-bg2">
                  <img src="@/assets/img/bg/contact-bg-02.png" alt="Shape" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="con-bg">
      <img src="@/assets/img/bg/contact-bg.png" alt="icon" />
    </div>
  </section>

  <!-- /Popular Lists -->
</template>

<script>
export default {
  data() {
    return {
      activeTab: "algiers",
    };
  },
  watch: {
    activeTab() {
      this.$nextTick(() => {
        const carousel = this.$refs.carousel;
        if (carousel) {
          carousel.restart();
        }
      });
    },
  },
};
</script>
