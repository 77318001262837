<template>
  <!-- Sign In -->
  <div class="row gx-0">
    <!-- Banner Content -->
    <div class="col-lg-6">
      <div class="authentication-wrapper">
        <div class="logo-container">
          <router-link to="/">
            <img
              src="@/assets/img/white-logo.svg"
              alt="img"
              class="login-logo"
            />
          </router-link>
        </div>
        <div class="login-bg">
          <img src="@/assets/img/bg/shape-02.png" alt="img" class="shape-02" />
          <img src="@/assets/img/bg/shape-03.png" alt="img" class="shape-03" />
          <img src="@/assets/img/bg/shape-04.png" alt="img" class="shape-04" />
          <img src="@/assets/img/bg/shape-05.png" alt="img" class="shape-05" />
          <img src="@/assets/img/bg/shape-06.png" alt="img" class="shape-06" />
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="login-wrapper">
        <div class="login-content">
          <form @submit.prevent="submitForm">
            <div class="login-userset">
              <div class="login-card">
                <div class="login-heading">
                  <h3>Hi, Welcome Back!</h3>
                  <p>Fill the fields to get into your account</p>
                </div>
                <div class="form-wrap form-focus">
                  <span class="form-icon">
                    <i class="feather-mail"></i>
                  </span>
                  <input type="email" class="form-control floating" />
                  <label class="focus-label">Email</label>
                </div>
                <div class="form-wrap form-focus pass-group">
                  <span class="form-icon" @click="toggleShow">
                    <i
                      :class="{
                        'feather-eye': showPassword,
                        'feather-eye-off': !showPassword,
                      }"
                    ></i>
                  </span>
                  <input
                    :type="showPassword ? 'text' : 'password'"
                    class="pass-input form-control floating"
                  />
                  <label class="focus-label">Password</label>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-wrap">
                      <label class="custom_check mb-0"
                        >Remember Me
                        <input type="checkbox" name="remeber" />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-wrap text-md-end">
                      <router-link to="/forgot-password" class="forgot-link"
                        >Forgot Password?</router-link
                      >
                    </div>
                  </div>
                </div>
                <div class="form-wrap mantadory-info d-none">
                  <p>
                    <i class="feather-alert-triangle"></i>Fill all the fields to
                    submit
                  </p>
                </div>
                <button type="submit" class="btn btn-primary">Sign In</button>
              </div>
              <div class="acc-in">
                <p>
                  Don't have an account?
                  <router-link to="/signup">Sign Up</router-link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- /Login Content -->
  </div>
  <!-- /Sign In -->
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import LoginBanner from "@/assets/json/login-banner.json";
import users from "@/data/users";

export default {
  data() {
    return {
      showPassword: false,
      LoginBanner: LoginBanner,
      settings: {
        itemsToShow: 3,
        snapAlign: "center",
      },
      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 1,
          snapAlign: "start",
        },
      },
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  mounted() {
    // Function to handle the focus event
    function handleFocus(e) {
      const parentFormFocus = findParentWithClass(e.target, "form-focus");
      if (parentFormFocus) {
        parentFormFocus.classList.add("focused");
      }
    }

    // Function to find the nearest parent element with a specific class
    function findParentWithClass(element, className) {
      let parent = element.parentElement;
      while (parent) {
        if (parent.classList.contains(className)) {
          return parent;
        }
        parent = parent.parentElement;
      }
      return null;
    }

    // Get all elements with the class "floating"
    const floatingElements = document.getElementsByClassName("floating");

    // Check if there are any elements with the class "floating"
    if (floatingElements.length > 0) {
      // Loop through each floating element
      for (let i = 0; i < floatingElements.length; i++) {
        const element = floatingElements[i];

        // Add event listener for the focus event
        element.addEventListener("focus", handleFocus);
      }
    }
  },
  methods: {
    submitForm() {
      const email = document.querySelector("input[type='email']").value;
      const password = document.querySelector("input[type='password']").value;

      const user = users.find((user) => user.email === email);

      if (user && user.password === password) {
        localStorage.setItem("user", JSON.stringify(user));
        this.$router.push("/user/user-dashboard");
      } else {
        this.$swal({
          title: "Error!",
          text: "Invalid email or password",
          icon: "error",
          confirmButtonText: "Try again",
        });
      }
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style scoped>
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 2rem 0;
}

.login-logo {
  width: 400px;
  height: auto;
}
</style>
