import { createApp } from "vue";
import { router } from "@/router";
import App from "./App.vue";
import { BootstrapVue3, BToastPlugin } from "bootstrap-vue-3";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/reset.css";
import VueSelect from "vue3-select2-component";
import DatePicker from "vue3-datepicker";
// import Vue3Autocounter from 'vue3-autocounter';
import VueApexCharts from "vue3-apexcharts";
import VueEasyLightbox from "vue-easy-lightbox";

// plugins
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "boxicons/css/boxicons.min.css";
import "@/assets/css/feather.css";
import "@/assets/css/style.css";

/********* Breadcrumb **********/
import Page_Header from "@/components/breadcrumb/page-header.vue";
import UserGigsFilter from "@/components/user-services-filter.vue";

/********* Layouts components **********/
import Loyout_Footer from "@/views/layouts/layout-footer.vue";
import Header_Menu from "@/views/layouts/header-menu.vue";
import User_Sidebar from "@/views/layouts/user-sidebar.vue";
import GridSidebar from "@/views/layouts/grid-sidebar.vue";
import User_Header from "@/views/layouts/user-header.vue";
import GigsLoader from "@/views/layouts/services-loader.vue";
// import GigsScroll from '@/views/layouts/services-scroll.vue'

/********* Pages components **********/
import Settings_Sidebar from "@/views/pages/user/user-settings/settings-sidebar.vue";
import Dashboard_Files from "@/views/pages/user/user-dashboard/dashboard-files.vue";
import Dashboard_Overview from "@/views/pages/user/user-dashboard/dashboard-overview.vue";
import Dashboard_Purchase from "@/views/pages/user/user-dashboard/dashboard-purchase.vue";
import Dashboard_Sales from "@/views/pages/user/user-dashboard/dashboard-sales.vue";
import Dashboard_Statisitcs from "@/views/pages/user/user-dashboard/dashboard-statistics.vue";

import HomeBanner from "@/views/pages/home/home-banner.vue";
import ExplorePopular from "@/views/pages/home/explore-popular.vue";
import ExploreLatest from "@/views/pages/home/explore-latest.vue";
import ExploreTopRatings from "@/views/pages/home/explore-top-ratings.vue";
import ExploreTrending from "@/views/pages/home/explore-trending.vue";
import ExploreServices from "@/views/pages/home/explore-services.vue";
import HomePopular from "@/views/pages/home/home-popular.vue";
import HomeClients from "@/views/pages/home/home-clients.vue";
import PopularList from "@/views/pages/home/popular-list.vue";
import PopularAlgiers from "@/views/pages/home/popular-algiers.vue";
import PopularOran from "@/views/pages/home/popular-oran.vue";
import PopularConstantine from "@/views/pages/home/popular-constantine.vue";
import PopularTizi from "@/views/pages/home/popular-tizi.vue";
import PopularBatna from "@/views/pages/home/popular-batna.vue";
import PopularAnnaba from "@/views/pages/home/popular-annaba.vue";
import HomeTestimonials from "@/views/pages/home/home-testimonials.vue";
import HomeFaq from "@/views/pages/home/home-faq.vue";
import Profile_Service from "@/views/pages/user/user-profile/profile-service.vue";
import Profile_Work from "@/views/pages/user/user-profile/profile-work.vue";
import Service_Filter from "@/views/pages/services/service-filter.vue";
import GigsServiceCarousel from "@/views/pages/services/service/services-service-carousel.vue";
import Service_Slider from "@/views/pages/services/service-details/service-slider.vue";
import Service_Work from "@/views/pages/services/service-details/service-work.vue";
import Service_Review from "@/views/pages/services/service-details/service-review.vue";
import Service_Member from "@/views/pages/services/service-details/service-member.vue";
import Service_Recent from "@/views/pages/services/service-details/service-recent.vue";
import ServiceGridCarousel from "@/views/pages/services/service/service-grid-carousel.vue";
import Chat_Image from "@/views/pages/user/chat/chat-image.vue";

/********* Modal components **********/
import Security_Settings_Modal from "@/components/modal/security-settings-modal.vue";
import Intergration_Settings_Modal from "@/components/modal/intergration-settings-modal.vue";
import User_Profile_Modal from "@/components/modal/user-profile-modal.vue";
import Service_Details_Modal from "@/components/modal/service-details-modal.vue";
import User_Wallet_Modal from "@/components/modal/user-wallet-modal.vue";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

// Define the feature flag before importing Vue
window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = true;

const app = createApp(App);

/********* Breadcrumb **********/
app.component("PageHeader", Page_Header);
app.component("UserGigsFilter", UserGigsFilter);

/********* Layouts components **********/
app.component("LayoutFooter", Loyout_Footer);
app.component("HeaderMenu", Header_Menu);
app.component("UserSidebar", User_Sidebar);
app.component("GridSidebar", GridSidebar);
app.component("UserHeader", User_Header);
app.component("GigsLoader", GigsLoader);
// app.component('services-scroll', GigsScroll)

/********* Pages components **********/
app.component("SettingsSidebar", Settings_Sidebar);
app.component("DashboardFiles", Dashboard_Files);
app.component("DashboardOverview", Dashboard_Overview);
app.component("DashboardPurchase", Dashboard_Purchase);
app.component("DashboardSales", Dashboard_Sales);
app.component("DashboardStatistics", Dashboard_Statisitcs);
app.component("HomeBanner", HomeBanner);
app.component("ExploreServices", ExploreServices);
app.component("ExplorePopular", ExplorePopular);
app.component("ExploreLatest", ExploreLatest);
app.component("ExploreTopRatings", ExploreTopRatings);
app.component("ExploreTrending", ExploreTrending);

app.component("HomePopular", HomePopular);
app.component("HomeClients", HomeClients);
app.component("PopularList", PopularList);
app.component("PopularAlgiers", PopularAlgiers);
app.component("PopularOran", PopularOran);
app.component("PopularConstantine", PopularConstantine);
app.component("PopularTizi", PopularTizi);
app.component("PopularBatna", PopularBatna);
app.component("PopularAnnaba", PopularAnnaba);
app.component("HomeTestimonials", HomeTestimonials);
app.component("HomeFaq", HomeFaq);
app.component("ProfileService", Profile_Service);
app.component("ProfileWork", Profile_Work);
app.component("ServiceFilter", Service_Filter);
app.component("GigsServiceCarousel", GigsServiceCarousel);
app.component("ServiceSlider", Service_Slider);
app.component("ServiceWork", Service_Work);
app.component("ServiceReview", Service_Review);
app.component("ServiceMember", Service_Member);
app.component("ServiceRecent", Service_Recent);
app.component("ServiceGridCarousel", ServiceGridCarousel);
app.component("ChatImage", Chat_Image);

/********* Modal components **********/
app.component("SecuritySettingsModal", Security_Settings_Modal);
app.component("IntergrationSettingsModal", Intergration_Settings_Modal);
app.component("UserProfileModal", User_Profile_Modal);
app.component("ServiceDetailsModal", Service_Details_Modal);
app.component("UserWalletModal", User_Wallet_Modal);

// app.component('vue3-autocounter', Vue3Autocounter)
app.component("VueSelect", VueSelect);
app.component("DatePicker", DatePicker);
app.use(VueSweetalert2);
app.use(VueApexCharts);
app.use(VueEasyLightbox).use(BootstrapVue3).use(BToastPlugin).use(Antd);
app.use(router).mount("#app");
