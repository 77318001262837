<template>
  <div class="col-lg-8">
    <div class="service-wrap my-profile-info">
      <div class="my-profile-details">
        <div class="user-details">
          <div class="user-img">
            <img src="@/assets/img/user/user-05.jpg" alt="img" />
          </div>
          <div class="user-info">
            <h5>
              <span class="me-2">Adrian Revolt</span>
              <span class="badge badge-success"
                ><i class="fa-solid fa-circle"></i> Online</span
              >
            </h5>
            <h6>An abundance of creativity</h6>
            <p><i class="fa-solid fa-star"></i>Ratings 5.0 (45 Reviews)</p>
          </div>
        </div>
        <div class="user-edit">
          <a href=""><img src="@/assets/img/icons/user-edit.svg" alt="" /></a>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-sm-6">
          <div class="more-details">
            <span class="icon-info"
              ><img src="@/assets/img/icons/map-pin-heart.svg" alt=""
            /></span>
            <h6>From<span>United States</span></h6>
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="more-details">
            <span class="icon-info"
              ><img src="@/assets/img/icons/user-star.svg" alt=""
            /></span>
            <h6>Member SInce<span>25 Jan 2024</span></h6>
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="more-details">
            <span class="icon-info"
              ><img src="@/assets/img/icons/language-katakana.svg" alt=""
            /></span>
            <h6>Speaks<span>English, Portugese</span></h6>
          </div>
        </div>
      </div>
    </div>
    <!-- About Me -->
    <div class="service-wrap">
      <h3>About Me</h3>
      <p>
        Hello, Greetings! My name is Adrian, a professional embroidery digitizer
        who converts an Image into embroidery files such as DST, PES or any
        other. I can produce an embroidery design file without any fabric
        puckering. I'm the guy who has more than 15 years of experience in the
        field of embroidery design digitizing. I love what I do because
        embroidery digitizing is my passion and profession. so, get in touch
        with me if you have any question. thank you!
      </p>
    </div>
    <!-- /About Me -->

    <!-- Skills -->
    <div class="service-wrap skills-wrap">
      <h3>Skills</h3>
      <ul class="my-skills">
        <li>
          <span><i class="fa-solid fa-circle"></i>Logo design</span>
        </li>
        <li>
          <span><i class="fa-solid fa-circle"></i>Graphics Design</span>
        </li>
        <li>
          <span><i class="fa-solid fa-circle"></i>Adobe illustrator</span>
        </li>
      </ul>
    </div>
    <!-- /Skills -->

    <!-- Recent Works -->
    <div class="service-wrap">
      <div class="row align-items-center">
        <div class="col-sm-8">
          <h3>Checkout My Recent Works</h3>
        </div>
        <div class="col-sm-4">
          <div class="owl-nav mynav1 nav-control"></div>
        </div>
      </div>
      <div class="owl-carousel recent-carousel">
        <Carousel
          :wrap-around="true"
          :settings="settings"
          :breakpoints="breakpoints"
        >
          <Slide v-for="record in User_Profile" :key="record.id">
            <div class="recent-img">
              <img
                :src="require(`@/assets/img/service/${record.Image}`)"
                class="img-fluid"
                alt="Slider Img"
              />
            </div>
          </Slide>
          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
    </div>
    <!-- /Recent Works -->

    <!-- Review Lists -->
    <div class="review-widget">
      <div class="review-title sort-search-services">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <h3>Reviews (45)</h3>
          </div>
          <div class="col-sm-6">
            <div class="search-filter-selected">
              <div class="form-group mb-0">
                <span class="sort-text">Sort By</span>
                <vue-select
                  id="datesel"
                  :options="DateSel"
                  placeholder="Recommended"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul class="review-lists">
        <li>
          <div class="review-wrap">
            <div class="review-user-info">
              <div class="review-img">
                <img src="@/assets/img/user/user-01.jpg" alt="img" />
              </div>
              <div class="reviewer-info">
                <div class="reviewer-loc">
                  <h6>
                    <a href="javascript:void(0);">kadajsalamander</a>
                  </h6>
                  <p><i class="feather-map-pin"></i>London</p>
                </div>
                <div class="reviewer-rating">
                  <div class="star-rate">
                    <span class="ratings">
                      <i class="fa-solid fa-star filled"></i>
                      <i class="fa-solid fa-star filled"></i>
                      <i class="fa-solid fa-star filled"></i>
                      <i class="fa-solid fa-star filled"></i>
                      <i class="fa-solid fa-star filled"></i>
                    </span>
                    <span class="rating-count">5.0 </span>
                  </div>
                  <p>1 Months ago</p>
                </div>
              </div>
            </div>
            <div class="review-content">
              <p>
                I recently hired a him to help me with a project and I must say,
                I am extremely impressed
              </p>
              <a href="javascript:void(0);" class="reply-btn"
                ><i class="feather-corner-up-left"></i>Reply</a
              >
            </div>
          </div>
        </li>
        <li>
          <div class="review-wrap">
            <div class="review-user-info">
              <div class="review-img">
                <img src="@/assets/img/user/user-11.jpg" alt="img" />
              </div>
              <div class="reviewer-info">
                <div class="reviewer-loc">
                  <h6><a href="javascript:void(0);">Simon</a></h6>
                  <p><i class="feather-map-pin"></i>Newyork</p>
                </div>
                <div class="reviewer-rating">
                  <div class="star-rate">
                    <span class="ratings">
                      <i class="fa-solid fa-star filled"></i>
                      <i class="fa-solid fa-star filled"></i>
                      <i class="fa-solid fa-star filled"></i>
                      <i class="fa-solid fa-star filled"></i>
                      <i class="fa-solid fa-star"></i>
                    </span>
                    <span class="rating-count">4.0 </span>
                  </div>
                  <p>15 days ago</p>
                </div>
              </div>
            </div>
            <div class="review-content">
              <p>Firstly, the his communication skills were top-notch.</p>
              <a href="javascript:void(0);" class="reply-btn"
                ><i class="feather-corner-up-left"></i>Reply</a
              >
            </div>
          </div>
        </li>
        <li>
          <div class="review-wrap">
            <div class="review-user-info">
              <div class="review-img">
                <img src="@/assets/img/user/user-04.jpg" alt="img" />
              </div>
              <div class="reviewer-info">
                <div class="reviewer-loc">
                  <h6><a href="javascript:void(0);">Andy</a></h6>
                  <p><i class="feather-map-pin"></i>Bulgaria</p>
                </div>
                <div class="reviewer-rating">
                  <div class="star-rate">
                    <span class="ratings">
                      <i class="fa-solid fa-star filled"></i>
                      <i class="fa-solid fa-star filled"></i>
                      <i class="fa-solid fa-star filled"></i>
                      <i class="fa-solid fa-star filled"></i>
                      <i class="fa-solid fa-star"></i>
                    </span>
                    <span class="rating-count">4.0 </span>
                  </div>
                  <p>1 Months ago</p>
                </div>
              </div>
            </div>
            <div class="review-content">
              <p>
                One of the things that stood out to me the most was the his
                ability to meet deadlines.
              </p>
              <a href="javascript:void(0);" class="reply-btn"
                ><i class="feather-corner-up-left"></i>Reply</a
              >
            </div>
          </div>
        </li>
        <li>
          <div class="review-wrap">
            <div class="review-user-info">
              <div class="review-img">
                <img src="@/assets/img/user/user-06.jpg" alt="img" />
              </div>
              <div class="reviewer-info">
                <div class="reviewer-loc">
                  <h6><a href="javascript:void(0);">Dane jose</a></h6>
                  <p><i class="feather-map-pin"></i>Sweden</p>
                </div>
                <div class="reviewer-rating">
                  <div class="star-rate">
                    <span class="ratings">
                      <i class="fa-solid fa-star filled"></i>
                      <i class="fa-solid fa-star filled"></i>
                      <i class="fa-solid fa-star filled"></i>
                      <i class="fa-solid fa-star filled"></i>
                      <i class="fa-solid fa-star filled"></i>
                    </span>
                    <span class="rating-count">5.0 </span>
                  </div>
                  <p>1 Months ago</p>
                </div>
              </div>
            </div>
            <div class="review-content">
              <p>
                Overall, I highly recommend this freelancer to anyone looking
                for high-quality work.
              </p>
              <a href="javascript:void(0);" class="reply-btn"
                ><i class="feather-corner-up-left"></i>Reply</a
              >
            </div>
          </div>
          <ul>
            <li>
              <div class="review-wrap">
                <div class="review-user-info">
                  <div class="review-img">
                    <img src="@/assets/img/user/user-02.jpg" alt="img" />
                  </div>
                  <div class="reviewer-info">
                    <div class="reviewer-loc">
                      <h6><a href="javascript:void(0);">Harry</a></h6>
                      <p><i class="feather-map-pin"></i>France</p>
                    </div>
                    <div class="reviewer-rating">
                      <div class="star-rate">
                        <span class="ratings">
                          <i class="fa-solid fa-star filled"></i>
                          <i class="fa-solid fa-star filled"></i>
                          <i class="fa-solid fa-star filled"></i>
                          <i class="fa-solid fa-star filled"></i>
                          <i class="fa-solid fa-star filled"></i>
                        </span>
                        <span class="rating-count">5.0 </span>
                      </div>
                      <p>1 Months ago</p>
                    </div>
                  </div>
                </div>
                <div class="review-content">
                  <p>Thank you</p>
                  <a href="javascript:void(0);" class="reply-btn"
                    ><i class="feather-corner-up-left"></i>Reply</a
                  >
                </div>
              </div>
            </li>
          </ul>
        </li>
      </ul>

      <div class="pagination">
        <ul>
          <li>
            <a href="javascript:void(0);" class="previous"
              ><i class="fa-solid fa-chevron-left"></i
            ></a>
          </li>
          <li>
            <a href="javascript:void(0);" class="active">1</a>
          </li>
          <li>
            <a href="javascript:void(0);">2</a>
          </li>
          <li>
            <a href="javascript:void(0);">3</a>
          </li>
          <li>
            <a href="javascript:void(0);">4</a>
          </li>
          <li>
            <a href="javascript:void(0);">5</a>
          </li>
          <li>
            <a href="javascript:void(0);" class="next"
              ><i class="fa-solid fa-chevron-right"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
    <!-- /Review Lists -->
  </div>
</template>
<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import User_Profile from "@/assets/json/user-profile.json";
export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      DateSel: ["Recommended", "Date"],
      User_Profile: User_Profile,
      title: "Home",
      text: "Blog Grid",
      text1: "Blog Grid",
      settings: {
        itemsToShow: 3,
        snapAlign: "center",
      },

      breakpoints: {
        575: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
};
</script>
