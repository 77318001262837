<template>
  <div class="recent-works">
    <div class="row">
      <div class="col-md-12">
        <div class="title-sec">
          <div class="row align-items-center">
            <div class="col-md-8">
              <h3>Recent Works</h3>
            </div>
            <div class="col-md-4">
              <div class="owl-nav worknav nav-control nav-top"></div>
            </div>
          </div>
        </div>
        <div class="services-slider owl-carousel">
          <Carousel
            :wrap-around="true"
            :settings="settings"
            :breakpoints="breakpoints"
          >
            <Slide v-for="item in Service_Recent" :key="item.id">
              <div class="services-grid">
                <div class="services-img">
                  <div class="img-slider">
                    <Carousel
                      :wrap-around="true"
                      :settings-one="settingsOne"
                      :breakpoints-one="breakpointsOne"
                    >
                      <Slide
                        v-for="slideItem in item.Slider"
                        :key="slideItem.id"
                      >
                        <div class="slide-images">
                          <router-link to="/services/service-details"
                            ><img
                              :src="
                                require(
                                  `@/assets/img/services/${slideItem.Image}`,
                                )
                              "
                              class="img-fluid"
                              alt="img"
                          /></router-link>
                        </div>
                      </Slide>
                      <template #addons>
                        <Pagination />
                      </template>
                    </Carousel>
                  </div>
                  <div class="card-overlay-badge">
                    <router-link to="/services/service">
                      <span :class="item.BadgeClass1">
                        <i :class="item.BadgeIcon1"></i>{{ item.Badge1 }}
                      </span>
                    </router-link>
                  </div>
                  <div class="fav-selection">
                    <a href="javascript:void(0);" :class="item.VideoIcon2"
                      ><i :class="item.VideoClass2"></i
                    ></a>
                    <a href="javascript:void(0);" class="fav-icon"
                      ><i class="feather-heart"></i
                    ></a>
                  </div>
                  <div class="user-thumb">
                    <router-link to="/user/user-profile"
                      ><img
                        :src="require(`@/assets/img/user/${item.Avatar}`)"
                        alt="img"
                    /></router-link>
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-info">
                    <router-link to="/services/service"
                      ><span class="badge bg-primary-light">{{
                        item.Roll
                      }}</span></router-link
                    >
                    <p><i class="feather-map-pin"></i>{{ item.Location }}</p>
                  </div>
                  <div class="services-title text-start">
                    <h3>
                      <router-link to="/services/service-details">{{
                        item.Content
                      }}</router-link>
                    </h3>
                  </div>
                  <div class="star-rate text-start">
                    <span
                      ><i class="fa-solid fa-star"></i>{{ item.Review }}</span
                    >
                  </div>
                  <div class="services-card-footer">
                    <div>
                      <a href="javascript:void(0);" class="share-icon"
                        ><i class="feather-share-2"></i
                      ></a>
                      <span class="badge">{{ item.Delivery }}</span>
                    </div>
                    <h5>{{ item.Price }}</h5>
                  </div>
                </div>
              </div>
            </Slide>
            <template #addons>
              <Navigation />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import Service_Recent from "@/assets/json/service-recent.json";
import "vue3-carousel/dist/carousel.css";

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      Service_Recent: Service_Recent,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },

      breakpoints: {
        575: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
      settingsOne: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpointsOne: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 1,
          snapAlign: "start",
        },
      },
    };
  },
};
</script>
