<template>
  <user-header></user-header>

  <!-- Breadcrumb -->
  <div class="breadcrumb-bar breadcrumb-bar-info">
    <div class="breadcrumb-img">
      <div class="breadcrumb-left">
        <img src="@/assets/img/bg/banner-bg-03.png" alt="img" />
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-12">
          <nav aria-label="breadcrumb" class="page-breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </li>
              <li class="breadcrumb-item" aria-current="page">Services</li>
              <li class="breadcrumb-item" aria-current="page">
                Programming & Tech
              </li>
            </ol>
          </nav>
          <div class="slide-title-wrap">
            <div class="row align-items-center">
              <div class="col-md-8">
                <div class="slider-title">
                  <h2>
                    Browse Listing & More
                    <span class="text-primary">“ 900 Services ”</span>
                  </h2>
                </div>
              </div>
              <div class="col-md-4 text-md-end">
                <div class="owl-nav service-nav nav-control nav-top"></div>
              </div>
            </div>
          </div>

          <!-- Service Slider -->
          <div class="service-sliders owl-carousel">
            <Carousel
              :wrap-around="true"
              :settings="settings"
              :breakpoints="breakpoints"
            >
              <Slide v-for="item in ServiceSlider" :key="item.id">
                <div class="service-box">
                  <div class="service-info text-start">
                    <span class="service-icon">
                      <img
                        :src="require(`@/assets/img/icons/${item.Image}`)"
                        alt="icon"
                      />
                    </span>
                    <div class="servive-name">
                      <h5>
                        <router-link to="/services/service-grid-sidebar">{{
                          item.Title
                        }}</router-link>
                      </h5>
                      <p>{{ item.Services }}</p>
                    </div>
                  </div>
                  <router-link to="/services/service-grid-sidebar"
                    ><i class="feather-arrow-up-right"></i
                  ></router-link>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
          <!-- /Service Slider -->
        </div>
      </div>
    </div>
  </div>
  <!-- /Breadcrumb -->

  <!-- Page Content -->
  <div class="page-content">
    <div class="container">
      <!-- Title -->
      <div class="title-section">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="title-header">
              <h3>Programming & Tech</h3>
              <p>View all your Services for your Business</p>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="title-filter">
              <div class="form-group search-group">
                <span class="search-icon"><i class="feather-search"></i></span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search Category"
                />
              </div>
              <div class="search-filter-selected">
                <div class="form-group">
                  <span class="sort-text">Sort By</span>
                  <vue-select
                    id="sortarrivals"
                    :options="Arrivals"
                    placeholder="New Arrivals"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Title -->

      <!-- Search Details -->
      <div class="service-services">
        <div class="row">
          <!-- Sidebar -->
          <div class="col-lg-3 theiaStickySidebar">
            <grid-sidebar></grid-sidebar>
          </div>
          <!-- /Sidebar -->

          <div class="col-lg-9">
            <div class="row">
              <service-grid-carousel></service-grid-carousel>
            </div>
          </div>
        </div>
      </div>
      <!-- /Service Details -->
    </div>
  </div>
  <!-- /Page Content -->

  <layout-footer></layout-footer>
</template>

<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import ServiceSlider from "@/assets/json/service-sliders.json";
import "vue3-carousel/dist/carousel.css";

export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      Arrivals: [
        "New Arrivals",
        "Featured",
        "Price: High to Low",
        "Price: Low to High",
      ],
      ServiceSlider: ServiceSlider,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        575: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
};
</script>
