<template>
  <user-header></user-header>
  <page-header :title="title" :text="text" :text1="text1" />
  <section class="team-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="team-slider owl-carousel">
            <Carousel
              :wrap-around="true"
              :settings="settings"
              :breakpoints="breakpoints"
            >
              <Slide v-for="record in Team_Carousel" :key="record.id">
                <div class="team-grid">
                  <div class="team-img">
                    <div class="team-overlay-img">
                      <img
                        :src="require(`@/assets/img/team/${record.Image}`)"
                        class="img-fluid"
                        alt="Team"
                      />
                    </div>
                    <div class="team-view-btn">
                      <router-link
                        to="/team/team-details"
                        class="btn btn-primary"
                        >View Detail</router-link
                      >
                    </div>
                  </div>
                  <div class="team-content">
                    <div class="team-title">
                      <h4>
                        <router-link to="/team/team-details">{{
                          record.Name
                        }}</router-link>
                      </h4>
                      <p>{{ record.Role }}</p>
                    </div>
                    <div class="team-social-links">
                      <a href="#" target="_blank">
                        <i class="feather-facebook hi-icon"></i>
                      </a>
                      <a href="#" target="_blank">
                        <i class="feather-twitter hi-icon"></i>
                      </a>
                      <a href="#" target="_blank">
                        <i class="feather-linkedin hi-icon"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Pagination />
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <layout-footer></layout-footer>
</template>
<script>
import { Carousel, Pagination, Slide } from "vue3-carousel";
import Team_Carousel from "@/assets/json/team-carousel.json";
import "vue3-carousel/dist/carousel.css";

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      Team_Carousel: Team_Carousel,
      title: "Home",
      text: "Team",
      text1: "Team",
      settings: {
        itemsToShow: 3,
        snapAlign: "center",
      },

      breakpoints: {
        575: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
};
</script>
