<template>
  <user-header></user-header>
  <page-header :title="title" :text="text" :text1="text1" />
  <div class="page-content content">
    <div class="container">
      <div class="row">
        <!-- Sidebar -->
        <div class="col-lg-4 col-xl-3 theiaStickySidebar">
          <div class="stickysidebar">
            <user-sidebar></user-sidebar>
          </div>
        </div>
        <!-- /Sidebar -->

        <div class="col-xl-9 col-lg-8">
          <div class="dashboard-header">
            <div class="main-title">
              <h3>Overview</h3>
            </div>
            <div class="search-filter-selected select-icon">
              <div class="form-group">
                <span class="sort-text"><i class="feather-calendar"></i></span>
                <vue-select
                  id="jansel"
                  :options="JanSel"
                  placeholder="Jan 2024"
                />
              </div>
            </div>
          </div>

          <!-- Overview -->
          <dashboard-overview></dashboard-overview>
          <!-- /Overview -->

          <!-- <dashboard-recent></dashboard-recent> -->

          <div class="row">
            <!-- Sales Statistics -->
            <dashboard-statistics></dashboard-statistics>
            <!-- /Sales Statistics -->
          </div>

          <div class="row">
            <!-- Recent Payments -->
            <div class="col-lg-12 d-flex">
              <div class="card dashboard-card card-service flex-fill">
                <div class="card-header">
                  <div class="service-card-head">
                    <h4 class="card-title">Services</h4>
                    <ul class="services-list nav">
                      <li>
                        <a
                          href="#"
                          class="active"
                          data-bs-toggle="tab"
                          data-bs-target="#purchase"
                          >Purchase</a
                        >
                      </li>
                      <li>
                        <a href="#" data-bs-toggle="tab" data-bs-target="#sales"
                          >Sales</a
                        >
                      </li>
                    </ul>
                  </div>
                  <router-link to="/user/user-purchase" class="view-link"
                    >View All<i class="feather-arrow-right"></i
                  ></router-link>
                </div>
                <div class="card-body p-0">
                  <div class="tab-content">
                    <dashboard-purchase></dashboard-purchase>

                    <dashboard-sales></dashboard-sales>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Recent Payments -->

            <!-- Files -->
            <!-- <dashboard-files></dashboard-files> -->
            <!-- /Files -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <layout-footer></layout-footer>
</template>
<script>
export default {
  data() {
    return {
      JanSel: ["Jan 2024", "Feb 2024"],
      title: "Home",
      text: "User Dashboard",
      text1: "User Dashboard",
    };
  },
};
</script>
