<template>
  <user-header></user-header>
  <page-header :title="title" :text="text" :text1="text1" />
  <section class="team-section team-section-list">
    <div class="container">
      <div class="row">
        <!-- Team List -->
        <div
          v-for="record in Team_Grid"
          :key="record.id"
          class="col-lg-4 col-md-6 d-flex"
        >
          <div class="team-grid w-100">
            <div class="team-img">
              <div class="team-overlay-img">
                <img
                  :src="require(`@/assets/img/team/${record.Image}`)"
                  class="img-fluid"
                  alt="Team"
                />
              </div>
              <div class="team-view-btn">
                <router-link to="/team/team-details" class="btn btn-primary"
                  >View Detail</router-link
                >
              </div>
            </div>
            <div class="team-content">
              <div class="team-title">
                <h4>
                  <router-link to="/team/team-details">{{
                    record.Name
                  }}</router-link>
                </h4>
                <p>{{ record.Role }}</p>
              </div>
              <div class="team-social-links">
                <a href="#" target="_blank">
                  <i class="feather-facebook hi-icon"></i>
                </a>
                <a href="#" target="_blank">
                  <i class="feather-twitter hi-icon"></i>
                </a>
                <a href="#" target="_blank">
                  <i class="feather-linkedin hi-icon"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- /Team List -->
      </div>

      <!-- Pagination -->
      <div class="pagination">
        <ul>
          <li>
            <a href="javascript:void(0);" class="previous"
              ><i class="fa-solid fa-chevron-left"></i
            ></a>
          </li>
          <li>
            <a href="javascript:void(0);" class="active">1</a>
          </li>
          <li>
            <a href="javascript:void(0);">2</a>
          </li>
          <li>
            <a href="javascript:void(0);">3</a>
          </li>
          <li>
            <a href="javascript:void(0);">4</a>
          </li>
          <li>
            <a href="javascript:void(0);">5</a>
          </li>
          <li>
            <a href="javascript:void(0);" class="next"
              ><i class="fa-solid fa-chevron-right"></i
            ></a>
          </li>
        </ul>
      </div>
      <!-- /Pagination -->
    </div>
  </section>
  <layout-footer></layout-footer>
</template>
<script>
import Team_Grid from "@/assets/json/team-grid.json";
export default {
  data() {
    return {
      Team_Grid: Team_Grid,
      title: "Home",
      text: "Team",
      text1: "Team",
    };
  },
};
</script>
