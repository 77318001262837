<template>
  <div class="slider-card">
    <div id="large-img" class="owl-carousel service-slider">
      <Carousel v-model="currentSlide" :items-to-show="1" :wrap-around="false">
        <Slide
          v-for="(image, index) in images"
          :key="index"
          class="product-img"
        >
          <div class="service-img-wrap">
            <img
              :src="require(`@/assets/img/service/${image.src}`)"
              class="img-fluid"
              alt="Slider Img"
            />
          </div>
        </Slide>
      </Carousel>
    </div>
    <div id="small-img" class="owl-carousel slider-nav-thumbnails">
      <Carousel
        id="small-img"
        ref="carousel"
        v-model="currentSlide"
        :items-to-show="4"
        :wrap-around="true"
      >
        <Slide v-for="(image, index) in imagesOne" :key="index">
          <div @click="slideTo(index)">
            <img
              :src="require(`@/assets/img/service/${image.src}`)"
              class="img-fluid"
              alt="Slider Img"
            />
          </div>
        </Slide>
        <template #addons>
          <Navigation />
        </template>
      </Carousel>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      images: [
        { src: "service-slide-01.jpg", alt: "Slider Img" },
        { src: "service-slide-02.jpg", alt: "Slider Img" },
        { src: "service-slide-03.jpg", alt: "Slider Img" },
        { src: "service-slide-04.jpg", alt: "Slider Img" },
        { src: "service-slide-05.jpg", alt: "Slider Img" },
      ],
      imagesOne: [
        { src: "service-slide-01.jpg", alt: "Slider Img" },
        { src: "service-slide-02.jpg", alt: "Slider Img" },
        { src: "service-slide-03.jpg", alt: "Slider Img" },
        { src: "service-slide-04.jpg", alt: "Slider Img" },
        { src: "service-slide-05.jpg", alt: "Slider Img" },
      ],
      currentSlide: 0,
    };
  },
  methods: {
    slideTo(val) {
      this.currentSlide = val;
    },
  },
});
</script>
