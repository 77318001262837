<template>
  <div class="col-lg-4 theiaStickySidebar">
    <div class="service-widget">
      <div
        class="service-amt d-flex align-items-center justify-content-between"
      >
        <p>Price</p>
        <h2>
          $256 <span class="text-decoration-line-through fs-6">$300</span>
        </h2>
      </div>
      <a
        href="#"
        data-bs-toggle="modal"
        data-bs-target="#order_details"
        class="btn btn-primary w-100"
        ><i class="feather-shopping-cart"></i> Buy this service</a
      >
      <div class="row gx-3 row-gap-3">
        <div class="col-xl-4 col-lg-6 col-sm-4 col-6">
          <div class="buy-box">
            <i class="feather-clock"></i>
            <p>Delivery Time</p>
            <h6>1 day</h6>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-sm-4 col-6">
          <div class="buy-box">
            <i class="feather-cloud"></i>
            <p>Total Sales</p>
            <h6>15</h6>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-sm-4 col-6">
          <div class="buy-box">
            <i class="feather-eye"></i>
            <p>Total Views</p>
            <h6>800</h6>
          </div>
        </div>
      </div>
    </div>
    <div class="service-widget member-widget">
      <div class="user-details">
        <div class="user-img">
          <img src="@/assets/img/user/user-05.jpg" alt="img" />
        </div>
        <div class="user-info">
          <h5>
            <span class="me-2">Adrian Revolt</span>
            <span class="badge badge-success"
              ><i class="fa-solid fa-circle"></i> Online</span
            >
          </h5>
          <p><i class="fa-solid fa-star"></i>Ratings 5.0 (45 Reviews)</p>
        </div>
      </div>
      <ul class="member-info">
        <li>
          From
          <span>United States</span>
        </li>
        <li>
          Member Since
          <span>25 Jan 2024</span>
        </li>
        <li>
          Speaks
          <span>English, Portugese</span>
        </li>
        <li>
          Last Project Delivery
          <span>29 Jan 2024</span>
        </li>
        <li>
          Avg Response Time
          <span>About 8 hours</span>
        </li>
      </ul>
      <div class="about-me">
        <h6>About Me</h6>
        <p>
          Hello, Greetings! My name is Adrian, and I am an experienced affiliate
          marketer and website developer<span class="more-content"
            >I have over five years experience in digital affiliate marketing &
            WordPress website development.</span
          >
        </p>
        <a href="javascript:void(0);" class="read-more">Read More</a>
      </div>
      <a
        href="#"
        data-bs-toggle="modal"
        data-bs-target="#contact_me"
        class="btn btn-primary mb-0 w-100"
        >Contact Me</a
      >
    </div>
  </div>
</template>
