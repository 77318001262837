<template>
  <div class="row">
    <div class="col-md-3 d-flex">
      <div class="dash-widget flex-fill">
        <span class="dash-icon bg-warning">
          <img
            src="@/assets/img/icons/check-icon.svg"
            class="img-fluid"
            alt="img"
          />
        </span>
        <p>Completed Jobs</p>
        <h3>08</h3>
      </div>
    </div>
    <div class="col-md-3 d-flex">
      <div class="dash-widget flex-fill">
        <span class="dash-icon bg-success">
          <img
            src="@/assets/img/icons/section-icon.svg"
            class="img-fluid"
            alt="img"
          />
        </span>
        <p>Recent Services</p>
        <h3>15</h3>
      </div>
    </div>
    <div class="col-md-6 d-flex">
      <div class="dash-earning flex-fill">
        <div class="earning-info">
          <p>Your Earning This month</p>
          <h3>$1,57,815</h3>
          <h6>Update your <span>setting</span></h6>
        </div>
        <div class="earning-btn">
          <router-link to="/user/user-wallet" class="btn btn-primary"
            >Wallet</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
