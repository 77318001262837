<template>
  <div ref="pageLoader" class="loader-main">
    <span class="page-loader"></span>
  </div>
</template>

<script>
import { ref, onMounted, nextTick } from "vue";

export default {
  setup() {
    const pageLoader = ref(null);

    onMounted(async () => {
      await nextTick(); // Wait for DOM to update
      setTimeout(() => {
        if (pageLoader.value) {
          pageLoader.value.style.opacity = "0";
          setTimeout(() => {
            if (pageLoader.value) {
              pageLoader.value.style.display = "none";
            }
          }, 500);
        }
      }, 500);
    });
    return {
      pageLoader,
    };
  },
};
</script>
