<template>
  <user-header></user-header>
  <div class="breadcrumb-bar breadcrumb-bar-info breadcrumb-info">
    <div class="breadcrumb-img">
      <div class="breadcrumb-left">
        <img src="@/assets/img/bg/banner-bg-03.png" alt="img" />
      </div>
    </div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-7 col-12">
          <nav aria-label="breadcrumb" class="page-breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link to="/services/service">Services</router-link>
              </li>
              <li class="breadcrumb-item" aria-current="page">
                Digital Marketing
              </li>
            </ol>
          </nav>
          <h2 class="breadcrumb-title">
            I will design, redesign wordpress website using elementor pro
          </h2>
          <ul class="info-links">
            <li><i class="feather-calendar"></i>Created 3 weeks ago</li>
            <li class="order-count">
              <i class="feather-folder"></i>15 Order in queue
            </li>
          </ul>
        </div>
        <div class="col-lg-5 col-12">
          <ul class="breadcrumb-links">
            <li>
              <a href="#"
                ><span><i class="feather-heart"></i></span>Add to Wishlist</a
              >
            </li>
            <li>
              <a href="#"
                ><span><i class="feather-share-2"></i></span>Share this
                services</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="page-content content">
    <div class="container">
      <div class="row">
        <!-- Service Details -->
        <div class="col-lg-8">
          <!-- Slider -->
          <service-slider></service-slider>
          <!-- /Slider -->

          <!-- Extra Services -->
          <div class="extra-service">
            <h3>Get more with Extra Services</h3>
            <ul class="service-time">
              <li>
                <div class="row align-items-center">
                  <div class="col-md-7">
                    <div class="delivery-info">
                      <h6>Full website design and redesign using Elementor</h6>
                      <p>Delivery in 1 day</p>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="delivery-amt">
                      <h6 class="amt">+200</h6>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="delivery-add">
                      <a
                        href="javascript:void(0);"
                        class="btn btn-light-primary add-btn"
                        :class="{ active: isActive }"
                        @click="toggleActive"
                        ><i
                          :class="{
                            'feather-check': isActive,
                            'feather-plus-circle': !isActive,
                          }"
                        ></i>
                        <span v-if="!isActive">Add</span>
                        <span v-else>Added</span></a
                      >
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="row align-items-center">
                  <div class="col-md-7">
                    <div class="delivery-info">
                      <h6>Mobile responsiveness to ensure a seamless user</h6>
                      <p>Delivery in 1 day</p>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="delivery-amt">
                      <h6 class="amt">+500</h6>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="delivery-add">
                      <a
                        href="javascript:void(0);"
                        class="btn btn-light-primary add-btn"
                        :class="{ active: isActiveOne }"
                        @click="toggleActiveOne"
                        ><i
                          :class="{
                            'feather-check': isActiveOne,
                            'feather-plus-circle': !isActiveOne,
                          }"
                        ></i>
                        <span v-if="!isActiveOne">Add</span>
                        <span v-else>Added</span></a
                      >
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="row align-items-center">
                  <div class="col-md-7">
                    <div class="delivery-info">
                      <h6>Speed Optimization</h6>
                      <p>Delivery in 1 day</p>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="delivery-amt">
                      <h6 class="amt">+200</h6>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="delivery-add">
                      <a
                        href="javascript:void(0);"
                        class="btn btn-light-primary add-btn"
                        :class="{ active: isActiveTwo }"
                        @click="toggleActiveTwo"
                        ><i
                          :class="{
                            'feather-check': isActiveTwo,
                            'feather-plus-circle': !isActiveTwo,
                          }"
                        ></i>
                        <span v-if="!isActiveTwo">Add</span>
                        <span v-else>Added</span></a
                      >
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <!-- /Extra Services -->

          <!-- About Services -->
          <div class="service-wrap">
            <h3>About this Service</h3>
            <p>
              Amazon affiliate marketing autopilot website with google SEO
              Autoblog for Making Money OnlineAffiliate marketing is an
              excellent way to earn passive income. this type of website doesn't
              require any extra maintenance or technical knowledge to run.
            </p>
            <p>
              Every product will be linked with your unique affiliate ID so that
              you will earn commissions on every sale. Autoblog Feature will
              automatically add high-quality blog content relevant to your niche
              to the site. No maintenance is required!With my service, you will
              get a Complete Automatic amazon affiliate website with auto blogs
              for passive income
            </p>
          </div>
          <!-- /About Services -->

          <!-- Why Work With Me -->
          <div class="service-wrap">
            <h3>Why Work With Me</h3>
            <ul class="service-lists">
              <li>
                I have five years+ of experience in affiliate marketing, and I
                am running my own affiliate marketing business, so I have an
                understanding of how these things really work with google SEO
              </li>
              <li>
                I will not leave you alone after delivery like others. Happy to
                provide Lifetime 24/7 Ongoing Support. Also, I will give you a
                secret guide that will help you to get Quick traffic and sales
                to the website.
              </li>
            </ul>
            <p>
              If you want to know more about how the Amazon Affiliate website
              work, contact me, and I'll personally explain everything in detail
              :)
            </p>
          </div>
          <!-- /Why Work With Me -->

          <!-- Recent Works -->
          <service-work></service-work>
          <!-- /Recent Works -->

          <!-- FAQ Lists -->
          <div class="service-wrap">
            <h3>FAQ</h3>
            <div class="faq-lists">
              <div class="faq-card">
                <h4 class="faq-title">
                  <a
                    class="collapsed"
                    data-bs-toggle="collapse"
                    href="#faqone"
                    aria-expanded="false"
                    >Do you offer assistance after the order has been
                    completed?</a
                  >
                </h4>
                <div id="faqone" class="card-collapse collapse">
                  <div class="faq-content">
                    <p>
                      Yes! My service guarantees you 24/7 lifetime support for
                      anything related to your website. Whenever you encounter a
                      problem, feel free to reach out to me anytime.
                    </p>
                  </div>
                </div>
              </div>
              <div class="faq-card">
                <h4 class="faq-title">
                  <a
                    class="collapsed"
                    data-bs-toggle="collapse"
                    href="#faqtwo"
                    aria-expanded="false"
                    >Can I choose my favorite Product category or Niche?</a
                  >
                </h4>
                <div id="faqtwo" class="card-collapse collapse">
                  <div class="faq-content">
                    <p>
                      Yes! My service guarantees you 24/7 lifetime support for
                      anything related to your website. Whenever you encounter a
                      problem, feel free to reach out to me anytime.
                    </p>
                  </div>
                </div>
              </div>
              <div class="faq-card">
                <h4 class="faq-title">
                  <a
                    class="collapsed"
                    data-bs-toggle="collapse"
                    href="#faqOne"
                    aria-expanded="false"
                    >Can I add products myself?</a
                  >
                </h4>
                <div id="faqOne" class="card-collapse collapse">
                  <div class="faq-content">
                    <p>
                      Yes! My service guarantees you 24/7 lifetime support for
                      anything related to your website. Whenever you encounter a
                      problem, feel free to reach out to me anytime.
                    </p>
                  </div>
                </div>
              </div>
              <div class="faq-card">
                <h4 class="faq-title">
                  <a
                    class="collapsed"
                    data-bs-toggle="collapse"
                    href="#faqfour"
                    aria-expanded="false"
                    >Are there any additional or hidden charges?</a
                  >
                </h4>
                <div id="faqfour" class="card-collapse collapse">
                  <div class="faq-content">
                    <p>
                      Yes! My service guarantees you 24/7 lifetime support for
                      anything related to your website. Whenever you encounter a
                      problem, feel free to reach out to me anytime.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /FAQ Lists -->

          <!-- Review Lists -->
          <service-review></service-review>
          <!-- /Review Lists -->

          <!-- Related Tags -->
          <div class="service-wrap tags-widget">
            <h3>Related Tags</h3>
            <ul class="tags">
              <li>
                <a href="javascript:void(0);">Wordpress design</a>
              </li>
              <li>
                <a href="javascript:void(0);">Plugin</a>
              </li>
            </ul>
          </div>
          <!-- /Related Tags -->
        </div>
        <!-- /Service Details -->

        <!-- Member Details -->
        <service-member></service-member>
        <!-- /Member Details -->
      </div>

      <!-- Recent Work -->
      <service-recent></service-recent>
      <!-- /Recent Work -->
    </div>
  </div>
  <layout-footer></layout-footer>

  <service-details-modal></service-details-modal>
</template>
<script>
export default {
  data() {
    return {
      isActive: false,
      isActiveOne: false,
      isActiveTwo: false,
    };
  },
  methods: {
    toggleActive() {
      this.isActive = !this.isActive;
    },
    toggleActiveOne() {
      this.isActiveOne = !this.isActiveOne;
    },
    toggleActiveTwo() {
      this.isActiveTwo = !this.isActiveTwo;
    },
  },
};
</script>
