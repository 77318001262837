<template>
  <div class="col-lg-12">
    <div class="card dashboard-card">
      <div class="card-header">
        <div class="service-card-head">
          <h4 class="card-title">Sales Statistics</h4>
          <ul class="services-list nav">
            <li>
              <a href="#" data-bs-toggle="tab" data-bs-target="#amount"
                >Amount</a
              >
            </li>
            <li>
              <a
                href="#"
                class="active"
                data-bs-toggle="tab"
                data-bs-target="#service"
                >Services</a
              >
            </li>
          </ul>
        </div>
        <router-link to="/user/user-sales" class="view-link"
          >View All<i class="feather-arrow-right"></i
        ></router-link>
      </div>
      <div class="card-body">
        <div class="tab-content">
          <div id="amount" class="tab-pane fade">
            <div id="amount-chart">
              <apexchart
                type="bar"
                height="250"
                :options="purchaseCharts.purchCharts"
                :series="purchaseCharts.series"
              ></apexchart>
            </div>
          </div>
          <div id="service" class="tab-pane show active">
            <div id="purchase-chart">
              <apexchart
                type="bar"
                height="250"
                :options="amountCharts.amoCharts"
                :series="amountCharts.series"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { purchaseCharts, amountCharts } from "./data";
export default {
  data() {
    return {
      purchaseCharts: purchaseCharts,
      amountCharts: amountCharts,
    };
  },
};
</script>
