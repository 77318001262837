<template>
  <user-header></user-header>
  <page-header :title="title" :text="text" :text1="text1" />
  <section class="about-us-section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="row">
            <div class="col-sm-6">
              <div class="about-inner-img">
                <img
                  src="@/assets/img/aboutus/about-us-01.jpg"
                  class="img-fluid"
                  alt="img"
                />
              </div>
            </div>
            <div class="col-sm-6">
              <div class="row">
                <div class="col-sm-12">
                  <div class="about-inner-img">
                    <img
                      src="@/assets/img/aboutus/about-us-02.jpg"
                      class="img-fluid"
                      alt="img"
                    />
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="about-inner-img">
                    <img
                      src="@/assets/img/aboutus/about-us-03.jpg"
                      class="img-fluid"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="about-us-info">
            <div class="about-us-head">
              <h2>About Us</h2>
              <p>
                Welcome to Portfolavo, where creativity meets opportunity. We
                are a cutting-edge platform dedicated to connecting talented
                audiovisual freelancers with clients seeking exceptional
                expertise. Our mission is to streamline the process of finding
                and hiring top professionals, fostering a vibrant community
                where innovation thrives and projects come to life seamlessly.
              </p>
              <h5>Our Mission</h5>
              <p>
                At Portfolavo, our mission is to bridge the gap between
                creativity and opportunity by connecting clients with skilled
                audiovisual professionals. We strive to cultivate a vibrant,
                inclusive marketplace where talent and innovation thrive,
                enabling individuals and businesses to achieve their goals with
                confidence and ease.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Why Choose Us -->
  <section class="why-choose-sec">
    <div class="container">
      <div class="about-us-header">
        <h2>Why Choose Us</h2>
        <p>
          We prioritize your satisfaction through personalized solutions and a
          commitment to excellence.
        </p>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="why-choose-card">
            <div class="card-icon">
              <img src="@/assets/img/icons/why-choose-icon-01.svg" alt="img" />
            </div>
            <h4>Reliable Connections</h4>
            <p>
              Connect with top audiovisual talent and clients to bring your
              creative projects to life.
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="why-choose-card">
            <div class="card-icon">
              <img src="@/assets/img/icons/why-choose-icon-02.svg" alt="img" />
            </div>
            <h4>Effortless Collaboration</h4>
            <p>
              A simple platform that enables seamless project management and
              communication for clients and freelancers.
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="why-choose-card">
            <div class="card-icon">
              <img src="@/assets/img/icons/why-choose-icon-03.svg" alt="img" />
            </div>
            <h4>Data Protection</h4>
            <p>
              Your personal and financial data is protected with
              industry-leading encryption and security protocols.
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="why-choose-card">
            <div class="card-icon">
              <img src="@/assets/img/icons/why-choose-icon-04.svg" alt="img" />
            </div>
            <h4>Fast & Reliable</h4>
            <p>
              Quickly find the right talent or job without compromising on
              quality, even for urgent needs.
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="why-choose-card">
            <div class="card-icon">
              <img src="@/assets/img/icons/why-choose-icon-05.svg" alt="img" />
            </div>
            <h4>Secure Transactions</h4>
            <p>
              Enjoy peace of mind with secure payment systems and escrow to
              ensure fair transactions.
            </p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="why-choose-card">
            <div class="card-icon">
              <img src="@/assets/img/icons/why-choose-icon-06.svg" alt="img" />
            </div>
            <h4>24/7 Support</h4>
            <p>
              Get personalized assistance anytime with our dedicated customer
              support team.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="start-seller-sec">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 d-flex">
          <div class="seller-inner-img w-100">
            <img
              src="@/assets/img/aboutus/about-us-04.jpg"
              class="img-fluid"
              alt="Freelancer Image"
            />
          </div>
        </div>
        <div class="col-lg-6 d-flex">
          <div class="seller-info-content w-100">
            <div class="seller-head">
              <h3>Start As Freelancer</h3>
              <p>
                Launch your freelance profile and showcase your audiovisual
                skills.
              </p>
              <p class="seller-para">
                Join a platform designed for creatives. Set your rates,
                highlight your portfolio, and connect with clients in need of
                your expertise.
              </p>
            </div>
            <div class="seller-feature-list d-flex w-100">
              <div class="sllers-list">
                <ul>
                  <li>
                    <span><i class="feather-check-square"></i></span>Set your
                    rates
                  </li>
                  <li>
                    <span><i class="feather-check-square"></i></span>Work on
                    your schedule
                  </li>
                  <li>
                    <span><i class="feather-check-square"></i></span>Build your
                    reputation
                  </li>
                  <li>
                    <span><i class="feather-check-square"></i></span>Offer 24/7
                    client support
                  </li>
                </ul>
                <router-link to="/signup" class="btn btn-primary w-auto"
                  >Become a Freelancer</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="start-seller-sec">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 d-flex" style="z-index: 1">
          <div
            class="seller-info-content w-100"
            style="margin: auto -100px auto 100px !important"
          >
            <div class="seller-head">
              <h3>Start As Client</h3>
              <p>
                Post your projects and find talented audiovisual freelancers
                today.
              </p>
              <p class="seller-para">
                Connect with top professionals for your audiovisual
                projects—whether it’s video production, sound design, VFX, and
                more.
              </p>
            </div>
            <div class="seller-feature-list d-flex w-100">
              <div class="sllers-list">
                <ul>
                  <li>
                    <span><i class="feather-check-square"></i></span>Post your
                    job listings
                  </li>
                  <li>
                    <span><i class="feather-check-square"></i></span>Browse
                    freelancer profiles
                  </li>
                  <li>
                    <span><i class="feather-check-square"></i></span>Review and
                    hire top talent
                  </li>
                  <li>
                    <span><i class="feather-check-square"></i></span>Manage
                    projects efficiently
                  </li>
                </ul>
                <router-link to="/signup" class="btn btn-primary w-auto"
                  >Become a Client</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 d-flex">
          <div class="seller-inner-img w-100">
            <img
              src="@/assets/img/aboutus/about-us-04.jpg"
              class="img-fluid"
              alt="Client Image"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <layout-footer></layout-footer>
</template>
<script>
export default {
  data() {
    return {
      title: "Home",
      text: "About Us",
      text1: "About Us",
    };
  },
};
</script>
