<template>
  <!-- FAQ -->
  <section class="explore-services-sec">
    <div class="section-bg">
      <img
        src="@/assets/img/bg/section-bg-06.png"
        class="explore-bg1"
        alt="img"
      />
    </div>
    <div class="container">
      <div class="faq-sec">
        <div class="row align-items-center">
          <div class="col-lg-12">
            <div class="faq-heading text-center aos" data-aos="fade-up">
              <div class="section-header">
                <h2><span>Your</span> Frequently Added Question’s</h2>
              </div>
              <p>Don’t find the answer? We can help you.</p>
              <router-link to="/faq" class="btn btn-primary"
                >Ask a Question<i class="fa-solid fa-caret-right"></i
              ></router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="trusted-customers-sec">
        <div class="section-bg">
          <img
            src="@/assets/img/bg/banner-bg-04.png"
            class="explore-bg2"
            alt="img"
          />
        </div>
        <div class="trusted-customers">
          <div class="section-header" data-aos="fade-up" data-aos-delay="500">
            <h2>Find the right service for your needs</h2>
            <p>Large listings of services available today for you.</p>
          </div>
          <div
            class="more-btn text-center"
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <router-link to="/services/service" class="btn btn-primary"
              ><img
                src="@/assets/img/icons/list-search-icon.svg"
                class="me-2"
                alt="img"
              />Explore Services</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /FAQ -->
</template>
