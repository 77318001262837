<template>
  <ul class="main-nav navbar-nav">
    <!-- Existing Vue loop condition -->
    <template v-for="item in navItems" :key="item.tittle">
      <li
        v-if="item.separateRoute == true"
        :class="{ active: route_array[1] === item.active_link }"
      >
        <router-link :to="item.routes">
          {{ item.tittle }}
        </router-link>
      </li>
      <li v-if="item.separateRoute == false" class="has-submenu">
        <a
          href="javascript:void(0);"
          :class="{
            active:
              isActiveLink(item.active_link) || isActiveLink(item.active_link1),
          }"
          @click="item.showAsTab = !item.showAsTab"
        >
          {{ item.tittle }} <i class="fas fa-chevron-down"></i>
        </a>
        <ul :class="{ 'd-block': item.showAsTab === true }" class="submenu">
          <!-- Nested Vue loop for submenu -->
          <template v-for="menu in item.menu" :key="menu.menuValue">
            <!-- Existing Vue conditions for submenu -->
            <li
              v-if="menu.hasSubRoute === false"
              :class="{ active: currentPath === menu.active_link }"
            >
              <router-link :to="menu.routes">{{ menu.menuValue }}</router-link>
            </li>
            <li
              v-else-if="menu.hasSubRoute === true"
              :class="{
                active:
                  isActiveLink(menu.active_link) ||
                  isActiveLink(menu.active_link1),
              }"
              class="has-submenu"
            >
              <a href="javascript:void(0);">{{ menu.menuValue }}</a>
              <ul class="submenu" :class="{ 'd-block': menu.showSubRoute }">
                <!-- Nested loop for submenus -->
                <template
                  v-for="subMenu in menu.subMenus"
                  :key="subMenu.menuValue"
                >
                  <li :class="{ active: currentPath === subMenu.routes }">
                    <router-link :to="subMenu.routes">{{
                      subMenu.menuValue
                    }}</router-link>
                  </li>
                </template>
              </ul>
            </li>
          </template>
        </ul>

        <!-- Additional code snippet for 'Explore' menu item -->
        <ul v-if="item.tittle === 'Explore'" class="submenu mega-submenu">
          <li>
            <div class="megamenu-wrapper">
              <div
                class="row row-cols-xxl-5 row-cols-xl-4 row-cols-lg-3 row-cols-md-1"
              >
                <template v-for="menu in item.menu" :key="menu.menuValue">
                  <div class="col">
                    <div class="menu-list">
                      <div class="menu-heading">
                        <span>
                          <img
                            :src="require(`@/assets/img/icons/${menu.image}`)"
                            class="img-fluid"
                            alt="img"
                          />
                        </span>
                        <h5>{{ menu.menuValue }}</h5>
                      </div>
                      <ul>
                        <li
                          v-for="subMenu in menu.subMenus"
                          :key="subMenu.menuValue"
                        >
                          <h6>
                            <router-link :to="subMenu.routes">{{
                              subMenu.menuValue
                            }}</router-link>
                          </h6>
                          <p>
                            <router-link :to="subMenu.routes">{{
                              subMenu.content
                            }}</router-link>
                          </p>
                        </li>
                        <li>
                          <router-link to="/services/" class="more-link"
                            >More Services<i class="feather-external-link"></i
                          ></router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </li>
        </ul>
      </li>
    </template>
  </ul>
</template>

<script>
import navItems from "@/assets/json/header-menu.json";
export default {
  data() {
    return {
      isSearchPopupVisible: false,
      route_array: [],
      navItems: navItems,
    };
  },
  computed: {
    currentPath() {
      return this.getCurrentPath();
    },
  },
  methods: {
    openSearchPopup() {
      this.isSearchPopupVisible = true;
      document.body.classList.add("search-visible");
    },
    closeSearchPopup() {
      this.isSearchPopupVisible = false;
      document.body.classList.remove("search-visible");
    },
    getCurrentPath() {
      this.route_array = this.$route.path.split("/");
      return this.$route.path;
    },
    isActiveLink(link) {
      return this.currentPath.includes(link);
    },
  },
};
</script>
