<template>
  <div class="services-card-slider listing-services owl-carousel">
    <Carousel
      :wrap-around="true"
      :settings="settings"
      :breakpoints="breakpoints"
    >
      <Slide v-for="item in PopularIndia" :key="item.id">
        <div class="services-grid">
          <div class="services-img">
            <div class="img-slider owl-carousel">
              <div class="slide-images">
                <router-link to="/services/service-details">
                  <img
                    :src="require(`@/assets/img/services/list/${item.Image}`)"
                    class="img-fluid"
                    alt="Services"
                  />
                </router-link>
              </div>
            </div>
            <div class="card-overlay-badge">
              <router-link to="/services/service"
                ><span class="badge bg-info"
                  ><i class="fa-solid fa-circle"></i>{{ item.Badge }}</span
                ></router-link
              >
            </div>
          </div>
          <div class="services-content text-start">
            <div class="services-info">
              <p class="services-location">
                <img src="@/assets/img/icons/map-pin-check.svg" alt="Icon" />{{
                  item.Location
                }}
              </p>
              <div class="star-rate">
                <span
                  ><i class="fa-solid fa-star"></i>5.0
                  <span class="fw-semibold ms-1">
                    {{ item.Reviews }}</span
                  ></span
                >
              </div>
            </div>
            <div class="services-title">
              <h3>
                <router-link to="/services/service-details">
                  {{ item.Title }}
                </router-link>
              </h3>
              <p class="loc-user-name">{{ item.Name }}</p>
            </div>
            <div class="services-card-footer">
              <h5><span>From</span>{{ item.Price }}</h5>
              <div class="services-share">
                <a href="javascript:void(0);">
                  <i class="feather-share-2"></i>
                </a>
                <span class="badge">{{ item.Delivery }}</span>
              </div>
            </div>
          </div>
        </div>
      </Slide>
      <template #addons>
        <Navigation />
      </template>
    </Carousel>
  </div>
</template>

<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import PopularIndia from "@/data/popular-annaba.json";
import "vue3-carousel/dist/carousel.css";

export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      PopularIndia: PopularIndia,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        575: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
};
</script>
