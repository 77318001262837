<template>
  <div class="sidebar-widget">
    <div class="sidebar-header">
      <h3>Filter</h3>
      <a href="#" class="reset-link">Reset Filter</a>
    </div>

    <div class="sidebar-body p-0">
      <!-- Categories -->
      <div class="collapse-card">
        <h4 class="card-title">
          <a
            class=""
            data-bs-toggle="collapse"
            href="#categories"
            aria-expanded="true"
          >
            <img
              src="@/assets/img/icons/category-icon.svg"
              alt="icon"
            />Categories
          </a>
        </h4>
        <div id="categories" class="collapse show">
          <div class="collapse-body">
            <div class="form-group search-group">
              <span class="search-icon"><i class="feather-search"></i></span>
              <input
                type="text"
                class="form-control"
                placeholder="Search Category"
              />
            </div>
            <ul class="checkbox-list">
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">Programming & Coding</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">Data Science & Analysis</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">Databases </span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">Mobile App Development</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">Email Template Development</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">CMS Development</span>
                </label>
              </li>
              <li>
                <div class="view-content">
                  <div class="viewall-one">
                    <ul>
                      <li>
                        <label class="custom_check">
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="checked-title"
                            >ECommerce CMS Development</span
                          >
                        </label>
                      </li>
                      <li>
                        <label class="custom_check">
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="checked-title">Programming</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div class="view-all">
                    <a href="javascript:void(0);" class="viewall-button-one"
                      ><span>More 20+ Categories</span></a
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Categories -->

      <!-- Locations -->
      <div class="collapse-card">
        <h4 class="card-title">
          <a
            class=""
            data-bs-toggle="collapse"
            href="#locations"
            aria-expanded="true"
          >
            <img src="@/assets/img/icons/map-icon.svg" alt="icon" />Locations
          </a>
        </h4>
        <div id="locations" class="collapse show">
          <div class="collapse-body">
            <div class="form-group search-group">
              <span class="search-icon"><i class="feather-search"></i></span>
              <input
                type="text"
                class="form-control"
                placeholder="Search Locations"
              />
            </div>
            <ul class="checkbox-list">
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">Canada</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">Bolivia</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">Tunsania</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">Indonesia</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">UK</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">UAE</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">USA</span>
                </label>
              </li>
              <li>
                <div class="view-content">
                  <div class="viewall-location">
                    <ul>
                      <li>
                        <label class="custom_check">
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="checked-title">Malaysia</span>
                        </label>
                      </li>
                      <li>
                        <label class="custom_check">
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="checked-title">Japan</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div class="view-all">
                    <a href="javascript:void(0);" class="viewall-btn-location"
                      ><span>More 20+ Locations</span></a
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Locations -->

      <!-- Seller Details -->
      <div class="collapse-card">
        <h4 class="card-title">
          <a
            class=""
            data-bs-toggle="collapse"
            href="#seller"
            aria-expanded="true"
          >
            <img src="@/assets/img/icons/user-icon.svg" alt="icon" />Seller
            Details
          </a>
        </h4>
        <div id="seller" class="collapse show">
          <div class="collapse-body">
            <ul class="seller-list">
              <li>
                <a href="javascript:void(0);"
                  >Seller Level<span><i class="feather-chevron-right"></i></span
                ></a>
              </li>
              <li>
                <a href="javascript:void(0);"
                  >Seller Availability<span
                    ><i class="feather-chevron-right"></i></span
                ></a>
              </li>
              <li>
                <a href="javascript:void(0);"
                  >Seller Speaks<span
                    ><i class="feather-chevron-right"></i></span
                ></a>
              </li>
              <li>
                <a href="javascript:void(0);"
                  >Seller Lives in<span
                    ><i class="feather-chevron-right"></i></span
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Seller Details -->

      <!-- Ratings -->
      <div class="collapse-card">
        <h4 class="card-title">
          <a
            class=""
            data-bs-toggle="collapse"
            href="#ratings"
            aria-expanded="true"
          >
            <img src="@/assets/img/icons/rating-icon.svg" alt="icon" />Ratings
          </a>
        </h4>
        <div id="ratings" class="collapse show">
          <div class="collapse-body">
            <ul class="checkbox-list star-rate">
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="ratings">
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                  </span>
                  <span class="rating-count">(5.0)</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="ratings">
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star"></i>
                  </span>
                  <span class="rating-count">(4.0)</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="ratings">
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                  </span>
                  <span class="rating-count">(3.0)</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="ratings">
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                  </span>
                  <span class="rating-count">(2.0)</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="ratings">
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                  </span>
                  <span class="rating-count">(1.0)</span>
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Ratings -->

      <!-- Budget -->
      <div class="collapse-card">
        <h4 class="card-title">
          <a
            class=""
            data-bs-toggle="collapse"
            href="#budget"
            aria-expanded="true"
          >
            <img src="@/assets/img/icons/money-icon.svg" alt="icon" />Budget
          </a>
        </h4>
        <div id="budget" class="collapse show">
          <div class="collapse-body">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                placeholder="Enter Custom Budget"
              />
            </div>
            <ul class="checkbox-list">
              <li>
                <label class="custom_radio">
                  <input type="radio" name="budget" checked />
                  <span class="checkmark"></span
                  ><span class="text-dark"> Value :</span> Under $4500
                </label>
              </li>
              <li>
                <label class="custom_radio">
                  <input type="radio" name="budget" />
                  <span class="checkmark"></span
                  ><span class="text-dark"> Mid-range :</span> Under $4500
                </label>
              </li>
              <li>
                <label class="custom_radio">
                  <input type="radio" name="budget" />
                  <span class="checkmark"></span
                  ><span class="text-dark"> High-end :</span> Under $4500
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Budget -->

      <!-- Subscription -->
      <div class="collapse-card">
        <h4 class="card-title">
          <a
            class=""
            data-bs-toggle="collapse"
            href="#subscription"
            aria-expanded="true"
          >
            <img
              src="@/assets/img/icons/subscribe-icon.svg"
              alt="icon"
            />Subscription
          </a>
        </h4>
        <div id="subscription" class="collapse show">
          <div class="collapse-body">
            <ul class="checkbox-list">
              <li>
                <label class="custom_radio">
                  <input type="radio" name="budget" checked />
                  <span class="checkmark"></span>All Subscription
                </label>
              </li>
              <li>
                <label class="custom_radio">
                  <input type="radio" name="budget" />
                  <span class="checkmark"></span>Basic Plan
                </label>
              </li>
              <li>
                <label class="custom_radio">
                  <input type="radio" name="budget" />
                  <span class="checkmark"></span>Premium Plan
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Subscription -->

      <!-- Delivery Time -->
      <div class="collapse-card">
        <h4 class="card-title">
          <a
            class=""
            data-bs-toggle="collapse"
            href="#deivery"
            aria-expanded="true"
          >
            <img src="@/assets/img/icons/time-icon.svg" alt="icon" />Delivery
            Time
          </a>
        </h4>
        <div id="deivery" class="collapse show">
          <div class="collapse-body">
            <ul class="checkbox-list">
              <li>
                <label class="custom_radio">
                  <input type="radio" name="budget" checked />
                  <span class="checkmark"></span>Enter 24H
                </label>
              </li>
              <li>
                <label class="custom_radio">
                  <input type="radio" name="budget" />
                  <span class="checkmark"></span>Upto 3 days
                </label>
              </li>
              <li>
                <label class="custom_radio">
                  <input type="radio" name="budget" />
                  <span class="checkmark"></span>Upto 7 days
                </label>
              </li>
              <li>
                <label class="custom_radio">
                  <input type="radio" name="budget" />
                  <span class="checkmark"></span>Anytime
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Delivery Time -->
    </div>

    <a href="#" class="btn btn-primary w-100"
      ><i class="fa-solid fa-caret-right"></i>Filter</a
    >
  </div>
</template>
