<template>
  <div class="row gx-0">
    <div class="col-lg-6">
      <div class="authentication-wrapper">
        <div class="logo-container">
          <router-link to="/">
            <img
              src="@/assets/img/white-logo.svg"
              alt="img"
              class="login-logo"
            />
          </router-link>
        </div>
        <div class="login-bg">
          <img src="@/assets/img/bg/shape-02.png" alt="img" class="shape-02" />
          <img src="@/assets/img/bg/shape-03.png" alt="img" class="shape-03" />
          <img src="@/assets/img/bg/shape-04.png" alt="img" class="shape-04" />
          <img src="@/assets/img/bg/shape-05.png" alt="img" class="shape-05" />
          <img src="@/assets/img/bg/shape-06.png" alt="img" class="shape-06" />
        </div>
      </div>
    </div>

    <!-- Forgot Password Content -->
    <div class="col-lg-6">
      <div class="login-wrapper">
        <div class="login-content">
          <form @submit.prevent="submitForm">
            <div class="login-userset">
              <div class="login-card">
                <div class="login-heading text-start">
                  <h3>Forgot Password?</h3>
                  <p>Fill the fields to get into your account</p>
                </div>
                <div class="form-wrap form-focus">
                  <span class="form-icon">
                    <i class="feather-mail"></i>
                  </span>
                  <input type="email" class="form-control floating" />
                  <label class="focus-label">Email</label>
                </div>
                <div class="form-wrap mantadory-info d-none">
                  <p>
                    <i class="feather-alert-triangle"></i>Fill all the fields to
                    submit
                  </p>
                </div>
                <button type="submit" class="btn btn-primary">
                  Send Email
                </button>
              </div>
              <div class="acc-in">
                <p>
                  Already have an account?
                  <router-link to="/login">Sign In</router-link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- /Forgot Password Content -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    submitForm() {
      this.$router.push("/change-password");
    },
  },
};
</script>

<style scoped>
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 2rem 0;
}

.login-logo {
  width: 400px;
  height: auto;
}
</style>
