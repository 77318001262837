<template>
  <user-header></user-header>
  <page-header :title="title" :text="text" :text1="text1" />
  <div class="page-content content">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="gallery-widget">
            <router-link to="/pages/portfolio-details">
              <img
                class="img-fluid"
                alt="Image"
                src="@/assets/img/gallery/gallery-01.jpg"
              />
              <div class="gallery-overlay">
                <h4>Digital Marketing</h4>
                <p>Website Promotion</p>
              </div>
            </router-link>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="gallery-widget">
            <router-link to="/pages/portfolio-details">
              <img
                class="img-fluid"
                alt="Image"
                src="@/assets/img/gallery/gallery-02.jpg"
              />
              <div class="gallery-overlay">
                <h4>Social Media</h4>
                <p>Ecommerce Seo</p>
              </div>
            </router-link>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="gallery-widget">
            <router-link to="/pages/portfolio-details">
              <img
                class="img-fluid"
                alt="Image"
                src="@/assets/img/gallery/gallery-03.jpg"
              />
              <div class="gallery-overlay">
                <h4>Artificial Intelligence</h4>
                <p>Promoted Listings</p>
              </div>
            </router-link>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="gallery-widget">
            <router-link to="/pages/portfolio-details">
              <img
                class="img-fluid"
                alt="Image"
                src="@/assets/img/gallery/gallery-04.jpg"
              />
              <div class="gallery-overlay">
                <h4>Digital Marketing</h4>
                <p>Website Promotion</p>
              </div>
            </router-link>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="gallery-widget">
            <router-link to="/pages/portfolio-details">
              <img
                class="img-fluid"
                alt="Image"
                src="@/assets/img/gallery/gallery-05.jpg"
              />
              <div class="gallery-overlay">
                <h4>Videography</h4>
                <p>Promotion Video</p>
              </div>
            </router-link>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="gallery-widget">
            <router-link to="/pages/portfolio-details">
              <img
                class="img-fluid"
                alt="Image"
                src="@/assets/img/gallery/gallery-06.jpg"
              />
              <div class="gallery-overlay">
                <h4>Design</h4>
                <p>Logo Design</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <layout-footer></layout-footer>
</template>
<script>
export default {
  data() {
    return {
      title: "Home",
      text: "Portfolio",
      text1: "Portfolio",
    };
  },
};
</script>
