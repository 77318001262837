<template>
  <user-header></user-header>
  <page-header :title="title" :text="text" :text1="text1" />
  <div class="page-content category-wrap">
    <div class="container">
      <div class="row">
        <!-- Category Section -->
        <div class="col-md-12">
          <div class="marketing-section">
            <div class="marketing-content">
              <h2>All Categories</h2>
              <p>
                Digital marketing is an essential component of modern business,
                given the widespread use of the internet and digital devices.
              </p>
              <div class="marketing-bg">
                <img
                  src="@/assets/img/bg/market-bg.png"
                  alt="img"
                  class="market-bg"
                />
                <img
                  src="@/assets/img/bg/market-bg-01.png"
                  alt="img"
                  class="market-img"
                />
              </div>
            </div>
          </div>

          <!-- Trending Categories -->
          <div class="trend-section">
            <div class="row align-items-center">
              <div class="col-sm-10">
                <h3>Trending Categories Today</h3>
              </div>
              <div class="col-sm-2 text-sm-end">
                <div class="owl-nav service-nav nav-control nav-top"></div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="service-sliders owl-carousel">
                  <Carousel
                    :wrap-around="true"
                    :settings="settings"
                    :breakpoints="breakpoints"
                  >
                    <Slide v-for="record in Trending_Category" :key="record.id">
                      <div class="service-box text-start">
                        <div class="service-info">
                          <span class="service-icon">
                            <img
                              :src="
                                require(`@/assets/img/icons/${record.Image}`)
                              "
                              alt="icon"
                            />
                          </span>
                          <div class="servive-name">
                            <h5>
                              <router-link to="/services/service">{{
                                record.Name
                              }}</router-link>
                            </h5>
                            <p>{{ record.Service }}</p>
                          </div>
                        </div>
                        <router-link to="/services/service"
                          ><i class="feather-arrow-up-right"></i
                        ></router-link>
                      </div>
                    </Slide>
                    <template #addons>
                      <Navigation />
                    </template>
                  </Carousel>
                </div>
                <!-- /Service Slider -->
              </div>
            </div>
          </div>
          <!-- /Trending Categories -->
        </div>
        <!-- /Category Section -->

        <!-- Sort By -->
        <div class="sortby-title">
          <div class="row align-items-center">
            <div class="col-md-12">
              <h4>10 Categories found with <span>14,787</span> Services</h4>
            </div>
          </div>
        </div>
        <!-- /Sort By -->

        <!-- Categories List -->
        <div
          v-for="record in Category_2"
          :key="record.id"
          class="col-xl-3 col-lg-4 col-sm-6"
        >
          <div class="category-item">
            <div class="category-img">
              <router-link to="/services/service">
                <img
                  :src="require(`@/assets/img/category/${record.Image}`)"
                  class="img-fluid"
                  alt="category img"
                />
              </router-link>
              <div class="category-slug">
                <router-link to="/services/service">{{
                  record.Name
                }}</router-link>
              </div>
            </div>
            <div class="category-list">
              <ul>
                <li>
                  <router-link to="/services/service"
                    >{{ record.Finance }}<i class="feather-arrow-up-right"></i
                  ></router-link>
                </li>
                <li>
                  <router-link to="/services/service"
                    >{{ record.Consulting
                    }}<i class="feather-arrow-up-right"></i
                  ></router-link>
                </li>
                <li>
                  <router-link to="/services/service"
                    >{{ record.Legal }}<i class="feather-arrow-up-right"></i
                  ></router-link>
                </li>
                <li>
                  <router-link to="/services/service"
                    >{{ record.Management
                    }}<i class="feather-arrow-up-right"></i
                  ></router-link>
                </li>
                <li>
                  <router-link to="/services/service"
                    >{{ record.Business }}<i class="feather-arrow-up-right"></i
                  ></router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- /Categories List -->
      </div>
    </div>
  </div>
  <layout-footer></layout-footer>
</template>
<script>
import Category_2 from "@/assets/json/category-2.json";
import Trending_Category from "@/assets/json/trending-category.json";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      Trending_Category: Trending_Category,
      Category_2: Category_2,
      title: "Home",
      text: "Categories",
      text1: "Browse Categories",
      settings: {
        itemsToShow: 3,
        snapAlign: "center",
      },

      breakpoints: {
        575: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
};
</script>
