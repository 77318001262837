<template>
  <div class="row gx-0">
    <div class="col-lg-6">
      <div class="authentication-wrapper">
        <div class="logo-container">
          <router-link to="/">
            <img
              src="@/assets/img/white-logo.svg"
              alt="img"
              class="login-logo"
            />
          </router-link>
        </div>
        <div class="login-bg">
          <img src="@/assets/img/bg/shape-02.png" alt="img" class="shape-02" />
          <img src="@/assets/img/bg/shape-03.png" alt="img" class="shape-03" />
          <img src="@/assets/img/bg/shape-04.png" alt="img" class="shape-04" />
          <img src="@/assets/img/bg/shape-05.png" alt="img" class="shape-05" />
          <img src="@/assets/img/bg/shape-06.png" alt="img" class="shape-06" />
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="login-wrapper">
        <div class="login-content">
          <form @submit.prevent="submitForm">
            <div class="login-userset">
              <div class="login-card">
                <div class="login-heading">
                  <h3>Hi, Welcome!</h3>
                  <p>Register to get access to Portfolavo</p>
                </div>
                <div class="form-wrap form-focus">
                  <span class="form-icon">
                    <i class="feather-user"></i>
                  </span>
                  <input type="text" class="form-control floating" />
                  <label class="focus-label">Username</label>
                </div>
                <div class="form-wrap form-focus">
                  <span class="form-icon">
                    <i class="feather-mail"></i>
                  </span>
                  <input type="email" class="form-control floating" />
                  <label class="focus-label">Email</label>
                </div>
                <div class="form-wrap form-focus pass-group">
                  <span class="form-icon">
                    <i class="toggle-password feather-eye-off"></i>
                  </span>
                  <input
                    type="password"
                    class="pass-input form-control floating"
                  />
                  <label class="focus-label">Password</label>
                </div>
                <div class="form-wrap form-focus pass-group">
                  <span class="form-icon">
                    <i class="toggle-password-confirm feather-eye-off"></i>
                  </span>
                  <input
                    type="password"
                    class="pass-confirm form-control floating"
                  />
                  <label class="focus-label">Confirm Password</label>
                </div>
                <div class="form-wrap">
                  <label class="custom_check mb-0"
                    >By login you agree to our
                    <router-link to="/terms-condition"
                      >Terms of Use</router-link
                    >
                    and
                    <router-link to="/privacy-policy"
                      >Privacy Policy</router-link
                    >
                    <input type="checkbox" name="remeber" />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="isDisabled"
                >
                  Sign Up
                </button>
              </div>
              <div class="acc-in">
                <p>
                  Already have an account?
                  <router-link to="/login">Sign In</router-link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- /Register Content -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDisabled: false,
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
    };
  },
  methods: {
    submitForm() {
      this.isDisabled = true;

      if (this.username === "") {
        this.$toast.error("Username is required");
        this.isDisabled = false;
        return;
      }
    },
  },
};
</script>

<style scoped>
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 2rem 0;
}

.login-logo {
  width: 400px;
  height: auto;
}
</style>
