<template>
  <div class="settings-page-lists">
    <ul class="settings-head">
      <li>
        <router-link to="/user/user-settings">Profile</router-link>
      </li>
      <li>
        <router-link to="/user/security-settings">Security</router-link>
      </li>
      <li>
        <router-link to="/user/preference-settings">Preferences</router-link>
      </li>
      <li>
        <router-link to="/user/billing-settings">Plan & Billing</router-link>
      </li>
      <li>
        <router-link to="/user/notification-settings"
          >Notifications</router-link
        >
      </li>
      <li>
        <router-link to="/user/integration-settings">Integrations</router-link>
      </li>
    </ul>
  </div>
</template>
