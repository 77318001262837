<template>
  <!-- Contact Me -->
  <div
    id="contact_me"
    class="modal new-modal fade"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Contact Me</h5>
          <button type="button" class="close-btn" data-bs-dismiss="modal">
            <span>×</span>
          </button>
        </div>
        <div class="modal-body service-modal">
          <div class="row">
            <div class="col-md-12">
              <div class="user-details">
                <div class="user-img">
                  <img src="@/assets/img/user/user-05.jpg" alt="img" />
                </div>
                <div class="user-info">
                  <h5>
                    <span class="me-2">Adrian Revolt</span>
                    <span class="badge badge-success"
                      ><i class="fa-solid fa-circle"></i> Online</span
                    >
                  </h5>
                  <h6>An abundance of creativity</h6>
                  <p>
                    <i class="fa-solid fa-star"></i>Ratings 5.0 (45 Reviews)
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="modal-form-group">
                <vue-select
                  id="abousel"
                  :options="AbouSel"
                  placeholder="What's the message is about?"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="modal-form-group">
                <input type="text" class="form-control" placeholder="Name" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="modal-form-group">
                <input type="email" class="form-control" placeholder="Email" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="modal-form-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Phone Number"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="modal-form-group">
                <vue-select
                  id="locatisel"
                  :options="LocatiSel"
                  placeholder="Location"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="modal-form-group">
                <textarea
                  class="form-control"
                  rows="6"
                  placeholder="Enter Your Comments"
                ></textarea>
              </div>
            </div>
            <div class="modal-form-group">
              <label class="custom_check mt-0 mb-0"
                >I agree to the <a href="#">Terms & Conditions</a>
                <input type="checkbox" name="remeber" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div class="modal-btn">
            <a
              href="javascript:void(0);"
              data-bs-dismiss="modal"
              class="btn btn-primary w-100"
            >
              Send Enquiry
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Contact Me -->

  <!-- Order Details -->
  <div
    id="order_details"
    class="modal new-modal fade"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Order Details</h5>
          <button type="button" class="close-btn" data-bs-dismiss="modal">
            <span>×</span>
          </button>
        </div>
        <div class="modal-body service-modal">
          <div class="row">
            <div class="col-md-12">
              <div class="order-status">
                <div class="order-item">
                  <div class="order-img">
                    <img
                      src="@/assets/img/service/service-slide-01.jpg"
                      alt="img"
                    />
                  </div>
                  <div class="order-info">
                    <h5>
                      I will design, redesign wordpress website using elementor
                      pro
                    </h5>
                    <ul>
                      <li>ID : #1245</li>
                      <li>Delivery : Jan 29 2024 8:10 AM</li>
                    </ul>
                  </div>
                </div>
                <h6 class="title">Details</h6>
                <div class="user-details">
                  <div class="user-img">
                    <img src="@/assets/img/user/user-05.jpg" alt="img" />
                  </div>
                  <div class="user-info">
                    <h5>
                      Adrian Revolt <span class="location">From USA</span>
                    </h5>
                    <p>
                      <i class="fa-solid fa-star"></i>Ratings 5.0 (45 Reviews)
                    </p>
                  </div>
                </div>
                <h6 class="title">Service Details</h6>
                <div class="detail-table table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Service</th>
                        <th>Quantity</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Designing and developing...</td>
                        <td>1</td>
                        <td class="text-primary">$100</td>
                      </tr>
                      <tr>
                        <td>Additional 1 : I can clean</td>
                        <td>1</td>
                        <td class="text-primary">$100</td>
                      </tr>
                      <tr>
                        <td>Super Fast : Super fast delivery</td>
                        <td>1</td>
                        <td class="text-primary">$100</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colspan="2">Grand Total</th>
                        <th class="text-primary">$300</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="modal-btn">
                  <div class="row gx-2">
                    <div class="col-6">
                      <a
                        href="#"
                        data-bs-dismiss="modal"
                        class="btn btn-secondary w-100 justify-content-center"
                        >Cancel</a
                      >
                    </div>
                    <div class="col-6">
                      <router-link
                        to="/user/user-purchase"
                        class="btn btn-primary w-100"
                        >Pay Now</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Order Details -->
</template>
<script>
export default {
  data() {
    return {
      LocatiSel: ["Location", "India", "UK"],
      AbouSel: ["What's the message is about?", "Service"],
    };
  },
};
</script>
