<template>
  <!-- Explore Services -->
  <section class="explore-services-section">
    <div class="container">
      <div class="section-head d-flex">
        <div class="section-header aos" data-aos="fade-up">
          <h2><span>Explore</span> Our Services.</h2>
        </div>
        <div class="section-tab">
          <ul
            id="pills-tab"
            class="nav nav-pills inner-tab aos"
            role="tablist"
            data-aos="fade-up"
          >
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                :class="{ active: activeTab === 'pills-popular' }"
                @click="activeTab = 'pills-popular'"
              >
                Popular
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                :class="{ active: activeTab === 'pills-latest' }"
                @click="activeTab = 'pills-latest'"
              >
                Latest
              </button>
            </li>

            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                :class="{ active: activeTab === 'pills-rating' }"
                @click="activeTab = 'pills-rating'"
              >
                Top Ratings
              </button>
            </li>

            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                :class="{ active: activeTab === 'pills-trend' }"
                @click="activeTab = 'pills-trend'"
              >
                Trending
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="tab-content dashboard-tab">
        <div
          v-if="activeTab === 'pills-popular'"
          id="pills-popular"
          class="tab-pane"
          :class="{ active: activeTab === 'pills-popular' }"
        >
          <div class="row">
            <div class="col-md-12">
              <explore-popular></explore-popular>
            </div>
          </div>
        </div>
        <div
          v-if="activeTab === 'pills-latest'"
          id="pills-latest"
          class="tab-pane"
          :class="{ active: activeTab === 'pills-latest' }"
        >
          <div class="row">
            <div class="col-md-12">
              <explore-latest></explore-latest>
            </div>
          </div>
        </div>
        <div
          v-if="activeTab === 'pills-rating'"
          id="pills-rating"
          class="tab-pane"
          :class="{ active: activeTab === 'pills-rating' }"
        >
          <div class="row">
            <div class="col-md-12">
              <explore-top-ratings></explore-top-ratings>
            </div>
          </div>
        </div>
        <div
          v-if="activeTab === 'pills-trend'"
          id="pills-trend"
          class="tab-pane"
          :class="{ active: activeTab === 'pills-trend' }"
        >
          <div class="row">
            <div class="col-md-12">
              <explore-trending></explore-trending>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Explore Services -->
</template>

<script>
export default {
  data() {
    return {
      activeTab: "pills-popular",
    };
  },
  watch: {
    activeTab() {
      this.$nextTick(() => {
        const carousel = this.$refs.carousel;
        if (carousel) {
          carousel.restart();
        }
      });
    },
  },
};
</script>
