<template>
  <div class="row">
    <div class="col-lg-12 mx-auto">
      <div class="error-wrapper">
        <!-- Coming Soon -->
        <div class="error-item p-0">
          <div class="coming-soon text-center">
            <div class="header-logo">
              <img src="@/assets/img/logo.svg" class="img-fluid" alt="img" />
            </div>

            <!-- Count Downs -->
            <div class="coming-content">
              <h2>COMING SOON</h2>
              <div v-if="showCountdown" class="days-count">
                <ul>
                  <li>
                    <h3 class="days">{{ days }}</h3>
                    <p>DAYS</p>
                  </li>
                  <li>
                    <h3 class="hours">{{ hours }}</h3>
                    <p>HOURS</p>
                  </li>
                  <li>
                    <h3 class="minutes">{{ minutes }}</h3>
                    <p>MINUTES</p>
                  </li>
                  <li>
                    <h3 class="seconds">{{ seconds }}</h3>
                    <p>SECONDS</p>
                  </li>
                </ul>
                <div class="count-bgs">
                  <img
                    src="@/assets/img/bg/error-01.png"
                    alt="img"
                    class="count-01"
                  />
                  <img
                    src="@/assets/img/bg/error-03.png"
                    alt="img"
                    class="count-02"
                  />
                </div>
              </div>
              <div v-else>
                <h1>{{ expiredMessage }}</h1>
              </div>
              <div class="getback-content">
                <p>
                  We Will Back With Something Amazing to Get Latest Update,
                  <span class="d-block">Please Sign Up to Our Newsletter</span>
                </p>
                <div class="mail-form">
                  <form action="#">
                    <div class="form-wrap">
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Enter Email"
                      />
                      <button type="submit" class="btn btn-primary">
                        Subscribe
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- /Count Downs -->

            <!-- Social Icons -->
            <div class="social-footer">
              <ul class="social-icon">
                <li>
                  <a href="javascript:void(0);">
                    <i class="feather-facebook hi-icon"></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i class="feather-linkedin hi-icon"></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i class="feather-twitter hi-icon"></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i class="feather-instagram hi-icon"></i>
                  </a>
                </li>
              </ul>
              <p>Copyright 2024 © Portfolavo</p>
            </div>
            <!-- /Social Icons -->
          </div>
        </div>
        <!-- /Coming Soon -->
      </div>

      <!-- Error Img -->
      <div class="error-imgs count-imgs">
        <img
          src="@/assets/img/bg/error-01.png"
          alt="img"
          class="error-01 error-bg"
        />
        <img
          src="@/assets/img/bg/error-02.png"
          alt="img"
          class="error-02 error-bg"
        />
        <img
          src="@/assets/img/bg/error-04.png"
          alt="img"
          class="error-04 error-bg"
        />
      </div>
      <!-- /Error Img -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showCountdown: true,
      days: 52,
      hours: 21,
      minutes: 2,
      seconds: 20,
      expiredMessage: "",
    };
  },
  mounted() {
    this.setCountdown();
  },
  methods: {
    setCountdown() {
      let countdownDate = new Date("mar 27, 2024 16:00:00").getTime();

      let updateCount = setInterval(() => {
        let todayDate = new Date().getTime();
        let distance = countdownDate - todayDate;

        if (distance >= 0) {
          this.showCountdown = true;
          this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
          this.hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
          );
          this.minutes = Math.floor(
            (distance % (1000 * 60 * 60)) / (1000 * 60),
          );
          this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
        } else {
          clearInterval(updateCount);
          this.showCountdown = false;
          // Set expired message as HTML content
          this.expiredMessage = "EXPIRED";
        }
      }, 1000);
    },
  },
};
</script>
