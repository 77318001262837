<template>
  <user-header></user-header>
  <page-header :title="title" :text="text" :text1="text1" />
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!-- Category Section -->
        <div class="col-md-12">
          <div class="marketing-section">
            <div class="marketing-content">
              <h2>All Categories</h2>
              <p>
                Digital marketing is an essential component of modern business,
                given the widespread use of the internet and digital devices.
              </p>
              <div class="marketing-bg">
                <img
                  src="@/assets/img/bg/market-bg.png"
                  alt="img"
                  class="market-bg"
                />
                <img
                  src="@/assets/img/bg/market-bg-01.png"
                  alt="img"
                  class="market-img"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- /Category Section -->

        <!-- Sort By -->
        <div class="sortby-title">
          <div class="row align-items-center">
            <div class="col-md-6">
              <h4>10 Categories found with <span>14,787</span> Services</h4>
            </div>
            <div class="col-md-6">
              <div class="search-filter-selected float-md-end">
                <div class="form-group">
                  <span class="sort-text">Sort By</span>
                  <vue-select
                    id="arrsel"
                    :options="ArrSel"
                    placeholder="New Arrivals"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Sort By -->

        <!-- Categories List -->
        <div
          v-for="record in Category"
          :key="record.id"
          class="col-xl-3 col-lg-4 col-sm-6"
        >
          <div class="service-grid">
            <div class="service-img">
              <router-link to="/services/service">
                <img
                  :src="require(`@/assets/img/service/${record.Image}`)"
                  class="img-fluid"
                  alt="img"
                />
              </router-link>
              <div class="avg-price">
                <h6>Average Price</h6>
                <span>{{ record.AveragePrice }}</span>
              </div>
            </div>
            <div
              class="service-type d-flex justify-content-between align-items-center"
            >
              <div class="servive-name">
                <h4>
                  <router-link to="/services/service">{{
                    record.Name
                  }}</router-link>
                </h4>
                <span>{{ record.ServicesCount }}</span>
              </div>
              <div class="next-arrow">
                <router-link to="/services/service"
                  ><i class="feather-arrow-up-right"></i
                ></router-link>
              </div>
            </div>
          </div>
        </div>
        <!-- /Categories List -->

        <!-- Load More -->
        <div class="search-load-btn">
          <a href="javascript:void(0);" class="btn btn-secondary">
            <img
              src="@/assets/img/icons/loader-icon-01.svg"
              class="spinner-border"
              alt="Icon"
            />
            Load More
          </a>
        </div>
        <!-- /Load More -->
      </div>
    </div>
  </div>
  <layout-footer></layout-footer>
</template>
<script>
import Category from "@/assets/json/category.json";
export default {
  data() {
    return {
      Category: Category,
      ArrSel: ["New Arrivals", "Featured"],
      title: "Home",
      text: "Categories",
      text1: "Browse Categories",
    };
  },
};
</script>
