<template>
  <!-- Find Your Needs -->
  <section class="provide-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-10">
          <div class="section-header aos" data-aos="fade-up">
            <h2><span>Find</span> the right talent or project.</h2>
            <p>
              Thousands of audiovisual professionals and projects are available
              today for you.
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-6">
          <div class="provide-box">
            <div class="provide-icon">
              <img src="@/assets/img/icons/ipad-icon.svg" alt="icon" />
            </div>
            <h3>Find a Service</h3>
            <p>
              Browse through a wide range of audiovisual services to bring your
              projects to life.
            </p>
            <router-link to="/services" class="btn btn-primary">
              Get Started<i class="fa-solid fa-caret-right"></i>
            </router-link>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="provide-box">
            <div class="provide-icon">
              <img src="@/assets/img/icons/service-icon.svg" alt="icon" />
            </div>
            <h3>Offer a Service</h3>
            <p>
              List your skills, set your rates, and connect with clients looking
              for your expertise.
            </p>
            <router-link to="/services/add" class="btn btn-primary">
              Add Service<i class="fa-solid fa-caret-right"></i>
            </router-link>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="provide-box">
            <div class="provide-icon">
              <img src="@/assets/img/icons/user-icon-01.svg" alt="icon" />
            </div>
            <h3>Join Portfolavo</h3>
            <p>
              Become part of a growing network of talented audiovisual
              freelancers and clients.
            </p>
            <router-link to="/signup" class="btn btn-primary">
              Sign Up<i class="fa-solid fa-caret-right"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- /Find Your Needs -->

  <!-- Clinets -->

  <!-- /Clinets -->
</template>

<script>
import Client from "@/assets/json/home-clients.json";
import "vue3-carousel/dist/carousel.css";

export default {
  data() {
    return {
      Client: Client,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        575: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 4,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 5,
          snapAlign: "start",
        },
      },
    };
  },
};
</script>
