<template>
  <user-header></user-header>
  <page-header :title="title" :text="text" :text1="text1" />
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!-- Sidebar -->
        <div class="col-lg-4 col-xl-3 theiaStickySidebar">
          <user-sidebar></user-sidebar>
        </div>
        <!-- /Sidebar -->

        <!--User Sales -->
        <div class="col-xl-9 col-lg-8">
          <div class="dashboard-header">
            <div class="main-title">
              <h3>My Sales</h3>
              <div id="tableinfo"></div>
            </div>
            <div class="head-info">
              <p>Total Sales <span class="text-primary">(17)</span></p>
            </div>
          </div>
          <div class="table-filter">
            <ul class="filter-item">
              <li>
                <p>Filter</p>
              </li>
              <li>
                <div class="form-sort form-wrap">
                  <span class="form-icon">
                    <img
                      src="@/assets/img/icons/calendar-icon.svg"
                      alt="icon"
                    />
                  </span>
                  <date-picker
                    v-model="startdate"
                    class="form-control datetimepicker"
                    :editable="true"
                    :clearable="false"
                    :input-format="dateFormat"
                    placeholder="Select Date"
                  />
                </div>
              </li>
              <li>
                <div class="form-sort form-wrap">
                  <span class="form-icon">
                    <img
                      src="@/assets/img/icons/calendar-icon.svg"
                      alt="icon"
                    />
                  </span>
                  <date-picker
                    v-model="startdateOne"
                    class="form-control datetimepicker"
                    :editable="true"
                    :clearable="false"
                    :input-format="dateFormat"
                    placeholder="Select Date"
                  />
                </div>
              </li>
              <li>
                <div class="form-sort form-wrap">
                  <span class="form-icon">
                    <img src="@/assets/img/icons/status-icon.svg" alt="icon" />
                  </span>
                  <vue-select
                    id="pensel"
                    :options="Pensel"
                    placeholder="By Status"
                  />
                </div>
              </li>
              <li>
                <div class="form-sort form-wrap">
                  <span class="form-icon">
                    <img src="@/assets/img/icons/user-heart.svg" alt="icon" />
                  </span>
                  <vue-select
                    id="marsel"
                    :options="MarSel"
                    placeholder="Buyer"
                  />
                </div>
              </li>
            </ul>
            <div id="tablefilter"></div>
          </div>
          <div class="table-responsive custom-table">
            <table class="table table-stripe datatable">
              <thead class="thead-light">
                <tr>
                  <th>ID</th>
                  <th>Title</th>
                  <th>Delivery On</th>
                  <th>Seller</th>
                  <th>Feedback</th>
                  <th>Cancel</th>
                  <th>Amount</th>
                  <th>Payment</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="record in User_Sales" :key="record.id">
                  <td>{{ record.ID }}</td>
                  <td class="text-dark">{{ record.Title }}</td>
                  <td>{{ record.DeliveryOn }}</td>
                  <td>
                    <h2 class="table-avatar d-flex align-items-center">
                      <router-link to="/user/user-profile" class="avatar">
                        <img
                          :src="require(`@/assets/img/user/${record.Image}`)"
                          alt="User Image"
                        />
                      </router-link>
                      <router-link to="/user/user-profile">{{
                        record.Seller
                      }}</router-link>
                    </h2>
                  </td>
                  <td>{{ record.Feedback }}</td>
                  <td>{{ record.Cancel }}</td>
                  <td>{{ record.Amount }}</td>
                  <td>{{ record.Payment }}</td>
                  <td>
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#order_status"
                      ><span :class="record.Class">{{ record.Status }}</span></a
                    >
                  </td>
                  <td class="text-end">
                    <div class="dropdown dropdown-action">
                      <a
                        href="#"
                        class="action-icon"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        ><i class="feather-more-vertical"></i
                      ></a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a
                          class="dropdown-item"
                          href="javascript:void(0);"
                          data-bs-toggle="modal"
                          data-bs-target="#order_details"
                          ><i class="feather-eye"></i> View</a
                        >
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="table-footer">
            <div id="tablepage"></div>
          </div>
        </div>
        <!-- /User Sales -->
      </div>
    </div>
  </div>
  <layout-footer></layout-footer>
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
import User_Sales from "@/assets/json/user-sales.json";
export default {
  data() {
    return {
      startdate: currentDate,
      startdateOne: currentDateOne,
      dateFormat: "dd-MM-yyyy",
      User_Sales: User_Sales,
      title: "Home",
      text: "My Sales",
      text1: "My Sales",
      Pensel: ["By Status", "New", "Pending", "Processing"],
      MarSel: ["Buyer", "Jones", "Andrea", "Martin"],
    };
  },
};
</script>
