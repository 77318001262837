<template>
  <user-header></user-header>
  <page-header :title="title" :text="text" :text1="text1" />
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!-- Sidebar -->
        <div class="col-lg-4 col-xl-3 theiaStickySidebar">
          <user-sidebar></user-sidebar>
        </div>
        <!-- /Sidebar -->

        <!--User Wallet -->
        <div class="col-xl-9 col-lg-8">
          <div class="dashboard-header">
            <div class="main-title">
              <h3>Wallet</h3>
              <div id="tableinfo" class="d-none"></div>
            </div>
          </div>
          <div class="wallet-wrap">
            <div class="wallet-list">
              <div class="wallet-item">
                <span>
                  <img src="@/assets/img/icons/wallet-01.svg" alt="icon" />
                </span>
                <div class="wallet-info">
                  <p>Total Amount</p>
                  <h3>$1,302.50</h3>
                </div>
              </div>
              <div class="wallet-item">
                <span>
                  <img src="@/assets/img/icons/wallet-03.svg" alt="icon" />
                </span>
                <div class="wallet-info">
                  <p>Total Credit</p>
                  <h3>$1,292.50</h3>
                </div>
              </div>
              <div class="wallet-item">
                <span>
                  <img src="@/assets/img/icons/wallet-02.svg" alt="icon" />
                </span>
                <div class="wallet-info">
                  <p>Total Debit</p>
                  <h3>$10.00</h3>
                </div>
              </div>
            </div>
            <a
              href="#"
              data-bs-toggle="modal"
              data-bs-target="#add_wallet"
              class="btn btn-primary"
              >Add Wallet</a
            >
          </div>
          <div class="table-filter">
            <ul class="filter-item">
              <li>
                <p>Filter</p>
              </li>
              <li>
                <div class="form-sort form-wrap">
                  <span class="form-icon">
                    <img
                      src="@/assets/img/icons/calendar-icon.svg"
                      alt="icon"
                    />
                  </span>
                  <date-picker
                    v-model="startdate"
                    class="form-control datetimepicker"
                    :editable="true"
                    :clearable="false"
                    :input-format="dateFormat"
                    placeholder="Select Date"
                  />
                </div>
              </li>
              <li>
                <div class="form-sort form-wrap">
                  <span class="form-icon">
                    <img src="@/assets/img/icons/wallet-icon.svg" alt="icon" />
                  </span>
                  <vue-select
                    id="willsel"
                    :options="WillSel"
                    placeholder="Reason"
                  />
                </div>
              </li>
              <li>
                <div class="form-sort form-wrap">
                  <span class="form-icon">
                    <img src="@/assets/img/icons/user-heart.svg" alt="icon" />
                  </span>
                  <vue-select
                    id="transe"
                    :options="TranSe"
                    placeholder="Transaction Type"
                  />
                </div>
              </li>
            </ul>
            <div id="tablefilter"></div>
          </div>
          <div class="table-responsive custom-table">
            <table class="table table-stripe datatable">
              <thead class="thead-light">
                <tr>
                  <th>ID</th>
                  <th>Reason</th>
                  <th>Date & time</th>
                  <th>Type</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="record in User_Wallet" :key="record.id">
                  <td>{{ record.ID }}</td>
                  <td>
                    <h2 class="table-avatar d-flex align-items-center">
                      <a href="javascript:void(0);" class="avatar">
                        <img
                          :src="
                            require(`@/assets/img/services/${record.Image}`)
                          "
                          class="rounded"
                          alt="User Image"
                        />
                      </a>
                      <a href="javascript:void(0);" class="text-dark">{{
                        record.Reason
                      }}</a>
                    </h2>
                  </td>
                  <td>{{ record.Date }}</td>
                  <td>
                    <span :class="record.Class"
                      ><i :class="record.ClassOne"></i> {{ record.Type }}</span
                    >
                  </td>
                  <td>{{ record.Amount }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="table-footer">
            <div id="tablepage"></div>
          </div>
        </div>
        <!-- /User Wallet -->
      </div>
    </div>
  </div>
  <layout-footer></layout-footer>
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import User_Wallet from "@/assets/json/user-wallet.json";
export default {
  data() {
    return {
      startdate: currentDate,
      dateFormat: "dd-MM-yyyy",
      User_Wallet: User_Wallet,
      title: "Home",
      text: "Wallet",
      text1: "Wallet",
      WillSel: [
        "Reason",
        "I will do designing..",
        "Develop open..",
        "Professional lifestyle",
      ],
      TranSe: ["Transaction Type", "Debit", "Credit"],
    };
  },
};
</script>
