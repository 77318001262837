<template>
  <div class="service-wrap">
    <div class="row align-items-center">
      <div class="col-sm-8">
        <h3>Checkout My Recent Works</h3>
      </div>
      <div class="col-sm-4">
        <div class="owl-nav mynav1 nav-control"></div>
      </div>
    </div>
    <div class="owl-carousel recent-carousel">
      <Carousel
        :wrap-around="true"
        :settings="settings"
        :breakpoints="breakpoints"
      >
        <Slide v-for="record in User_Profile" :key="record.id">
          <div class="recent-img">
            <img
              :src="require(`@/assets/img/service/${record.Image}`)"
              class="img-fluid"
              alt="Slider Img"
            />
          </div>
        </Slide>
        <template #addons>
          <Navigation />
        </template>
      </Carousel>
    </div>
  </div>
</template>
<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import User_Profile from "@/assets/json/user-profile.json";
export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      DateSel: ["Recommended", "Date"],
      User_Profile: User_Profile,
      title: "Home",
      text: "Blog Grid",
      text1: "Blog Grid",
      settings: {
        itemsToShow: 3,
        snapAlign: "center",
      },

      breakpoints: {
        575: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
};
</script>
