<template>
  <div
    id="connect_nexmo"
    class="modal new-modal fade"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Nexmo</h5>
          <button type="button" class="close-btn" data-bs-dismiss="modal">
            <span>×</span>
          </button>
        </div>
        <div class="modal-body service-modal">
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-md-12">
                <div class="form-wrap">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="API Key"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="API Secret Key"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Sender ID"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap service-option">
                  <label class="custom_radio">
                    <input type="radio" name="gateway" checked />
                    <span class="checkmark"></span>Active
                  </label>
                  <label class="custom_radio">
                    <input type="radio" name="gateway" />
                    <span class="checkmark"></span>Inactive
                  </label>
                </div>
              </div>
              <div class="col-md-12">
                <div class="btn-item">
                  <a href="#" class="btn btn-secondary" data-bs-dismiss="modal"
                    >Cancel</a
                  >
                  <button class="btn btn-primary" type="submit">Submit</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "Home",
      text: "Settings",
      text1: "Settings",
    };
  },
  methods: {
    submitForm() {
      this.$router.push("/user/integration-settings");
    },
  },
};
</script>
