<template>
  <user-header></user-header>
  <page-header :title="title" :text="text" :text1="text1" />
  <div class="page-content">
    <div class="privacy-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="terms-policy">
              <h4>Effective Date: January 1, 2025</h4>

              <p>
                Welcome to Portfolavo! By accessing or using our platform, you
                agree to comply with and be bound by these Terms & Conditions.
                Please read them carefully before using the platform. If you do
                not agree to any part of these terms, please refrain from using
                our services.
              </p>

              <h5>1. Definitions</h5>
              <p style="padding-left: 10px">
                We collect the following types of information to provide and
                improve our services:
              </p>

              <ul style="padding-left: 15px">
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  "Platform": Refers to Portfolavo, including its website,
                  mobile applications, and other related services.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  "User": Any individual or entity using the platform, including
                  freelancers and clients.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  "Freelancer": A user offering audiovisual services.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  "Client": A user seeking audiovisual services.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  "Project": Any job or task posted by a client on the platform.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  "Deliverables": Completed work provided by freelancers to
                  clients.
                </li>
              </ul>

              <h5>2. Eligibility</h5>
              <ul style="padding-left: 15px">
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Users must be at least 18 years old to register and use the
                  platform.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  The platform is primarily intended for users residing in
                  Algeria or those working with Algerian clients and
                  freelancers.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  By using Portfolavo, you represent and warrant that all
                  registration and profile information you submit is accurate
                  and truthful.
                </li>
              </ul>

              <h5>3. Account Registration</h5>
              <ul style="padding-left: 15px">
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Users must create an account to access services.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  You are responsible for maintaining the confidentiality of
                  your account credentials.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Portfolavo reserves the right to suspend or terminate accounts
                  for violations of these terms or misuse of the platform.
                </li>
              </ul>

              <h5>4. Services Offered</h5>
              <p style="padding-left: 10px">
                Portfolavo exclusively focuses on audiovisual fields, including
                but not limited to:
              </p>
              <ul style="padding-left: 15px">
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Video editing
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Sound design
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Animation
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Voiceover work
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Graphic design related to audiovisual production
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Scriptwriting for audiovisual projects
                </li>
              </ul>

              <h5>5. User Conduct</h5>
              <p style="padding-left: 10px">All users agree to:</p>
              <ul style="padding-left: 15px">
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Use the platform only for lawful purposes.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Refrain from engaging in fraudulent, defamatory, or harmful
                  activities.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Respect intellectual property rights and ensure that all
                  uploaded materials comply with copyright laws.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Avoid spamming, phishing, or engaging in activities that
                  disrupt the platform’s operation.
                </li>
              </ul>
              <p style="padding-left: 10px">
                Freelancers agree to deliver high-quality work that meets client
                requirements. Clients agree to provide clear and complete
                project briefs to enable successful delivery.
              </p>

              <h5>6. Projects and Payment</h5>

              <h6>a. Posting and Accepting Projects</h6>
              <ul style="padding-left: 15px">
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Clients can post projects for freelancers to bid on.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Freelancers must submit proposals tailored to the client's
                  requirements.
                </li>
              </ul>

              <h6>b. Payments</h6>
              <ul style="padding-left: 15px">
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Payments must be processed exclusively through Portfolavo's
                  secure payment system.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Funds are held in escrow until the client approves the project
                  deliverables.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Portfolavo charges a service fee on all completed transactions
                  (fee structure will be disclosed at registration).
                </li>
              </ul>

              <h6>c. Dispute Resolution</h6>
              <ul style="padding-left: 15px">
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  In case of disputes, both parties agree to seek resolution
                  through Portfolavo's mediation process before pursuing
                  external legal remedies.
                </li>
              </ul>

              <h5>7. Intellectual Property</h5>
              <ul style="padding-left: 15px">
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Freelancers retain ownership of their intellectual property
                  until payment is made.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Upon payment, the client receives rights to the agreed
                  deliverables.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Users may not use, modify, or distribute content without
                  proper authorization from the rightful owner.
                </li>
              </ul>

              <h5>8. Fees and Taxes</h5>
              <ul style="padding-left: 15px">
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Portfolavo charges service fees for connecting clients and
                  freelancers.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Users are responsible for any applicable taxes, including
                  income tax, VAT, or similar charges, as per Algerian law.
                </li>
              </ul>

              <h5>9. Confidentiality</h5>
              <ul style="padding-left: 15px">
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Both clients and freelancers must respect the confidentiality
                  of project details and discussions conducted through the
                  platform.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Users may not disclose sensitive information without prior
                  written consent from the other party.
                </li>
              </ul>

              <h5>10. Limitations of Liability</h5>
              <ul style="padding-left: 15px">
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Portfolavo is not responsible for the quality of work
                  delivered by freelancers.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Portfolavo is not responsible for project outcomes or
                  disagreements between users.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Portfolavo is not responsible for loss of data due to platform
                  outages or unforeseen circumstances.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Our total liability under any circumstances will not exceed
                  the fees paid to Portfolavo for the specific project in
                  question.
                </li>
              </ul>

              <h5>11. Prohibited Activities</h5>
              <ul style="padding-left: 15px">
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Circumventing platform fees by contacting other users directly
                  for project work.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Posting false or misleading information.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Uploading harmful or inappropriate content.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Using bots, scrapers, or similar automated tools to interact
                  with the platform.
                </li>
              </ul>

              <h5>12. Termination of Accounts</h5>
              <ul style="padding-left: 15px">
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Portfolavo reserves the right to suspend or terminate accounts
                  that violate these Terms & Conditions.
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Portfolavo reserves the right to withhold payments in cases of
                  fraudulent or unethical activities.
                </li>
              </ul>

              <h5>13. Modifications to the Platform</h5>
              <p style="padding-left: 10px">
                We reserve the right to modify or discontinue the platform (or
                any part thereof) at any time without notice.
              </p>

              <h5>14. Governing Law</h5>
              <p style="padding-left: 10px">
                These Terms & Conditions are governed by the laws of Algeria.
                Any disputes will be subject to the exclusive jurisdiction of
                Algerian courts.
              </p>

              <h5>15. Changes to Terms & Conditions</h5>
              <p style="padding-left: 10px">
                Portfolavo reserves the right to amend these terms at any time.
                Significant changes will be communicated to users via email or
                notifications on the platform. Continued use of the platform
                constitutes acceptance of the updated terms.
              </p>

              <h5>16. Contact Us</h5>
              <p style="padding-left: 10px">
                If you have questions or concerns about this Privacy Policy,
                please contact us:
              </p>
              <p style="padding-left: 10px">Email: support@portfolavo.com</p>
              <p style="padding-left: 10px">
                Thank you for trusting Portfolavo! Together, we’re creating a
                platform where creativity meets opportunity.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <layout-footer></layout-footer>
</template>
<script>
export default {
  data() {
    return {
      title: "Home",
      text: "Terms & Conditions",
      text1: "Terms & Conditions",
    };
  },
};
</script>
