<template>
  <user-header></user-header>
  <page-header :title="title" :text="text" :text1="text1" />
  <div class="page-content content">
    <div class="container">
      <div class="page-back-btn">
        <router-link to="/user/user-dashboard"
          ><i class="feather-arrow-left me-2"></i>Back to Dashboard</router-link
        >
      </div>
      <settings-sidebar></settings-sidebar>
      <div class="row">
        <div class="col-xl-3 col-md-4 col-sm-6">
          <div class="settings-card">
            <div class="settings-card-body d-flex justify-content-between">
              <h6>Purchase List</h6>
              <div class="status-toggle d-flex align-items-center">
                <input id="toggle1" type="checkbox" class="check" checked="" />
                <label for="toggle1" class="checktoggle"></label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-4 col-sm-6">
          <div class="settings-card">
            <div class="settings-card-body d-flex justify-content-between">
              <h6>Sales List</h6>
              <div class="status-toggle d-flex align-items-center">
                <input id="toggle2" type="checkbox" class="check" checked="" />
                <label for="toggle2" class="checktoggle"></label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-4 col-sm-6">
          <div class="settings-card">
            <div class="settings-card-body d-flex justify-content-between">
              <h6>Uploaded Files</h6>
              <div class="status-toggle d-flex align-items-center">
                <input id="toggle3" type="checkbox" class="check" checked="" />
                <label for="toggle3" class="checktoggle"></label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-4 col-sm-6">
          <div class="settings-card">
            <div class="settings-card-body d-flex justify-content-between">
              <h6>Reviews</h6>
              <div class="status-toggle d-flex align-items-center">
                <input id="toggle4" type="checkbox" class="check" checked="" />
                <label for="toggle4" class="checktoggle"></label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-4 col-sm-6">
          <div class="settings-card">
            <div class="settings-card-body d-flex justify-content-between">
              <h6>Wishlist</h6>
              <div class="status-toggle d-flex align-items-center">
                <input id="toggle5" type="checkbox" class="check" checked="" />
                <label for="toggle5" class="checktoggle"></label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-4 col-sm-6">
          <div class="settings-card">
            <div class="settings-card-body d-flex justify-content-between">
              <h6>Chat</h6>
              <div class="status-toggle d-flex align-items-center">
                <input id="toggle6" type="checkbox" class="check" checked="" />
                <label for="toggle6" class="checktoggle"></label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-4 col-sm-6">
          <div class="settings-card">
            <div class="settings-card-body d-flex justify-content-between">
              <h6>Wallet</h6>
              <div class="status-toggle d-flex align-items-center">
                <input id="toggle7" type="checkbox" class="check" checked="" />
                <label for="toggle7" class="checktoggle"></label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-4 col-sm-6">
          <div class="settings-card">
            <div class="settings-card-body d-flex justify-content-between">
              <h6>Payments</h6>
              <div class="status-toggle d-flex align-items-center">
                <input id="toggle8" type="checkbox" class="check" checked="" />
                <label for="toggle8" class="checktoggle"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-item mb-4">
        <a href="#" class="btn btn-secondary">Cancel</a>
        <a href="#" class="btn btn-primary">Save Changes</a>
      </div>
    </div>
  </div>
  <layout-footer></layout-footer>
</template>
<script>
export default {
  data() {
    return {
      title: "Home",
      text: "Settings",
      text1: "Settings",
    };
  },
};
</script>
