<template>
  <user-header></user-header>
  <page-header :title="title" :text="text" :text1="text1" />
  <section class="price-section">
    <div class="container">
      <div class="tab-content">
        <!-- Monthly Plans -->
        <div id="monthly" class="tab-pane active show">
          <div class="row justify-content-center">
            <!-- Basic Plan -->
            <div class="col-lg-4 col-md-6">
              <div
                class="price-card aos"
                data-aos="flip-right"
                data-aos-easing="ease-out-cubic"
              >
                <div class="price-title">
                  <div class="plan-type">
                    <h3>Basic</h3>
                  </div>
                  <div class="amt-item">
                    <h2>2,000 DZD</h2>
                    <p>month</p>
                  </div>
                </div>
                <div class="price-features">
                  <h6>Includes</h6>
                  <ul>
                    <li>
                      <span><i class="bx bx-check-double"></i></span>3 Active
                      Projects
                    </li>
                    <li>
                      <span><i class="bx bx-check-double"></i></span>10 Service
                      Listings
                    </li>
                    <li>
                      <span><i class="bx bx-check-double"></i></span>5 Proposals
                    </li>
                    <li>
                      <span><i class="bx bx-check-double"></i></span>Email
                      Support
                    </li>
                    <li class="inactive">
                      <span><i class="bx bx-x"></i></span>Priority Support
                    </li>
                  </ul>
                </div>
                <div class="price-btn">
                  <a href="javascript:void(0);" class="btn-primary">
                    Choose Plan<i class="feather-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <!-- Standard Plan -->
            <div class="col-lg-4 col-md-6">
              <div
                class="price-card active aos"
                data-aos="flip-right"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="1000"
              >
                <div class="price-title">
                  <div class="plan-type">
                    <h3>Standard</h3>
                    <span class="badge">Recommended</span>
                  </div>
                  <div class="amt-item">
                    <h2>5,000 DZD</h2>
                    <p>month</p>
                  </div>
                </div>
                <div class="price-features">
                  <h6>Includes</h6>
                  <ul>
                    <li>
                      <span><i class="bx bx-check-double"></i></span>10 Active
                      Projects
                    </li>
                    <li>
                      <span><i class="bx bx-check-double"></i></span>50 Service
                      Listings
                    </li>
                    <li>
                      <span><i class="bx bx-check-double"></i></span>20
                      Proposals
                    </li>
                    <li>
                      <span><i class="bx bx-check-double"></i></span>24/7
                      Support
                    </li>
                    <li>
                      <span><i class="bx bx-check-double"></i></span>Basic
                      Analytics
                    </li>
                  </ul>
                </div>
                <div class="price-btn">
                  <a href="javascript:void(0);" class="btn-primary">
                    Choose Plan<i class="feather-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <!-- Premium Plan -->
            <div class="col-lg-4 col-md-6">
              <div
                class="price-card aos"
                data-aos="flip-right"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="2000"
              >
                <div class="price-title">
                  <div class="plan-type">
                    <h3>Premium</h3>
                  </div>
                  <div class="amt-item">
                    <h2>10,000 DZD</h2>
                    <p>month</p>
                  </div>
                </div>
                <div class="price-features">
                  <h6>Includes</h6>
                  <ul>
                    <li>
                      <span><i class="bx bx-check-double"></i></span>Unlimited
                      Projects
                    </li>
                    <li>
                      <span><i class="bx bx-check-double"></i></span>Unlimited
                      Service Listings
                    </li>
                    <li>
                      <span><i class="bx bx-check-double"></i></span>Unlimited
                      Proposals
                    </li>
                    <li>
                      <span><i class="bx bx-check-double"></i></span>Dedicated
                      Account Manager
                    </li>
                    <li>
                      <span><i class="bx bx-check-double"></i></span>Advanced
                      Analytics
                    </li>
                  </ul>
                </div>
                <div class="price-btn">
                  <a href="javascript:void(0);" class="btn-primary">
                    Choose Plan<i class="feather-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Yearly Plans -->
        <div id="yearly" class="tab-pane fade">
          <div class="row justify-content-center">
            <!-- Yearly Plans can follow similar structure with adjusted pricing -->
            <!-- For instance, yearly plans may offer a 10-15% discount -->
          </div>
        </div>
      </div>
    </div>
  </section>

  <layout-footer></layout-footer>
</template>
<script>
export default {
  data() {
    return {
      title: "Home",
      text: "Pricing",
      text1: "Pricing",
    };
  },
};
</script>
