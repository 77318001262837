<template>
  <div class="filters-section">
    <ul class="filters-wrap">
      <!-- Categories -->
      <li>
        <div class="collapse-card">
          <div class="filter-header">
            <a href="javascript:void(0);">
              <img
                src="@/assets/img/icons/category-icon.svg"
                alt="icon"
              />Categories
            </a>
          </div>
          <div id="categories" class="collapse-body">
            <div class="form-group search-group">
              <span class="search-icon"><i class="feather-search"></i></span>
              <input
                type="text"
                class="form-control"
                placeholder="Search Category"
              />
            </div>
            <ul class="checkbox-list">
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">Programming & Coding</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">Data Science & Analysis</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">Databases </span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">Mobile App Development</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">Email Template Development</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">CMS Development</span>
                </label>
              </li>
              <li>
                <div class="view-content">
                  <div class="viewall-one">
                    <ul>
                      <li>
                        <label class="custom_check">
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="checked-title"
                            >ECommerce CMS Development</span
                          >
                        </label>
                      </li>
                      <li>
                        <label class="custom_check">
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="checked-title">Programming</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="view-all">
                  <a href="javascript:void(0);" class="viewall-button-one"
                    ><span>More 20+ Categories</span></a
                  >
                </div>
              </li>
            </ul>
            <div class="filter-btn">
              <a href="javascript:void(0);">Reset</a>
              <button class="btn btn-primary">Apply</button>
            </div>
          </div>
        </div>
      </li>
      <!-- /Categories -->

      <!-- Locations -->
      <li>
        <div class="collapse-card">
          <div class="filter-header">
            <a href="javascript:void(0);">
              <img src="@/assets/img/icons/map-icon.svg" alt="icon" />Locations
            </a>
          </div>
          <div id="locations" class="collapse-body">
            <div class="form-group search-group">
              <span class="search-icon"><i class="feather-search"></i></span>
              <input
                type="text"
                class="form-control"
                placeholder="Search Locations"
              />
            </div>
            <ul class="checkbox-list">
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">Canada</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">Bolivia</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">Tunsania</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">Indonesia</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">UK</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">UAE</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="checked-title">USA</span>
                </label>
              </li>
              <li>
                <div class="view-content">
                  <div class="viewall-location">
                    <ul>
                      <li>
                        <label class="custom_check">
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="checked-title">Malaysia</span>
                        </label>
                      </li>
                      <li>
                        <label class="custom_check">
                          <input type="checkbox" />
                          <span class="checkmark"></span>
                          <span class="checked-title">Japan</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="view-all">
                  <a href="javascript:void(0);" class="viewall-btn-location"
                    ><span>More 20+ Locations</span></a
                  >
                </div>
              </li>
            </ul>
            <div class="filter-btn">
              <a href="javascript:void(0);">Reset</a>
              <button class="btn btn-primary">Apply</button>
            </div>
          </div>
        </div>
      </li>
      <!-- /Locations -->

      <!-- Ratings -->
      <li>
        <div class="collapse-card">
          <div class="filter-header">
            <a href="javascript:void(0);">
              <img src="@/assets/img/icons/rating-icon.svg" alt="icon" />Reviews
            </a>
          </div>
          <div id="ratings" class="collapse-body">
            <ul class="checkbox-list star-rate">
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="ratings">
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                  </span>
                  <span class="rating-count">(5.0)</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="ratings">
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star"></i>
                  </span>
                  <span class="rating-count">(4.0)</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="ratings">
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                  </span>
                  <span class="rating-count">(3.0)</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="ratings">
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                  </span>
                  <span class="rating-count">(2.0)</span>
                </label>
              </li>
              <li>
                <label class="custom_check">
                  <input type="checkbox" />
                  <span class="checkmark"></span>
                  <span class="ratings">
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                  </span>
                  <span class="rating-count">(1.0)</span>
                </label>
              </li>
            </ul>
            <div class="filter-btn">
              <a href="javascript:void(0);">Reset</a>
              <button class="btn btn-primary">Apply</button>
            </div>
          </div>
        </div>
      </li>
      <!-- /Ratings -->

      <!-- Budget -->
      <li>
        <div class="collapse-card">
          <div class="filter-header">
            <a href="javascript:void(0);" @click="toggleCollapse">
              <img src="@/assets/img/icons/money-icon.svg" alt="icon" />Budget
            </a>
          </div>
          <div v-show="isCollapsed" id="budget" class="collapse-body">
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                placeholder="Enter Custom Budget"
              />
            </div>
            <ul class="checkbox-list">
              <li>
                <label class="custom_radio">
                  <input type="radio" name="budget" checked />
                  <span class="checkmark"></span
                  ><span class="text-dark"> Value :</span> Under $4500
                </label>
              </li>
              <li>
                <label class="custom_radio">
                  <input type="radio" name="budget" />
                  <span class="checkmark"></span
                  ><span class="text-dark"> Mid-range :</span> Under $4500
                </label>
              </li>
              <li>
                <label class="custom_radio">
                  <input type="radio" name="budget" />
                  <span class="checkmark"></span
                  ><span class="text-dark"> High-end :</span> Under $4500
                </label>
              </li>
            </ul>
            <div class="filter-btn">
              <a href="javascript:void(0);">Reset</a>
              <button class="btn btn-primary">Apply</button>
            </div>
          </div>
        </div>
      </li>
      <!-- /Budget -->

      <!-- Seller Details -->
      <li v-show="isContentVisible" class="more-content">
        <div class="collapse-card">
          <div class="filter-header">
            <a href="javascript:void(0);">
              <img src="@/assets/img/icons/user-icon.svg" alt="icon" />Seller
              Details
            </a>
          </div>
          <div id="seller" class="collapse-body">
            <ul class="seller-list">
              <li>
                <a href="javascript:void(0):"
                  >Seller Level<span><i class="feather-chevron-right"></i></span
                ></a>
              </li>
              <li>
                <a href="javascript:void(0):"
                  >Seller Availability<span
                    ><i class="feather-chevron-right"></i></span
                ></a>
              </li>
              <li>
                <a href="javascript:void(0):"
                  >Seller Speaks<span
                    ><i class="feather-chevron-right"></i></span
                ></a>
              </li>
              <li>
                <a href="javascript:void(0):"
                  >Seller Lives in<span
                    ><i class="feather-chevron-right"></i></span
                ></a>
              </li>
            </ul>
            <div class="filter-btn">
              <a href="javascript:void(0);">Reset</a>
              <button class="btn btn-primary">Apply</button>
            </div>
          </div>
        </div>
      </li>
      <!-- /Seller Details -->

      <!-- Delivery Time -->
      <li v-show="isContentVisible" class="more-content">
        <div class="collapse-card">
          <div class="filter-header">
            <a href="javascript:void(0);">
              <img src="@/assets/img/icons/time-icon.svg" alt="icon" />Delivery
              Time
            </a>
          </div>
          <div id="deivery" class="collapse show">
            <div class="collapse-body">
              <ul class="checkbox-list">
                <li>
                  <label class="custom_radio">
                    <input type="radio" name="budget" checked />
                    <span class="checkmark"></span>Enter 24H
                  </label>
                </li>
                <li>
                  <label class="custom_radio">
                    <input type="radio" name="budget" />
                    <span class="checkmark"></span>Upto 3 days
                  </label>
                </li>
                <li>
                  <label class="custom_radio">
                    <input type="radio" name="budget" />
                    <span class="checkmark"></span>Upto 7 days
                  </label>
                </li>
                <li>
                  <label class="custom_radio">
                    <input type="radio" name="budget" />
                    <span class="checkmark"></span>Anytime
                  </label>
                </li>
              </ul>
              <div class="filter-btn">
                <a href="javascript:void(0);">Reset</a>
                <button class="btn btn-primary">Apply</button>
              </div>
            </div>
          </div>
        </div>
      </li>
      <!-- /Delivery Time -->

      <li class="view-all">
        <a href="javascript:void(0);" class="show-more" @click="toggleContent">
          <span><img src="@/assets/img/icons/add-icon.svg" alt="img" /></span>
          <span>{{ showMoreText }}</span>
        </a>
      </li>
    </ul>
    <!-- /Filter -->

    <!-- Sort By -->
    <div class="search-filter-selected float-lg-end">
      <div class="form-group">
        <span class="sort-text">Sort By</span>
        <vue-select id="feasel" :options="FeaSel" placeholder="recommended" />
      </div>
    </div>
    <!-- /Sort By -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      isContentVisible: false,
      showMoreText: "Show More",
      isCollapsed: true,
      collapseCard: null,
      FeaSel: [
        "Recommended",
        "Featured",
        "Price: High to Low",
        "Price: Low to High",
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.collapseCard = this.$refs.collapseCard;
    });
  },
  methods: {
    toggleContent() {
      this.isContentVisible = !this.isContentVisible;
      this.showMoreText = this.isContentVisible ? "Show Less" : "Show More";
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
      if (this.collapseCard) {
        const collapseBody = this.collapseCard.querySelector(".collapse-body");
        if (collapseBody) {
          collapseBody.style.display = this.isCollapsed ? "none" : "block";
          this.collapseCard.classList.toggle("active", !this.isCollapsed);
        }
      }
    },
  },
};
</script>
