<template>
  <user-header></user-header>
  <page-header :title="title" :text="text" :text1="text1" />
  <div class="page-content">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="marketing-section service-post">
            <div class="marketing-content">
              <h2>
                <span class="text-primary">Post a Service</span> in few seconds
              </h2>
              <p>
                Service: a packed service you can deliver remotely or around you
                for a fixed price in a set time frame.
              </p>
            </div>
            <div class="services-step">
              <ul>
                <li>
                  <span>
                    <img src="@/assets/img/icons/book-01.svg" alt="img" />
                  </span>
                  <p>Step 01</p>
                  <h6>Create your service</h6>
                </li>
                <li>
                  <span>
                    <img src="@/assets/img/icons/book-02.svg" alt="img" />
                  </span>
                  <p>Step 02</p>
                  <h6>Publish</h6>
                </li>
                <li>
                  <span>
                    <img src="@/assets/img/icons/book-03.svg" alt="img" />
                  </span>
                  <p>Step 03</p>
                  <h6>Receive Orders</h6>
                </li>
                <li>
                  <span>
                    <img src="@/assets/img/icons/book-01.svg" alt="img" />
                  </span>
                  <p>Step 04</p>
                  <h6>Deliver the work</h6>
                </li>
                <li>
                  <span>
                    <img src="@/assets/img/icons/book-04.svg" alt="img" />
                  </span>
                  <p>Step 05</p>
                  <h6>Get Paid</h6>
                </li>
                <li>
                  <span>
                    <img src="@/assets/img/icons/book-05.svg" alt="img" />
                  </span>
                  <p>Step 06</p>
                  <h6>Withdraw Funds</h6>
                </li>
              </ul>
            </div>
            <div class="marketing-bg">
              <img
                src="@/assets/img/bg/market-bg.png"
                alt="img"
                class="market-bg"
              />
              <img
                src="@/assets/img/bg/market-bg-01.png"
                alt="img"
                class="market-img"
              />
            </div>
          </div>
        </div>

        <!-- General -->
        <div class="col-lg-4">
          <div class="property-info">
            <h4>General</h4>
            <p>
              Add the Details of your Service to know the user to receive orders
            </p>
          </div>
        </div>

        <div class="col-lg-8">
          <div class="add-property-wrap">
            <div class="row">
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label">Title for your Service *</label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label">Select Category</label>
                  <vue-select
                    id="busel"
                    :options="BusSel"
                    placeholder="Select Category"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-wrap">
                  <label class="col-form-label">Select Sub Category</label>
                  <vue-select
                    id="ecosel"
                    :options="EcoSel"
                    placeholder="Select Sub Category"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <label class="col-form-label">Description</label>
                  <textarea
                    class="form-control"
                    rows="6"
                    placeholder="Provide more details about your service *"
                  ></textarea>
                </div>
                <div class="form-wrap service-option">
                  <h6>Service Option</h6>
                  <label class="custom_check">
                    <input type="checkbox" />
                    <span class="checkmark"></span>Featured
                  </label>
                  <label class="custom_check">
                    <input type="checkbox" />
                    <span class="checkmark"></span>Hot
                  </label>
                  <label class="custom_check">
                    <input type="checkbox" />
                    <span class="checkmark"></span>New
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /General -->

        <!-- Buyer -->
        <div class="col-lg-4">
          <div class="property-info">
            <h4>Buyer</h4>
            <p>
              Add Extra Service & Other Details Of the Services to earn Extra
              Amount
            </p>
          </div>
        </div>

        <div class="col-lg-8">
          <div class="add-property-wrap">
            <div class="add-content">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-wrap">
                    <label class="col-form-label">I Can</label>
                    <input type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-wrap">
                    <label class="col-form-label">For ($)</label>
                    <input type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-wrap">
                    <label class="col-form-label">In (Day)</label>
                    <input type="text" class="form-control" />
                  </div>
                </div>
              </div>
              <div
                v-for="(Medications, index) in Medications"
                :key="index"
                class="row sign-cont"
              >
                <div class="col-md-4">
                  <div class="form-wrap">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="I can"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-wrap">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="For ($)"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-wrap d-flex align-items-center">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="In (Day)"
                    /><a
                      href="javascript:void(0);"
                      class="trash-sign ms-2 text-danger"
                      @click="deleteMedications(index)"
                      ><i class="feather-trash-2"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <a
                  href="javascript:void(0);"
                  class="btn btn-secondary amount-add"
                  @click="addMedications"
                  ><i class="feather-plus-circle"></i>Add New</a
                >
              </div>
              <div class="col-md-12">
                <label class="custom_check extra-serv">
                  <input type="checkbox" class="disable-check" />
                  <span class="checkmark"></span>Superfast Delivery
                </label>
              </div>
              <div class="col-md-4">
                <div class="form-wrap">
                  <label class="col-form-label">I Can</label>
                  <input type="text" class="form-control exta-label" disabled />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-wrap">
                  <label class="col-form-label">For ($)</label>
                  <input type="text" class="form-control exta-label" disabled />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-wrap">
                  <label class="col-form-label">In (Day)</label>
                  <input type="text" class="form-control exta-label" disabled />
                </div>
              </div>
              <div class="col-md-12">
                <h6>How are you planning to work with the Buyer? *</h6>
                <div class="form-wrap service-option">
                  <label class="custom_radio">
                    <input type="radio" name="buyer" checked />
                    <span class="checkmark"></span>Remote
                  </label>
                  <label class="custom_radio">
                    <input type="radio" name="buyer" />
                    <span class="checkmark"></span>On-site
                  </label>
                </div>
                <div class="form-wrap">
                  <label class="col-form-label">Description</label>
                  <textarea
                    class="form-control"
                    rows="6"
                    placeholder="What do you need from the Buyer to get started"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Buyer -->

        <!-- Upload -->
        <div class="col-lg-4">
          <div class="property-info">
            <h4>Upload</h4>
            <p>Add images and videos for your services.</p>
          </div>
        </div>

        <div class="col-lg-8">
          <div class="add-property-wrap">
            <div class="row">
              <div class="col-md-12">
                <h6>Upload images videos and more</h6>
                <ul class="nav upload-list">
                  <li>
                    <a
                      href="#"
                      class="active"
                      data-bs-toggle="tab"
                      data-bs-target="#upload-img"
                    >
                      <span>
                        <img
                          src="@/assets/img/icons/upload-01.svg"
                          alt="icon"
                        />
                      </span>
                      <h6>Upload Image</h6>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      data-bs-toggle="tab"
                      data-bs-target="#upload-video"
                    >
                      <span>
                        <img
                          src="@/assets/img/icons/upload-02.svg"
                          alt="icon"
                        />
                      </span>
                      <h6>Upload Videos</h6>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      data-bs-toggle="tab"
                      data-bs-target="#upload-link"
                    >
                      <span>
                        <img
                          src="@/assets/img/icons/upload-03.svg"
                          alt="icon"
                        />
                      </span>
                      <h6>Upload Video Link</h6>
                    </a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div id="upload-img" class="tab-pane show active">
                    <div class="drag-upload form-wrap">
                      <input type="file" accept="image/*" />
                      <div class="img-upload">
                        <p>
                          <i class="feather-upload-cloud"></i>Drag or Upload
                          Image
                        </p>
                      </div>
                    </div>
                    <div class="upload-wrap">
                      <div class="upload-image">
                        <span>
                          <i class="feather-image"></i>
                        </span>
                        <h6>Worklog.png</h6>
                      </div>
                      <a href="javascript:void(0);" class="del-action"
                        ><i class="feather-trash-2"></i
                      ></a>
                    </div>
                  </div>
                  <div id="upload-video" class="tab-pane fade">
                    <div class="drag-upload form-wrap">
                      <input type="file" accept="video/*" />
                      <div class="img-upload">
                        <p>
                          <i class="feather-upload-cloud"></i>Drag or Upload
                          Video
                        </p>
                      </div>
                    </div>
                    <div class="upload-wrap">
                      <div class="upload-image">
                        <span>
                          <i class="feather-image"></i>
                        </span>
                        <h6>Video_gig-1.mp4</h6>
                      </div>
                      <a href="javascript:void(0);" class="del-action"
                        ><i class="feather-trash-2"></i
                      ></a>
                    </div>
                  </div>
                  <div id="upload-link" class="tab-pane fade">
                    <div class="form-wrap">
                      <label class="col-form-label">Upload video link *</label>
                      <input type="text" class="form-control" />
                    </div>
                    <div class="form-wrap">
                      <label class="col-form-label">Vimeo link *</label>
                      <input type="text" class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="confirm-content">
                  <div class="form-wrap">
                    <label class="custom_check">
                      <input type="checkbox" />
                      <span class="checkmark"></span> I confirm that I am able
                      to deliver this service to Buyers within the delivery time
                      specified.I will update or pause my Service if I can no
                      longer meet this delivery time.I understand that late
                      delivery will adversely affect my rankings on Portfolavo
                      And will entitle the buyer to a refund. See Terms &
                      Conditions
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btn-item text-end">
            <a href="#" class="btn btn-secondary">Cancel</a>
            <a
              href="#"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#success_gigs"
              >Publish Service</a
            >
          </div>
        </div>
        <!-- /Upload -->
      </div>
    </div>
  </div>
  <layout-footer></layout-footer>
  <div id="success_gigs" class="modal custom-modal fade">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="success-message text-center">
            <div class="success-popup-icon">
              <img src="@/assets/img/icons/happy-icon.svg" alt="icon" />
            </div>
            <div class="success-content">
              <h4>Services Published Successfully</h4>
              <p>
                Updated New Services
                <span>“Designing and developing software applications”</span> in
                the list
              </p>
            </div>
            <div class="col-lg-12 text-center">
              <router-link to="/user/user-services" class="btn btn-primary"
                >Back to Services</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      EcoSel: ["Select Sub Category", "Website Promotion", "Ecommerce-Seo"],
      BusSel: ["Select Category", "Business", "Design"],
      title: "Home",
      text: "Edit Services",
      text1: "Edit Services",
      Medications: [],
    };
  },
  methods: {
    addMedications() {
      this.Medications.push({});
    },
    deleteMedications(index) {
      this.Medications.splice(index, 1);
    },
  },
};
</script>
