<template>
  <!-- <services-loader></services-loader> -->

  <user-header></user-header>

  <home-banner></home-banner>

  <explore-services></explore-services>

  <home-popular></home-popular>

  <home-clients></home-clients>

  <popular-list></popular-list>

  <home-testimonials></home-testimonials>

  <home-faq></home-faq>

  <layout-footer></layout-footer>

  <!-- <services-scroll></services-scroll> -->
</template>
