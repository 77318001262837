<template>
  <user-header></user-header>
  <page-header :title="title" :text="text" :text1="text1" />
  <section class="contact-section">
    <!-- Contact Bottom -->
    <div class="contact-bottom bg-white">
      <div class="container">
        <div class="row justify-content-center">
          <!-- Contact Grid -->
          <div class="col-xl-4 col-lg-6 d-flex">
            <div class="contact-grid con-info w-100">
              <div class="contact-content">
                <div class="contact-icon">
                  <span>
                    <img src="@/assets/img/icons/contact-mail.svg" alt="Icon" />
                  </span>
                </div>
                <div class="contact-details">
                  <p>
                    <a href="javascript:void(0);">contact@portfolavo.com</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- /Contact Grid -->

          <!-- Contact Grid -->
          <div class="col-xl-4 col-lg-6 d-flex">
            <div class="contact-grid con-info w-100">
              <div class="contact-content">
                <div class="contact-icon">
                  <span>
                    <img
                      src="@/assets/img/icons/contact-phone.svg"
                      alt="Icon"
                    />
                  </span>
                </div>
                <div class="contact-details">
                  <p>+213 0XX XX XX XX</p>
                </div>
              </div>
            </div>
          </div>
          <!-- /Contact Grid -->

          <!-- Contact Grid -->
          <div class="col-xl-4 col-lg-6 d-flex">
            <div class="contact-grid con-info w-100">
              <div class="contact-content">
                <div class="contact-icon">
                  <span>
                    <img src="@/assets/img/icons/contact-map.svg" alt="Icon" />
                  </span>
                </div>
                <div class="contact-details contact-details-address">
                  <p>Algiers, Algeria</p>
                </div>
              </div>
            </div>
          </div>
          <!-- /Contact Grid -->
        </div>
      </div>
    </div>
    <!-- /Contact Bottom -->

    <!-- Contact Top -->
    <div class="contact-top pt-0">
      <div class="container">
        <div class="row align-items-center">
          <!-- Contact Image -->
          <div class="col-lg-6 col-md-12 d-flex">
            <div class="contact-img">
              <img
                src="@/assets/img/contact-01.png"
                class="img-fluid"
                alt="img"
              />
            </div>
          </div>
          <!-- /Contact Image -->

          <!-- Contact Form -->
          <div class="col-lg-6 col-md-12 d-flex">
            <div class="team-form w-100">
              <div class="team-form-heading">
                <h3>Get in Touch</h3>
                <p>How can help I you? Please write down your query</p>
              </div>
              <form action="#">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Name"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Phone"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Email"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Subject"
                      />
                    </div>
                    <div class="form-group">
                      <textarea
                        class="form-control"
                        placeholder="Your Message"
                      ></textarea>
                    </div>
                    <div class="form-group mb-0">
                      <button type="submit" class="btn btn-primary">
                        Send Message
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- /Contact Form -->
        </div>
      </div>
    </div>
    <!-- /Contact Top -->
  </section>
  <layout-footer></layout-footer>
</template>
<script>
export default {
  data() {
    return {
      title: "Home",
      text: "Contact Us",
      text1: "Contact Us",
    };
  },
};
</script>
