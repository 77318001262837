<template>
  <user-header></user-header>
  <page-header :title="title" :text="text" :text1="text1" />
  <div class="page-content">
    <div class="privacy-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="terms-policy">
              <h4>Effective Date: January 1, 2025</h4>

              <p>
                Welcome to Portfolavo! Your privacy is important to us. This
                Privacy Policy explains how we collect, use, and protect your
                personal information when you use our platform.
              </p>

              <h5>1. Information We Collect</h5>
              <p style="padding-left: 10px">
                We collect the following types of information to provide and
                improve our services:
              </p>

              <ul style="padding-left: 15px">
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Personal Information: Name, email address, contact details,
                  and payment information
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Non-Personal Information: IP address, browser type, device
                  information, and usage data
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  User Content: Uploaded portfolios, project descriptions, and
                  platform communications
                </li>
              </ul>

              <h5>2. How We Use Your Information</h5>
              <p style="padding-left: 10px">
                We use your information for the following purposes:
              </p>
              <ul style="padding-left: 15px">
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Facilitate connections between freelancers and clients
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Process payments and manage transactions
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Improve platform functionality and user experience
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Send important updates and promotional offers (with your
                  consent)
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Ensure the security of our platform
                </li>
              </ul>

              <h5>3. Sharing of Information</h5>
              <p style="padding-left: 10px">
                We do not sell your personal information to third parties.
                However, we may share information in the following cases:
              </p>
              <ul style="padding-left: 15px">
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  With Service Providers: To support platform operations (e.g.,
                  payment processors, hosting services)
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  With Clients or Freelancers: To facilitate project
                  collaborations
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  As Required by Law: To comply with legal obligations or
                  protect rights and safety
                </li>
              </ul>

              <h5>4. Cookies and Tracking Technologies</h5>
              <p style="padding-left: 10px">We use cookies to:</p>
              <ul style="padding-left: 15px">
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Enhance your browsing experience
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Analyze platform performance and usage
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Deliver targeted advertisements (if applicable)
                </li>
              </ul>
              <p style="padding-left: 10px">
                You can manage cookie preferences through your browser settings.
              </p>

              <h5>5. Data Security</h5>
              <p style="padding-left: 10px">
                We implement robust security measures to protect your personal
                information. However, no online platform can guarantee absolute
                security. Use the platform responsibly and notify us immediately
                of any suspicious activity.
              </p>

              <h5>6. Your Rights</h5>
              <p style="padding-left: 10px">
                You have the following rights regarding your data:
              </p>
              <ul style="padding-left: 15px">
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Access your personal information
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Update or correct inaccuracies
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Request deletion of your data
                </li>
                <li>
                  <span><i class="fa-solid fa-circle-check"></i></span>
                  Opt-out of promotional communications
                </li>
              </ul>
              <p style="padding-left: 10px">
                To exercise these rights, contact us at support@portfolavo.com.
              </p>

              <h5>7. Third-Party Links</h5>
              <p style="padding-left: 10px">
                Our platform may contain links to third-party websites. We are
                not responsible for their privacy practices or content. Review
                their policies before engaging.
              </p>

              <h5>8. Children's Privacy</h5>
              <p style="padding-left: 10px">
                Portfolavo is not intended for users under 18. We do not
                knowingly collect information from minors. If we learn of such
                instances, we will delete the information promptly.
              </p>

              <h5>9. Changes to This Privacy Policy</h5>
              <p style="padding-left: 10px">
                We may update this policy periodically. Significant changes will
                be communicated through the platform or via email. Continued use
                of Portfolavo constitutes acceptance of the updated policy.
              </p>

              <h5>10. Contact Us</h5>
              <p style="padding-left: 10px">
                If you have questions or concerns about this Privacy Policy,
                please contact us:
              </p>
              <p style="padding-left: 10px">Email: support@portfolavo.com</p>
              <p style="padding-left: 10px">
                Thank you for trusting Portfolavo! Together, we’re creating a
                platform where creativity meets opportunity.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <layout-footer></layout-footer>
</template>

<script>
export default {
  data() {
    return {
      title: "Home",
      text: "Privacy Policy",
      text1: "Privacy Policy",
    };
  },
};
</script>
