import { createRouter, createWebHistory } from "vue-router";

import Login from "@/views/pages/auth/sign-in.vue";
import Sign_Up from "@/views/pages/auth/sign-up.vue";
import Forgot_Password from "@/views/pages/auth/forgot-password.vue";

import Contact_Us from "@/views/pages/contact-us.vue";
import User_Index from "@/views/pages/user/user-index.vue";
import User_Settings from "@/views/pages/user/user-settings/user-settings.vue";
import Security_Settings from "@/views/pages/user/user-settings/security-settings.vue";
import Preference_Settings from "@/views/pages/user/user-settings/preference-settings.vue";
import Billing_Settings from "@/views/pages/user/user-settings/billing-settings.vue";
import Notification_Settings from "@/views/pages/user/user-settings/notification-settings.vue";
import Integration_Settings from "@/views/pages/user/user-settings/integration-settings.vue";
import User_Payments from "@/views/pages/user/user-payments.vue";
import User_Wallet from "@/views/pages/user/user-wallet.vue";
import User_Reviews from "@/views/pages/user/user-reviews.vue";
import User_Files from "@/views/pages/user/user-files.vue";
import User_Sales from "@/views/pages/user/user-sales.vue";
import User_Purchase from "@/views/pages/user/user-purchase.vue";
import User_Dashboard from "@/views/pages/user/user-dashboard/user-dashboard.vue";
import User_Profile from "@/views/pages/user/user-profile/user-profile.vue";
import User_Wishlist from "@/views/pages/user/user-wishlist/user-wishlist.vue";
import User_Notification from "@/views/pages/user/user-notification.vue";
import Pages_Index from "@/views/pages/pages/pages-index.vue";
import Coming_Soon from "@/views/pages/pages/coming-soon.vue";
import Under_Construction from "@/views/pages/pages/under-construction.vue";
import Pricing_Index from "@/views/pages/pages/pricing-index.vue";
import Portfolio_Index from "@/views/pages/pages/portfolio-index.vue";
import Portfolio_Details from "@/views/pages/pages/portfolio-details.vue";
import Error_404 from "@/views/pages/pages/error/error-404.vue";
import Error_500 from "@/views/pages/pages/error/error-500.vue";

import Change_Password from "@/views/pages/auth/change-password.vue";
import HomeIndex from "@/views/pages/home/home-index.vue";
import Team_Index from "@/views/pages/pages/team/team-index.vue";
import Team_Grid from "@/views/pages/pages/team/team-grid.vue";
import Team_Details from "@/views/pages/pages/team/team-details.vue";
import Team_Carousel from "@/views/pages/pages/team/team-carousel.vue";
import About_Us from "@/views/pages/pages/about-us/about-us.vue";
import Gigs_Index from "@/views/pages/services/services-index.vue";
import Add_Gigs from "@/views/pages/services/add-services.vue";
import Edit_Gigs from "@/views/pages/services/edit-services.vue";
import Service_Sub_Category from "@/views/pages/services/service-sub-category";
import Categories_2 from "@/views/pages/services/categories-2.vue";
import Categories_Index from "@/views/pages/services/categories-index.vue";
import User_Gigs from "@/views/pages/user/user-services.vue";
import GigsService from "@/views/pages/services/service/services-service.vue";
import Service_Details from "@/views/pages/services/service-details/service-details.vue";
import ServiceGridSidebar from "@/views/pages/services/service/service-grid-sidebar.vue";
import Faq_Index from "@/views/pages/faq-index.vue";
import Privacy_Policy from "@/views/pages/privacy-policy.vue";
import Terms_Condition from "@/views/pages/terms-condition.vue";
import User_Message from "@/views/pages/user/chat/user-message.vue";

const routes = [
  {
    path: "/login",
    name: "login-index",
    component: Login,
  },
  {
    path: "/signup",
    name: "sign-up",
    component: Sign_Up,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: Forgot_Password,
  },
  {
    path: "/change-password",
    name: "change-password",
    component: Change_Password,
  },
  {
    path: "/",
    name: "home-index",
    component: HomeIndex,
  },
  {
    path: "/faq",
    name: "faq-index",
    component: Faq_Index,
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: Privacy_Policy,
  },
  {
    path: "/terms-condition",
    name: "terms-condition",
    component: Terms_Condition,
  },
  {
    path: "/about-us",
    component: About_Us,
  },
  {
    path: "/contact",
    component: Contact_Us,
  },
  {
    path: "/user",
    component: User_Index,
    children: [
      { path: "", redirect: "/user/user-dashboard" },
      { path: "user-dashboard", component: User_Dashboard },
      { path: "user-settings", component: User_Settings },
      { path: "security-settings", component: Security_Settings },
      { path: "preference-settings", component: Preference_Settings },
      { path: "billing-settings", component: Billing_Settings },
      { path: "notification-settings", component: Notification_Settings },
      { path: "integration-settings", component: Integration_Settings },
      { path: "user-payments", component: User_Payments },
      { path: "user-wallet", component: User_Wallet },
      { path: "user-reviews", component: User_Reviews },
      { path: "user-files", component: User_Files },
      { path: "user-sales", component: User_Sales },
      { path: "user-purchase", component: User_Purchase },
      { path: "user-wishlist", component: User_Wishlist },
      { path: "user-profile", component: User_Profile },
      { path: "user-services", component: User_Gigs },
      { path: "user-message", component: User_Message },
      { path: "user-notification", component: User_Notification },
    ],
  },
  {
    path: "/pages",
    component: Pages_Index,
    children: [
      { path: "", redirect: "/pages/about-us" },
      { path: "about-us", component: About_Us },
      { path: "coming-soon", component: Coming_Soon },
      { path: "under-construction", component: Under_Construction },
      { path: "pricing", component: Pricing_Index },
      { path: "portfolio", component: Portfolio_Index },
      { path: "portfolio-details", component: Portfolio_Details },
      { path: "error-404", component: Error_404 },
      { path: "error-500", component: Error_500 },
    ],
  },
  {
    path: "/team",
    component: Team_Index,
    children: [
      { path: "", redirect: "/team/team-grid" },
      { path: "team-grid", component: Team_Grid },
      { path: "team-details", component: Team_Details },
      { path: "team-carousel", component: Team_Carousel },
    ],
  },
  {
    path: "/services",
    component: Gigs_Index,
    children: [
      { path: "", redirect: "/services/service" },
      { path: "service", component: GigsService },
      { path: "add-services", component: Add_Gigs },
      { path: "edit-services", component: Edit_Gigs },
      { path: "service-sub-category", component: Service_Sub_Category },
      { path: "service-grid-sidebar", component: ServiceGridSidebar },
      { path: "categories-2", component: Categories_2 },
      { path: "categories", component: Categories_Index },
      { path: "service-details", component: Service_Details },
    ],
  },
];

export const router = createRouter({
  history: createWebHistory("/"),
  linkActiveClass: "active",
  routes,
});

router.beforeEach((to, from, next) => {
  // Scroll to the top of the page
  window.scrollTo({ top: 0, behavior: "smooth" });

  // Continue with the navigation
  next();
});
