<template>
  <div class="review-widget">
    <div class="review-title sort-search-services">
      <div class="row align-items-center">
        <div class="col-sm-6">
          <h3>Reviews (45)</h3>
        </div>
        <div class="col-sm-6">
          <div class="search-filter-selected">
            <div class="form-group mb-0">
              <span class="sort-text">Sort By</span>
              <vue-select
                id="datere"
                :options="Datere"
                placeholder="Recommended"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ul class="review-lists">
      <li>
        <div class="review-wrap">
          <div class="review-user-info">
            <div class="review-img">
              <img src="@/assets/img/user/user-01.jpg" alt="img" />
            </div>
            <div class="reviewer-info">
              <div class="reviewer-loc">
                <h6>
                  <a href="javascript:void(0);">kadajsalamander</a>
                </h6>
                <p><i class="feather-map-pin"></i>London</p>
              </div>
              <div class="reviewer-rating">
                <div class="star-rate">
                  <span class="ratings">
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                  </span>
                  <span class="rating-count">5.0 </span>
                </div>
                <p>1 Months ago</p>
              </div>
            </div>
          </div>
          <div class="review-content">
            <p>
              I recently hired a him to help me with a project and I must say, I
              am extremely impressed with their work. From start to finish, the
              freelancer was professional, efficient, and a pleasure to work
              with.
            </p>
            <a href="javascript:void(0);" class="reply-btn"
              ><i class="feather-corner-up-left"></i>Reply</a
            >
          </div>
        </div>
      </li>
      <li>
        <div class="review-wrap">
          <div class="review-user-info">
            <div class="review-img">
              <img src="@/assets/img/user/user-11.jpg" alt="img" />
            </div>
            <div class="reviewer-info">
              <div class="reviewer-loc">
                <h6><a href="javascript:void(0);">Simon</a></h6>
                <p><i class="feather-map-pin"></i>Newyork</p>
              </div>
              <div class="reviewer-rating">
                <div class="star-rate">
                  <span class="ratings">
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star"></i>
                  </span>
                  <span class="rating-count">4.0 </span>
                </div>
                <p>15 days ago</p>
              </div>
            </div>
          </div>
          <div class="review-content">
            <p>
              One of the things that stood out to me the most was the his
              ability to meet deadlines. He able to deliver the project on time,
              despite a tight deadline. This showed their professionalism and
              dedication to their work.
            </p>
            <a href="javascript:void(0);" class="reply-btn"
              ><i class="feather-corner-up-left"></i>Reply</a
            >
          </div>
        </div>
      </li>
      <li>
        <div class="review-wrap">
          <div class="review-user-info">
            <div class="review-img">
              <img src="@/assets/img/user/user-06.jpg" alt="img" />
            </div>
            <div class="reviewer-info">
              <div class="reviewer-loc">
                <h6><a href="javascript:void(0);">Dane jose</a></h6>
                <p><i class="feather-map-pin"></i>Sweden</p>
              </div>
              <div class="reviewer-rating">
                <div class="star-rate">
                  <span class="ratings">
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                    <i class="fa-solid fa-star filled"></i>
                  </span>
                  <span class="rating-count">5.0 </span>
                </div>
                <p>1 Months ago</p>
              </div>
            </div>
          </div>
          <div class="review-content">
            <p>
              Overall, I highly recommend this freelancer to anyone looking for
              high-quality work and exceptional service. They are a true
              professional and I will definitely be hiring them again for future
              projects. Thank you for your hard work and dedication!
            </p>
            <a href="javascript:void(0);" class="reply-btn"
              ><i class="feather-corner-up-left"></i>Reply</a
            >
          </div>
        </div>
        <ul>
          <li>
            <div class="review-wrap">
              <div class="review-user-info">
                <div class="review-img">
                  <img src="@/assets/img/user/user-02.jpg" alt="img" />
                </div>
                <div class="reviewer-info">
                  <div class="reviewer-loc">
                    <h6><a href="javascript:void(0);">Harry</a></h6>
                    <p><i class="feather-map-pin"></i>France</p>
                  </div>
                  <div class="reviewer-rating">
                    <div class="star-rate">
                      <span class="ratings">
                        <i class="fa-solid fa-star filled"></i>
                        <i class="fa-solid fa-star filled"></i>
                        <i class="fa-solid fa-star filled"></i>
                        <i class="fa-solid fa-star filled"></i>
                        <i class="fa-solid fa-star filled"></i>
                      </span>
                      <span class="rating-count">5.0 </span>
                    </div>
                    <p>1 Months ago</p>
                  </div>
                </div>
              </div>
              <div class="review-content">
                <p>Thank you</p>
                <a href="javascript:void(0);" class="reply-btn"
                  ><i class="feather-corner-up-left"></i>Reply</a
                >
              </div>
            </div>
          </li>
        </ul>
      </li>
    </ul>

    <div class="pagination">
      <ul>
        <li>
          <a href="javascript:void(0);" class="previous"
            ><i class="fa-solid fa-chevron-left"></i
          ></a>
        </li>
        <li>
          <a href="javascript:void(0);" class="active">1</a>
        </li>
        <li>
          <a href="javascript:void(0);">2</a>
        </li>
        <li>
          <a href="javascript:void(0);">3</a>
        </li>
        <li>
          <a href="javascript:void(0);">4</a>
        </li>
        <li>
          <a href="javascript:void(0);">5</a>
        </li>
        <li>
          <a href="javascript:void(0);" class="next"
            ><i class="fa-solid fa-chevron-right"></i
          ></a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      Datere: ["Recommended", "Date"],
    };
  },
};
</script>
