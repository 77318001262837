<template>
  <user-header></user-header>

  <!-- Breadcrumb -->
  <div class="breadcrumb-bar breadcrumb-bar-info">
    <div class="breadcrumb-img">
      <div class="breadcrumb-left">
        <img src="@/assets/img/bg/banner-bg-03.png" alt="img" />
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-12">
          <nav aria-label="breadcrumb" class="page-breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </li>
              <li class="breadcrumb-item" aria-current="page">Services</li>
              <li class="breadcrumb-item" aria-current="page">
                Digital Marketing
              </li>
            </ol>
          </nav>
          <h2 class="breadcrumb-title mb-0">
            Browse Digital Marketing Services
            <span class="text-primary">“ 900 Services ”</span>
          </h2>
        </div>
      </div>
    </div>
  </div>
  <!-- /Breadcrumb -->

  <div class="page-content">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <!-- Category Section -->
          <div class="marketing-section">
            <div class="marketing-content">
              <h2>Digital Marketing</h2>
              <p>
                Digital marketing is an essential component of modern business,
                given the widespread use of the internet and digital devices.
              </p>
              <div class="marketing-bg">
                <img
                  src="@/assets/img/bg/market-bg.png"
                  alt="img"
                  class="market-bg"
                />
                <img
                  src="@/assets/img/bg/market-bg-01.png"
                  alt="img"
                  class="market-img"
                />
              </div>
            </div>
          </div>
          <!-- /Category Section -->

          <!-- Trending Categories -->
          <div class="trend-section trending-box">
            <div class="row align-items-center">
              <div class="col-sm-10">
                <h3>Trending Categories on Digital Marketing</h3>
              </div>
              <div class="col-sm-2 text-sm-end">
                <div class="owl-nav trend-nav nav-control nav-top"></div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <div class="trend-items owl-carousel">
                  <Carousel
                    :wrap-around="true"
                    :settings="settings"
                    :breakpoints="breakpoints"
                  >
                    <Slide v-for="item in TrendingBox" :key="item.id">
                      <div class="trend-box">
                        <div class="trend-info text-start">
                          <h6>
                            <router-link to="/services/service-sub-category">{{
                              item.Title
                            }}</router-link>
                          </h6>
                          <p>{{ item.Service }}</p>
                        </div>
                        <router-link to="/services/service-sub-category"
                          ><i class="feather-arrow-up-right"></i
                        ></router-link>
                      </div>
                    </Slide>
                    <template #addons>
                      <Navigation />
                    </template>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
          <!-- /Trending Categories -->

          <user-services-filter></user-services-filter>
        </div>
      </div>

      <!-- Service -->
      <div class="service-services">
        <div class="row">
          <div class="col-lg-12">
            <services-service-carousel></services-service-carousel>
          </div>
        </div>
      </div>
      <!-- /Service -->
    </div>
  </div>

  <layout-footer></layout-footer>
</template>

<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import TrendingBox from "@/assets/json/trending-box.json";
import "vue3-carousel/dist/carousel.css";

export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      TrendingBox: TrendingBox,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        575: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
};
</script>
