const purchaseCharts = {
  series: [
    {
      name: "Purchase",
      colors: ["#748CAB"],
      data: [
        {
          x: "jan",
          y: 7.0,
        },
        {
          x: "Feb",
          y: 4.0,
        },
        {
          x: "Mar",
          y: 3.0,
        },
        {
          x: "Apr",
          y: 3.7,
        },
        {
          x: "May",
          y: 6.0,
        },
        {
          x: "Jun",
          y: 2.0,
        },
        {
          x: "Jul",
          y: 6.5,
        },
        {
          x: "Aug",
          y: 2.0,
        },
        {
          x: "Sep",
          y: 3.0,
        },
        {
          x: "Oct",
          y: 2.0,
        },
        {
          x: "Nov",
          y: 5.0,
        },
        {
          x: "Dec",
          y: 7.0,
        },
      ],
    },
  ],
  purchCharts: {
    chart: {
      type: "bar",
      height: 250,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "20%",
        borderRadius: 0,
        endingShape: "rounded",
      },
    },
    colors: ["#748CAB"],
  },
};

const amountCharts = {
  series: [
    {
      name: "Sales",
      colors: ["#748CAB"],
      data: [
        {
          x: "jan",
          y: 7.0,
        },
        {
          x: "Feb",
          y: 7.0,
        },
        {
          x: "Mar",
          y: 3.0,
        },
        {
          x: "Apr",
          y: 8.7,
        },
        {
          x: "May",
          y: 7.0,
        },
        {
          x: "Jun",
          y: 2.0,
        },
        {
          x: "Jul",
          y: 7.5,
        },
        {
          x: "Aug",
          y: 2.0,
        },
        {
          x: "Sep",
          y: 3.0,
        },
        {
          x: "Oct",
          y: 2.0,
        },
        {
          x: "Nov",
          y: 5.0,
        },
        {
          x: "Dec",
          y: 7.0,
        },
      ],
    },
  ],
  amoCharts: {
    chart: {
      type: "bar",
      height: 250,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "20%",
        borderRadius: 0,
        endingShape: "rounded",
      },
    },
    colors: ["#748CAB"],
  },
};
export { purchaseCharts, amountCharts };
