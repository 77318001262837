<template>
  <div class="breadcrumb-bar">
    <div class="breadcrumb-img">
      <div class="breadcrumb-left">
        <img src="@/assets/img/bg/banner-bg-03.png" alt="img" />
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-12">
          <nav aria-label="breadcrumb" class="page-breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/">{{ title }}</router-link>
              </li>
              <li class="breadcrumb-item" aria-current="page">{{ text }}</li>
            </ol>
          </nav>
          <h2 class="breadcrumb-title">{{ text1 }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    text1: {
      type: String,
      default: "",
    },
  },
};
</script>
