<template>
  <user-header></user-header>
  <page-header :title="title" :text="text" :text1="text1" />
  <div class="page-content content">
    <div class="container">
      <div class="page-back-btn">
        <router-link to="/user/user-dashboard"
          ><i class="feather-arrow-left me-2"></i>Back to Dashboard</router-link
        >
      </div>
      <settings-sidebar></settings-sidebar>
      <div class="row">
        <div class="col-lg-6">
          <div class="settings-card">
            <div class="settings-card-head">
              <h4>Password</h4>
            </div>
            <div class="settings-card-body">
              <span class="changed-info"
                >Last Changed 20 Jan 2024, 09:00 AM</span
              >
            </div>
            <div class="settings-card-footer">
              <div class="btn-item">
                <a
                  href="#"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#change_password"
                  >Change Password</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="settings-card">
            <div
              class="settings-card-head d-flex justify-content-between align-items-center"
            >
              <h4>Two Factor Authentication</h4>
              <div class="status-toggle d-flex align-items-center">
                <input id="user2" type="checkbox" class="check" checked="" />
                <label for="user2" class="checktoggle"></label>
              </div>
            </div>
            <div class="settings-card-body">
              <span class="changed-info"
                >Last Changed 20 Jan 2024, 09:00 AM</span
              >
            </div>
            <div class="settings-card-footer">
              <div class="btn-item">
                <a href="#" class="btn btn-primary">Disable</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="settings-card">
            <div class="settings-card-head">
              <h4>Device Management</h4>
            </div>
            <div class="settings-card-body">
              <span class="changed-info"
                >Last Changed 20 Jan 2024, 09:00 AM</span
              >
            </div>
            <div class="settings-card-footer">
              <div class="btn-item">
                <a href="#" class="btn btn-primary">Manage</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="settings-card">
            <div class="settings-card-head">
              <h4>Account Acitivity</h4>
            </div>
            <div class="settings-card-body">
              <span class="changed-info"
                >Last Changed 20 Jan 2024, 09:00 AM</span
              >
            </div>
            <div class="settings-card-footer">
              <div class="btn-item">
                <a href="#" class="btn btn-primary">View All</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <layout-footer></layout-footer>
  <security-settings-modal></security-settings-modal>
</template>
<script>
export default {
  data() {
    return {
      title: "Home",
      text: "Settings",
      text1: "Settings",
    };
  },
};
</script>
