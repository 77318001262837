<template>
  <user-header></user-header>
  <page-header :title="title" :text="text" :text1="text1" />
  <section class="faq-section">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="faq-wrapper faq-lists">
            <h2 style="padding-top: 15px; padding-bottom: 10px">
              General Questions
            </h2>
            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqgeneral1"
                  aria-expanded="false"
                  >What is Portfolavo?</a
                >
              </h4>
              <div id="faqgeneral1" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    Portfolavo is a platform that connects audiovisual
                    freelancers with clients in Algeria. Whether you need help
                    with pre-production, production, post-production, or UGC
                    (user-generated content), Portfolavo makes it easy to find
                    the right talent or client.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqgeneral2"
                  aria-expanded="false"
                  >Who can use Portfolavo?</a
                >
              </h4>
              <div id="faqgeneral2" class="card-collapse collapse">
                <div class="faq-content">
                  <p>Portfolavo is designed for two main user groups:</p>
                  <ul>
                    <li>
                      Freelancers specializing in audiovisual fields looking to
                      showcase their expertise and find projects.
                    </li>
                    <li>
                      Clients who need professional audiovisual services for
                      their projects.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqgeneral3"
                  aria-expanded="false"
                  >Is Portfolavo available outside Algeria?</a
                >
              </h4>
              <div id="faqgeneral3" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    While Portfolavo primarily focuses on the Algerian market,
                    users outside Algeria can join and collaborate as long as
                    their projects or skills align with the platform's
                    audiovisual niche.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqgeneral4"
                  aria-expanded="false"
                  >Is Portfolavo free to use?</a
                >
              </h4>
              <div id="faqgeneral4" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    Creating an account on Portfolavo is free. However, the
                    platform charges a small service fee on successful
                    transactions between clients and freelancers to maintain
                    operations and ensure secure payments.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqgeneral5"
                  aria-expanded="false"
                  >How do I create an account?</a
                >
              </h4>
              <div id="faqgeneral5" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    To create an account, simply click the “Sign Up” button on
                    the homepage, choose whether you're a freelancer or a
                    client, and fill in the required details. Once registered,
                    you can start exploring the platform immediately.
                  </p>
                </div>
              </div>
            </div>

            <h2 style="padding-top: 15px; padding-bottom: 10px">For Clients</h2>
            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqclient1"
                  aria-expanded="false"
                  >How do I post a job on Portfolavo?</a
                >
              </h4>
              <div id="faqclient1" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    To post a job, simply sign in as a client, navigate to your
                    dashboard, and click on the “Post a Job” button. Fill in the
                    project details, including the description, budget,
                    timeline, and required skills, and your job will be visible
                    to freelancers.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqclient2"
                  aria-expanded="false"
                  >How do I select the right freelancer for my project?</a
                >
              </h4>
              <div id="faqclient2" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    You can browse through freelancer profiles, review their
                    portfolios, and examine their experience. You can also
                    communicate directly with them through the platform’s
                    messaging system to clarify details before making your
                    decision.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqclient3"
                  aria-expanded="false"
                  >What happens after I select a freelancer?</a
                >
              </h4>
              <div id="faqclient3" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    After you select a freelancer, they will begin working on
                    your project. You can track progress through your project
                    dashboard, share feedback, and update milestones. Once the
                    work is completed, you'll review the deliverables and
                    approve the final payment.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqclient4"
                  aria-expanded="false"
                  >How do I make a payment to a freelancer?</a
                >
              </h4>
              <div id="faqclient4" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    Payments are made through the secure payment gateway
                    integrated within Portfolavo. Once the project is completed
                    and you are satisfied with the deliverables, the payment
                    will be released from escrow to the freelancer.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqclient5"
                  aria-expanded="false"
                  >Can I change or edit a job listing after posting it?</a
                >
              </h4>
              <div id="faqclient5" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    Yes, you can edit or update your job listing as long as no
                    freelancer has been hired yet. You can modify details such
                    as the budget, project description, and deadline.
                  </p>
                </div>
              </div>
            </div>

            <h2 style="padding-top: 15px; padding-bottom: 10px">
              For Freelancers
            </h2>
            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqfreelancer1"
                  aria-expanded="false"
                  >How do I sign up as a freelancer?</a
                >
              </h4>
              <div id="faqfreelancer1" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    To sign up as a freelancer, click on the “Sign Up” button,
                    choose the "Freelancer" option, and fill in the necessary
                    details such as your portfolio, skills, experience, and
                    availability. Once your profile is complete, you can start
                    browsing job listings and submitting proposals.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqfreelancer2"
                  aria-expanded="false"
                  >How do I find and apply for jobs?</a
                >
              </h4>
              <div id="faqfreelancer2" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    You can search for jobs by category, skills, or project
                    details. Once you find a job that matches your skills,
                    submit a customized proposal, detailing your experience and
                    why you’re the best fit for the project.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqfreelancer3"
                  aria-expanded="false"
                  >How do I communicate with clients?</a
                >
              </h4>
              <div id="faqfreelancer3" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    Portfolavo provides a built-in messaging system where you
                    can communicate directly with clients. This allows you to
                    clarify project details, ask questions, and update them on
                    progress throughout the project.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqfreelancer4"
                  aria-expanded="false"
                  >How is payment handled?</a
                >
              </h4>
              <div id="faqfreelancer4" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    Payments are processed securely through Portfolavo’s payment
                    gateway. Once the client approves the deliverables, the
                    payment is released from escrow to you. Portfolavo charges a
                    service fee on each transaction, which is deducted before
                    the payment is sent to you.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqfreelancer5"
                  aria-expanded="false"
                  >Can I specialize in multiple categories?</a
                >
              </h4>
              <div id="faqfreelancer5" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    Yes, as a freelancer, you can showcase your expertise in
                    multiple categories, such as editing, sound design, VFX, and
                    more. You can also create separate portfolios or highlight
                    specific skills depending on the projects you wish to
                    attract.
                  </p>
                </div>
              </div>
            </div>

            <h2 style="padding-top: 15px; padding-bottom: 10px">
              Categories and Services
            </h2>
            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqcategories1"
                  aria-expanded="false"
                  >What types of services are offered on Portfolavo?</a
                >
              </h4>
              <div id="faqcategories1" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    Portfolavo offers a wide range of audiovisual services
                    across different stages of production:
                  </p>
                  <ul>
                    <li>
                      <strong>Pre-Production:</strong> Services such as
                      scriptwriting, storyboard creation, casting, budget
                      management, and more.
                    </li>
                    <li>
                      <strong>Production (On-Site):</strong> Includes roles like
                      actors, directors, cinematographers, sound recorders, and
                      set decorators.
                    </li>
                    <li>
                      <strong>Production (Hybrid):</strong> Virtual set
                      designers, livestream production managers, remote camera
                      operators, and other remote-based services.
                    </li>
                    <li>
                      <strong>Post-Production:</strong> Editors, colorists,
                      sound mixers, VFX specialists, and music composers for
                      finalizing projects.
                    </li>
                    <li>
                      <strong>UGC (User-Generated Content):</strong> Social
                      media content producers, short-form content editors, UGC
                      video strategists, and more.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqcategories2"
                  aria-expanded="false"
                  >Can freelancers specialize in more than one category?</a
                >
              </h4>
              <div id="faqcategories2" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    Yes! Freelancers can showcase expertise in multiple
                    categories on Portfolavo. For example, a freelancer may
                    specialize in both video editing and sound design or in
                    scriptwriting and directing. You can display multiple skills
                    on your profile to attract a broader range of projects.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqcategories3"
                  aria-expanded="false"
                  >Can clients request freelancers from specific categories?</a
                >
              </h4>
              <div id="faqcategories3" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    Yes, clients can post jobs and request freelancers from
                    specific categories. They can choose the required skills and
                    experience when creating a job listing, ensuring that they
                    find the right professionals for their project needs.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqcategories4"
                  aria-expanded="false"
                  >Can I browse freelancers by category?</a
                >
              </h4>
              <div id="faqcategories4" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    Yes, Portfolavo allows clients to browse freelancers by
                    category, skills, and experience. You can use the search and
                    filter functions to find freelancers who specialize in
                    specific areas such as VFX, animation, editing, and more.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqcategories5"
                  aria-expanded="false"
                  >Are there any restrictions on what services can be posted?</a
                >
              </h4>
              <div id="faqcategories5" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    As long as the services are related to audiovisual
                    production or content creation, there are no significant
                    restrictions on what can be posted. All job listings must
                    comply with Portfolavo’s guidelines, including adhering to
                    applicable laws and regulations.
                  </p>
                </div>
              </div>
            </div>

            <h2 style="padding-top: 15px; padding-bottom: 10px">
              Payments and Fees
            </h2>
            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqpayments1"
                  aria-expanded="false"
                  >What payment methods are supported on Portfolavo?</a
                >
              </h4>
              <div id="faqpayments1" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    Portfolavo supports secure payments through popular gateways
                    such as BaridiMob. These methods are integrated to ensure
                    that both clients and freelancers have a seamless
                    transaction experience.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqpayments2"
                  aria-expanded="false"
                  >How does the payment process work?</a
                >
              </h4>
              <div id="faqpayments2" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    Payments are held in an escrow account until both the client
                    and freelancer are satisfied with the project deliverables.
                    Once the client approves the work, the payment is released
                    to the freelancer. This process ensures security and trust
                    for both parties.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqpayments3"
                  aria-expanded="false"
                  >Are there any fees for using Portfolavo?</a
                >
              </h4>
              <div id="faqpayments3" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    Yes, Portfolavo charges a service fee on completed
                    transactions between clients and freelancers. This fee is
                    deducted from the payment before it's transferred to the
                    freelancer. The exact fee percentage is provided during the
                    payment process.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqpayments4"
                  aria-expanded="false"
                  >How are taxes handled on Portfolavo?</a
                >
              </h4>
              <div id="faqpayments4" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    Freelancers are responsible for handling their own taxes,
                    including income tax or VAT. Portfolavo provides a platform
                    for facilitating payments, but users must comply with their
                    local tax regulations and declare earnings as required.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqpayments5"
                  aria-expanded="false"
                  >How long does it take to receive payment?</a
                >
              </h4>
              <div id="faqpayments5" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    Payments are typically processed within 1-2 business days
                    after the client approves the project deliverables. However,
                    the processing time may vary depending on the payment
                    gateway and your account status.
                  </p>
                </div>
              </div>
            </div>

            <h2 style="padding-top: 15px; padding-bottom: 10px">
              Project Management
            </h2>
            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqproject1"
                  aria-expanded="false"
                  >How do I manage a project on Portfolavo?</a
                >
              </h4>
              <div id="faqproject1" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    Once you’ve posted a job and selected a freelancer, you can
                    manage your project through the built-in project dashboard.
                    This includes tracking project progress, sharing files, and
                    providing feedback. You can also update milestones and
                    manage deadlines directly from the dashboard.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqproject2"
                  aria-expanded="false"
                  >Can I track the progress of my project?</a
                >
              </h4>
              <div id="faqproject2" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    Yes, Portfolavo provides a project tracking system where
                    both freelancers and clients can update the status of
                    milestones, track deliverables, and stay on top of
                    deadlines. You will be notified of updates, so you’re always
                    informed about the project’s progress.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqproject3"
                  aria-expanded="false"
                  >How do I communicate with my freelancer or client?</a
                >
              </h4>
              <div id="faqproject3" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    Portfolavo offers an integrated messaging system that allows
                    seamless communication between clients and freelancers. You
                    can send messages, share files, and ask questions directly
                    through your project dashboard.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqproject4"
                  aria-expanded="false"
                  >Can I make changes to the project details once it has
                  started?</a
                >
              </h4>
              <div id="faqproject4" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    Yes, you can make changes to the project details, such as
                    adjusting deadlines, modifying deliverables, or updating the
                    project description. However, these changes should be
                    communicated clearly to ensure both parties are aligned.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqproject5"
                  aria-expanded="false"
                  >How do I manage project milestones?</a
                >
              </h4>
              <div id="faqproject5" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    Milestones can be set up during the project creation
                    process. Both clients and freelancers can update the status
                    of milestones as the work progresses. If the project
                    requires multiple stages or reviews, milestones will help
                    track progress and ensure timely delivery.
                  </p>
                </div>
              </div>
            </div>

            <h2 style="padding-top: 15px; padding-bottom: 10px">
              Ratings and Reviews
            </h2>
            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqreviews1"
                  aria-expanded="false"
                  >How does the ratings system work?</a
                >
              </h4>
              <div id="faqreviews1" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    After completing a project, both clients and freelancers can
                    rate each other. The rating system helps build trust within
                    the community. Clients rate freelancers based on their work
                    quality, communication, and adherence to deadlines.
                    Similarly, freelancers can rate clients based on the project
                    details and their overall experience.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqreviews2"
                  aria-expanded="false"
                  >Can I leave a review after the project is completed?</a
                >
              </h4>
              <div id="faqreviews2" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    Yes, both clients and freelancers can leave reviews after
                    the project is completed. This feedback is essential for
                    maintaining transparency and helping future clients and
                    freelancers make informed decisions.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqreviews3"
                  aria-expanded="false"
                  >What should I include in my review?</a
                >
              </h4>
              <div id="faqreviews3" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    In your review, it’s helpful to include specific details
                    about the work experience, such as the freelancer's quality
                    of work, communication, ability to meet deadlines, and
                    overall professionalism. For freelancers, reviewing the
                    clarity of the project brief, timely feedback, and payment
                    reliability is encouraged.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqreviews4"
                  aria-expanded="false"
                  >Can I dispute or remove a negative review?</a
                >
              </h4>
              <div id="faqreviews4" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    If you believe a review is unfair or violates the platform’s
                    guidelines, you can dispute it with Portfolavo’s support
                    team. We will review the case and take appropriate action.
                    However, we encourage users to leave constructive feedback
                    to help improve the platform and working relationships.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqreviews5"
                  aria-expanded="false"
                  >Are reviews visible to others?</a
                >
              </h4>
              <div id="faqreviews5" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    Yes, reviews are public and can be viewed by other users on
                    freelancer and client profiles. Reviews help others make
                    informed decisions about potential collaborations based on
                    past experiences.
                  </p>
                </div>
              </div>
            </div>

            <h2 style="padding-top: 15px; padding-bottom: 10px">
              Support and Policies
            </h2>
            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqsupport1"
                  aria-expanded="false"
                  >How can I contact Portfolavo support?</a
                >
              </h4>
              <div id="faqsupport1" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    You can contact Portfolavo support through the "Contact Us"
                    section on the website or by sending an email to **[Insert
                    Support Email]**. Our support team is available to help with
                    any inquiries or issues.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqsupport2"
                  aria-expanded="false"
                  >What should I do if I encounter a technical issue?</a
                >
              </h4>
              <div id="faqsupport2" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    If you encounter a technical issue while using Portfolavo,
                    you can reach out to our support team through the platform's
                    "Help" section or by email. Provide details of the issue,
                    including any error messages, and our team will assist you
                    in resolving it.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqsupport3"
                  aria-expanded="false"
                  >How can I delete my Portfolavo account?</a
                >
              </h4>
              <div id="faqsupport3" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    To delete your Portfolavo account, please contact our
                    support team via email. Please note that account deletion is
                    permanent and cannot be undone, so ensure you have completed
                    all necessary transactions before requesting deletion.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqsupport4"
                  aria-expanded="false"
                  >How do I report a problem with a project or user?</a
                >
              </h4>
              <div id="faqsupport4" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    If you experience an issue with a project or a user, such as
                    unprofessional behavior or a dispute, you can report it to
                    Portfolavo’s support team. Provide as much information as
                    possible, including screenshots or other documentation, and
                    our team will investigate the issue and take appropriate
                    action.
                  </p>
                </div>
              </div>
            </div>

            <div class="faq-card aos" data-aos="fade-up">
              <h4 class="faq-title">
                <a
                  class="collapsed"
                  data-bs-toggle="collapse"
                  href="#faqsupport5"
                  aria-expanded="false"
                  >Where can I find Portfolavo’s Terms & Conditions and Privacy
                  Policy?</a
                >
              </h4>
              <div id="faqsupport5" class="card-collapse collapse">
                <div class="faq-content">
                  <p>
                    Portfolavo’s **Terms & Conditions** and **Privacy Policy**
                    can be accessed at the bottom of the website. Please take
                    the time to review these documents to understand how we
                    handle your data and the terms of using our platform.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <layout-footer></layout-footer>
</template>
<script>
export default {
  data() {
    return {
      title: "Home",
      text: "FAQ",
      text1: "Frequently Asked Questions",
    };
  },
};
</script>
