<template>
  <user-header></user-header>
  <page-header :title="title" :text="text" :text1="text1" />
  <div class="page-content">
    <div class="container">
      <div class="page-back-btn">
        <router-link to="/user/user-dashboard"
          ><i class="feather-arrow-left me-2"></i>Back to Dashboard</router-link
        >
      </div>
      <settings-sidebar></settings-sidebar>
      <div class="row">
        <div class="col-md-12">
          <div class="billing-type">
            <div class="row">
              <div class="col-lg-6">
                <div class="settings-card">
                  <div class="settings-card-head">
                    <h5>Current Plan: Basic</h5>
                    <span>Renew On: 01 Feb 2024</span>
                  </div>
                  <div class="settings-card-body">
                    <div class="btn-item">
                      <a href="#" class="btn btn-primary">Manage</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="settings-card">
                  <div
                    class="settings-card-head d-flex justify-content-between align-items-start"
                  >
                    <div class="payment-method-add">
                      <h5>Payment Method</h5>
                      <span>Expires On: 04 Mar 2024</span>
                    </div>
                    <a href="#" class="add-payment payment-btn">Add</a>
                  </div>
                  <div class="settings-card-body">
                    <div class="payment-method-edit">
                      <div class="card-type">
                        <span class="payment-card-img">
                          <img src="@/assets/img/icons/visa.svg" alt="" />
                        </span>
                        <div class="card-no">
                          <span>Visa *****4023</span>
                        </div>
                      </div>
                      <a href="#" class="edit-payment payment-btn">Edit</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="plan-bill-table">
        <div class="dashboard-header">
          <div class="main-title">
            <h3>Invoices</h3>
            <div id="tableinfo"></div>
          </div>
          <div class="head-info">
            <p>Total Payments <span class="text-primary">(17)</span></p>
          </div>
        </div>
        <div class="table-filter">
          <ul class="filter-item">
            <li>
              <p>Filter</p>
            </li>
            <li>
              <div class="form-sort form-wrap">
                <span class="form-icon">
                  <img src="@/assets/img/icons/calendar-icon.svg" alt="icon" />
                </span>
                <date-picker
                  v-model="startdate"
                  class="form-control datetimepicker"
                  :editable="true"
                  :clearable="false"
                  :input-format="dateFormat"
                  placeholder="Select Date"
                />
              </div>
            </li>
            <li>
              <div class="form-sort form-wrap">
                <span class="form-icon">
                  <img src="@/assets/img/icons/calendar-icon.svg" alt="icon" />
                </span>
                <date-picker
                  v-model="startdateOne"
                  class="form-control datetimepicker"
                  :editable="true"
                  :clearable="false"
                  :input-format="dateFormat"
                  placeholder="Select Date"
                />
              </div>
            </li>
            <li>
              <div class="search-filter-selected select-icon">
                <div class="form-group">
                  <span class="sort-text"
                    ><img
                      src="@/assets/img/icons/user-heart.svg"
                      class="img-fluid"
                      alt="img"
                  /></span>
                  <vue-select
                    id="plansel"
                    :options="PlanSel"
                    placeholder="Plan"
                  />
                </div>
              </div>
            </li>
          </ul>
          <div id="tablefilter"></div>
        </div>
        <div class="table-responsive custom-table">
          <table class="table table-stripe datatable">
            <thead class="thead-light">
              <tr>
                <th>ID</th>
                <th>Invoice No</th>
                <th>Billing Date</th>
                <th>Plan</th>
                <th>Status</th>
                <th>Amount</th>
                <th class="text-end">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="record in Billing_Settings" :key="record.id">
                <td>{{ record.ID }}</td>
                <td>
                  <a href="#">{{ record.InvoiceNo }}</a>
                </td>
                <td>{{ record.BillingDate }}</td>
                <td>{{ record.Plan }}</td>
                <td>
                  <span
                    class="badge badge-receive bg-success d-inline-flex align-items-center"
                    ><i class="feather-check me-2"></i>{{ record.Plan }}</span
                  >
                </td>
                <td>{{ record.Amount }}</td>
                <td>
                  <div class="table-action justify-content-end">
                    <a href="javascript:void(0);"
                      ><i class="feather-download"></i
                    ></a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-footer">
          <div id="tablepage"></div>
        </div>
      </div>
    </div>
  </div>
  <layout-footer></layout-footer>
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
import Billing_Settings from "@/assets/json/billing-settings.json";
export default {
  data() {
    return {
      startdate: currentDate,
      startdateOne: currentDateOne,
      dateFormat: "dd-MM-yyyy",
      Billing_Settings: Billing_Settings,
      PlanSel: ["Plan", "Purchase"],
      title: "Home",
      text: "Settings",
      text1: "Settings",
    };
  },
};
</script>
