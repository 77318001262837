<template>
  <div class="row gx-0">
    <div class="col-lg-6">
      <div class="authentication-wrapper">
        <div class="logo-container">
          <router-link to="/">
            <img
              src="@/assets/img/white-logo.svg"
              alt="img"
              class="login-logo"
            />
          </router-link>
        </div>
        <div class="login-bg">
          <img src="@/assets/img/bg/shape-02.png" alt="img" class="shape-02" />
          <img src="@/assets/img/bg/shape-03.png" alt="img" class="shape-03" />
          <img src="@/assets/img/bg/shape-04.png" alt="img" class="shape-04" />
          <img src="@/assets/img/bg/shape-05.png" alt="img" class="shape-05" />
          <img src="@/assets/img/bg/shape-06.png" alt="img" class="shape-06" />
        </div>
      </div>
    </div>

    <!-- Change Password Content -->
    <div class="col-lg-6">
      <div class="login-wrapper">
        <div class="login-content">
          <form @submit.prevent="submitForm">
            <div class="login-userset">
              <div class="login-card">
                <div class="login-heading text-start">
                  <h3>Change Password</h3>
                  <p>
                    Your new password must be different from previous used one
                  </p>
                </div>
                <div class="form-wrap form-focus pass-group">
                  <span class="form-icon">
                    <i class="toggle-password feather-eye-off"></i>
                  </span>
                  <input
                    type="password"
                    class="pass-input form-control floating"
                  />
                  <label class="focus-label">Password</label>
                </div>
                <div class="form-wrap form-focus pass-group">
                  <span class="form-icon">
                    <i class="toggle-password-confirm feather-eye-off"></i>
                  </span>
                  <input
                    type="password"
                    class="pass-confirm form-control floating"
                  />
                  <label class="focus-label">Confirm Password</label>
                </div>
                <div class="form-wrap mantadory-info d-none">
                  <p><i class="feather-alert-triangle"></i>Enter Same Value</p>
                </div>
                <button type="submit" class="btn btn-primary">
                  Update Password
                </button>
              </div>
              <div class="acc-in">
                <p>
                  Remember Password? <router-link to="/">Sign In</router-link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- /Change Password Content -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    submitForm() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 2rem 0;
}

.login-logo {
  width: 400px;
  height: auto;
}
</style>
