<template>
  <div class="col-lg-5 d-flex">
    <div class="card dashboard-card mb-0 flex-fill">
      <div class="card-header">
        <h4 class="card-title">Files</h4>
        <router-link to="/user/user-files" class="view-link"
          >View All<i class="feather-arrow-right"></i
        ></router-link>
      </div>
      <div class="card-body p-0">
        <div class="table-responsive card-table">
          <table class="table">
            <tbody>
              <tr>
                <td>
                  <div class="file-wrap">
                    <span class="file-icon">
                      <img
                        src="@/assets/img/icons/pdf-icon.svg"
                        class="img-fluid"
                        alt="img"
                      />
                    </span>
                    <div class="file-content">
                      <h6>Document.pdf</h6>
                      <p>Update on: 11/01/2024</p>
                    </div>
                  </div>
                </td>
                <td class="text-end">
                  <div class="file-actions">
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="tooltip"
                      data-bs-custom-class="tooltip-dark"
                      data-bs-original-title="I will develop openai, dall, chat gpt app for mobile.."
                      ><i class="feather-alert-circle"></i
                    ></a>
                    <a href="javascript:void(0);"
                      ><i class="feather-download"></i
                    ></a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="file-wrap">
                    <span class="file-icon">
                      <img
                        src="@/assets/img/icons/pdf-icon.svg"
                        class="img-fluid"
                        alt="img"
                      />
                    </span>
                    <div class="file-content">
                      <h6>Notes.pdf</h6>
                      <p>Update on: 11/01/2024</p>
                    </div>
                  </div>
                </td>
                <td class="text-end">
                  <div class="file-actions">
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="tooltip"
                      data-bs-custom-class="tooltip-dark"
                      data-bs-original-title="I will develop openai, dall, chat gpt app for mobile.."
                      ><i class="feather-alert-circle"></i
                    ></a>
                    <a href="javascript:void(0);"
                      ><i class="feather-download"></i
                    ></a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="file-wrap">
                    <span class="file-icon">
                      <img
                        src="@/assets/img/icons/img-icon.svg"
                        class="img-fluid"
                        alt="img"
                      />
                    </span>
                    <div class="file-content">
                      <h6>Worklog.png</h6>
                      <p>Update on: 11/01/2024</p>
                    </div>
                  </div>
                </td>
                <td class="text-end">
                  <div class="file-actions">
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="tooltip"
                      data-bs-custom-class="tooltip-dark"
                      data-bs-original-title="I will develop openai, dall, chat gpt app for mobile.."
                      ><i class="feather-alert-circle"></i
                    ></a>
                    <a href="javascript:void(0);"
                      ><i class="feather-download"></i
                    ></a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="file-wrap">
                    <span class="file-icon">
                      <img
                        src="@/assets/img/icons/img-icon.svg"
                        class="img-fluid"
                        alt="img"
                      />
                    </span>
                    <div class="file-content">
                      <h6>Alter.png</h6>
                      <p>Update on: 11/01/2024</p>
                    </div>
                  </div>
                </td>
                <td class="text-end">
                  <div class="file-actions">
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="tooltip"
                      data-bs-custom-class="tooltip-dark"
                      data-bs-original-title="I will develop openai, dall, chat gpt app for mobile.."
                      ><i class="feather-alert-circle"></i
                    ></a>
                    <a href="javascript:void(0);"
                      ><i class="feather-download"></i
                    ></a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="file-wrap">
                    <span class="file-icon">
                      <img
                        src="@/assets/img/icons/img-icon.svg"
                        class="img-fluid"
                        alt="img"
                      />
                    </span>
                    <div class="file-content">
                      <h6>Reportfile.png</h6>
                      <p>Update on: 11/01/2024</p>
                    </div>
                  </div>
                </td>
                <td class="text-end">
                  <div class="file-actions">
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="tooltip"
                      data-bs-custom-class="tooltip-dark"
                      data-bs-original-title="I will develop openai, dall, chat gpt app for mobile.."
                      ><i class="feather-alert-circle"></i
                    ></a>
                    <a href="javascript:void(0);"
                      ><i class="feather-download"></i
                    ></a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
