<template>
  <div
    id="contact_me"
    class="modal new-modal fade"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Contact Me</h5>
          <button type="button" class="close-btn" data-bs-dismiss="modal">
            <span>×</span>
          </button>
        </div>
        <div class="modal-body service-modal">
          <div class="row">
            <div class="col-md-12">
              <div class="user-details">
                <div class="user-img">
                  <img src="@/assets/img/user/user-05.jpg" alt="img" />
                </div>
                <div class="user-info">
                  <h5>
                    <span class="me-2">Adrian Revolt</span>
                    <span class="badge badge-success"
                      ><i class="fa-solid fa-circle"></i> Online</span
                    >
                  </h5>
                  <h6>An abundance of creativity</h6>
                  <p>
                    <i class="fa-solid fa-star"></i>Ratings 5.0 (45 Reviews)
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="modal-form-group">
                <vue-select
                  id="messSel"
                  :options="MesSel"
                  placeholder="What's the message is about?"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="modal-form-group">
                <input type="text" class="form-control" placeholder="Name" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="modal-form-group">
                <input type="email" class="form-control" placeholder="Email" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="modal-form-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Phone Number"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="modal-form-group">
                <vue-select
                  id="indisel"
                  :options="IndiSel"
                  placeholder="Location"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="modal-form-group">
                <textarea
                  class="form-control"
                  rows="6"
                  placeholder="Enter Your Comments"
                ></textarea>
              </div>
            </div>
            <div class="modal-form-group">
              <label class="custom_check mt-0 mb-0"
                >I agree to the <a href="#">Terms & Conditions</a>
                <input type="checkbox" name="remeber" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div class="modal-btn">
            <a
              href="javascript:void(0);"
              data-bs-dismiss="modal"
              class="btn btn-primary w-100"
            >
              Send Enquiry
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      IndiSel: ["Location", "India", "Uk"],
      MesSel: ["What's the message is about?", "Service"],
    };
  },
};
</script>
