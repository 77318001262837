<template>
  <user-header></user-header>
  <page-header :title="title" :text="text" :text1="text1" />
  <div class="page-content content">
    <div class="container">
      <div class="page-back-btn">
        <router-link to="/user/user-dashboard"
          ><i class="feather-arrow-left me-2"></i>Back to Dashboard</router-link
        >
      </div>
      <settings-sidebar></settings-sidebar>
      <div class="row">
        <div class="col-lg-6">
          <div class="table-card noti-setting-table">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Transactional Notifications</th>
                    <th>Push</th>
                    <th>Email</th>
                    <th>SMS</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>Order Confirmation</td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggle1"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggle1" class="checktoggle"></label>
                      </div>
                    </td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggle2"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggle2" class="checktoggle"></label>
                      </div>
                    </td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggle3"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggle3" class="checktoggle"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Service Requests</td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggle4"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggle4" class="checktoggle"></label>
                      </div>
                    </td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggle5"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggle5" class="checktoggle"></label>
                      </div>
                    </td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input id="toggle6" type="checkbox" class="check" />
                        <label for="toggle6" class="checktoggle"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Payment Receipts</td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggle7"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggle7" class="checktoggle"></label>
                      </div>
                    </td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggle8"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggle8" class="checktoggle"></label>
                      </div>
                    </td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input id="toggle9" type="checkbox" class="check" />
                        <label for="toggle9" class="checktoggle"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Appointment Reminders</td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggle10"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggle10" class="checktoggle"></label>
                      </div>
                    </td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggle11"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggle11" class="checktoggle"></label>
                      </div>
                    </td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggle12"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggle12" class="checktoggle"></label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="btn-item mt-4">
              <a href="#" class="btn btn-secondary">Cancel</a>
              <a href="#" class="btn btn-primary">Save Changes</a>
            </div>
          </div>
          <div class="table-card noti-setting-table">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>User Engagement Notifications</th>
                    <th>Push</th>
                    <th>Email</th>
                    <th>SMS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Profile Completion Reminder</td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggle-1"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggle-1" class="checktoggle"></label>
                      </div>
                    </td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggle-2"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggle-2" class="checktoggle"></label>
                      </div>
                    </td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggle-3"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggle-3" class="checktoggle"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Feedback or Survey Requests</td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggle-4"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggle-4" class="checktoggle"></label>
                      </div>
                    </td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggle-5"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggle-5" class="checktoggle"></label>
                      </div>
                    </td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input id="toggle-6" type="checkbox" class="check" />
                        <label for="toggle-6" class="checktoggle"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Achievements</td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggle-7"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggle-7" class="checktoggle"></label>
                      </div>
                    </td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggle-8"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggle-8" class="checktoggle"></label>
                      </div>
                    </td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input id="toggle-9" type="checkbox" class="check" />
                        <label for="toggle-9" class="checktoggle"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Suggestions</td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggle-10"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggle-10" class="checktoggle"></label>
                      </div>
                    </td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggle-11"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggle-11" class="checktoggle"></label>
                      </div>
                    </td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggle-12"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggle-12" class="checktoggle"></label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="btn-item mt-4">
              <a href="#" class="btn btn-secondary">Cancel</a>
              <a href="#" class="btn btn-primary">Save Changes</a>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="table-card noti-setting-table">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>System Notifications</th>
                    <th>Push</th>
                    <th>Email</th>
                    <th>SMS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>System Maintenance Schedules</td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggles-1"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggles-1" class="checktoggle"></label>
                      </div>
                    </td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggles-2"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggles-2" class="checktoggle"></label>
                      </div>
                    </td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggles-3"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggles-3" class="checktoggle"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Updates</td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggles-4"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggles-4" class="checktoggle"></label>
                      </div>
                    </td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggles-5"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggles-5" class="checktoggle"></label>
                      </div>
                    </td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input id="toggles-6" type="checkbox" class="check" />
                        <label for="toggles-6" class="checktoggle"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Security Alerts</td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggles-7"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggles-7" class="checktoggle"></label>
                      </div>
                    </td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggles-8"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggles-8" class="checktoggle"></label>
                      </div>
                    </td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input id="toggles-9" type="checkbox" class="check" />
                        <label for="toggles-9" class="checktoggle"></label>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Service Availability</td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggles-10"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggles-10" class="checktoggle"></label>
                      </div>
                    </td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggles-11"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggles-11" class="checktoggle"></label>
                      </div>
                    </td>
                    <td>
                      <div class="status-toggle d-flex align-items-center">
                        <input
                          id="toggles-12"
                          type="checkbox"
                          class="check"
                          checked=""
                        />
                        <label for="toggles-12" class="checktoggle"></label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="btn-item mt-4">
              <a href="#" class="btn btn-secondary">Cancel</a>
              <a href="#" class="btn btn-primary">Save Changes</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <layout-footer></layout-footer>
</template>
<script>
export default {
  data() {
    return {
      title: "Home",
      text: "Settings",
      text1: "Settings",
    };
  },
};
</script>
