<template>
  <div
    id="change_password"
    class="modal new-modal fade"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Change Password</h5>
          <button type="button" class="close-btn" data-bs-dismiss="modal">
            <span>×</span>
          </button>
        </div>
        <div class="modal-body service-modal">
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-md-12">
                <div class="form-wrap">
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Current Password"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <input
                    type="password"
                    class="form-control"
                    placeholder="New Password"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-wrap">
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Confirm Password"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="btn-item">
                  <a href="#" class="btn btn-secondary" data-bs-dismiss="modal"
                    >Cancel</a
                  >
                  <button class="btn btn-primary" type="submit">
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    submitForm() {
      this.$router.push("/user/integration-settings");
    },
  },
};
</script>
