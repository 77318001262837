<template>
  <div id="sales" class="tab-pane fade">
    <div class="table-responsive card-table">
      <table class="table">
        <tbody>
          <tr>
            <td>
              <div class="d-flex align-items-center">
                <div class="table-img">
                  <a href="javascript:void(0);"
                    ><img
                      src="@/assets/img/services/services-01.jpg"
                      class="img-fluid"
                      alt="img"
                  /></a>
                </div>
                <div class="recent-payment">
                  <h6>
                    <a href="javascript:void(0);"
                      >I will do integrating AR elements into...</a
                    >
                  </h6>
                  <ul>
                    <li>Date: 07/01/2024</li>
                    <li>
                      Buyer :
                      <span class="text-dark">Robert</span>
                    </li>
                  </ul>
                </div>
              </div>
            </td>
            <td class="text-end">
              <span class="badge badge-receive bg-success">Completed</span>
            </td>
            <td class="text-end">
              <h6 class="mb-0">$1400</h6>
            </td>
          </tr>
          <tr>
            <td>
              <div class="d-flex align-items-center">
                <div class="table-img">
                  <a href="javascript:void(0);"
                    ><img
                      src="@/assets/img/services/services-08.jpg"
                      class="img-fluid"
                      alt="img"
                  /></a>
                </div>
                <div class="recent-payment">
                  <h6>
                    <a href="javascript:void(0);"
                      >I will do collaborating with influencers...</a
                    >
                  </h6>
                  <ul>
                    <li>Date: 10/01/2024</li>
                    <li>
                      Buyer :
                      <span class="text-dark">James</span>
                    </li>
                  </ul>
                </div>
              </div>
            </td>
            <td class="text-end">
              <span class="badge badge-receive bg-info">New</span>
            </td>
            <td class="text-end">
              <h6 class="mb-0">$1400</h6>
            </td>
          </tr>
          <tr>
            <td>
              <div class="d-flex align-items-center">
                <div class="table-img">
                  <a href="javascript:void(0);"
                    ><img
                      src="@/assets/img/services/services-09.jpg"
                      class="img-fluid"
                      alt="img"
                  /></a>
                </div>
                <div class="recent-payment">
                  <h6>
                    <a href="javascript:void(0);"
                      >Managing and optimizing paid advertising...</a
                    >
                  </h6>
                  <ul>
                    <li>Date: 11/01/2024</li>
                    <li>
                      Buyer :
                      <span class="text-dark">Jones</span>
                    </li>
                  </ul>
                </div>
              </div>
            </td>
            <td class="text-end">
              <span class="badge badge-receive bg-info">New</span>
            </td>
            <td class="text-end">
              <h6 class="mb-0">$1000</h6>
            </td>
          </tr>
          <tr>
            <td>
              <div class="d-flex align-items-center">
                <div class="table-img">
                  <a href="javascript:void(0);"
                    ><img
                      src="@/assets/img/services/services-09.jpg"
                      class="img-fluid"
                      alt="img"
                  /></a>
                </div>
                <div class="recent-payment">
                  <h6>
                    <a href="javascript:void(0);"
                      >I will do designing and executing targeted...</a
                    >
                  </h6>
                  <ul>
                    <li>Date: 09/01/2024</li>
                    <li>
                      Buyer :
                      <span class="text-dark">Linda</span>
                    </li>
                  </ul>
                </div>
              </div>
            </td>
            <td class="text-end">
              <span class="badge badge-receive bg-info">New</span>
            </td>
            <td class="text-end">
              <h6 class="mb-0">$1400</h6>
            </td>
          </tr>
          <tr>
            <td>
              <div class="d-flex align-items-center">
                <div class="table-img">
                  <a href="javascript:void(0);"
                    ><img
                      src="@/assets/img/services/services-04.jpg"
                      class="img-fluid"
                      alt="img"
                  /></a>
                </div>
                <div class="recent-payment">
                  <h6>
                    <a href="javascript:void(0);"
                      >Embedded Android & AOSP customizations...</a
                    >
                  </h6>
                  <ul>
                    <li>Date: 08/01/2024</li>
                    <li>
                      Buyer :
                      <span class="text-dark">Jeffrey</span>
                    </li>
                  </ul>
                </div>
              </div>
            </td>
            <td class="text-end">
              <span class="badge badge-receive bg-info">New</span>
            </td>
            <td class="text-end">
              <h6 class="mb-0">$1400</h6>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
