<template>
  <user-header></user-header>
  <page-header :title="title" :text="text" :text1="text1" />

  <!-- Page Content -->
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!-- Sidebar -->
        <div class="col-lg-4 col-xl-3 theiaStickySidebar">
          <div class="stickysidebar">
            <user-sidebar></user-sidebar>
          </div>
        </div>
        <!-- /Sidebar -->

        <!-- Manage Services -->
        <div class="col-xl-9 col-lg-8">
          <div class="dashboard-header">
            <div class="main-title">
              <h3>Manage Services</h3>
            </div>
            <div class="head-info">
              <router-link to="/services/add-services" class="btn btn-primary"
                >Add New Service</router-link
              >
            </div>
          </div>

          <div class="row">
            <!-- Service List -->
            <div
              v-for="item in UserGigs"
              :key="item.id"
              class="user-services col-xl-4 col-md-6"
            >
              <div class="services-grid">
                <div class="services-img">
                  <div class="img-slider owl-carousel">
                    <Carousel
                      :wrap-around="true"
                      :settings="settings"
                      :breakpoints="breakpoints"
                    >
                      <Slide
                        v-for="slideItem in item.Slider"
                        :key="slideItem.id"
                      >
                        <!-- Display only the first image from the Slider array -->
                        <div class="slide-images">
                          <router-link to="/services/service-details">
                            <img
                              :src="
                                require(
                                  `@/assets/img/services/${slideItem.Img}`,
                                )
                              "
                              class="img-fluid"
                              alt="img"
                            />
                          </router-link>
                        </div>
                      </Slide>
                      <template #addons>
                        <Pagination />
                      </template>
                    </Carousel>
                  </div>
                  <div class="fav-selection">
                    <router-link to="/services/edit-services"
                      ><i class="feather-edit"></i
                    ></router-link>
                    <a href="javascript:void(0);"
                      ><i class="feather-trash-2"></i
                    ></a>
                  </div>
                </div>
                <div class="services-content">
                  <div class="services-info">
                    <a
                      href="javascript:void(0);"
                      class="badge bg-primary-light"
                      >{{ item.Roll }}</a
                    >
                    <div class="star-rate">
                      <span
                        ><i class="fa-solid fa-star"></i>{{ item.Rating }}</span
                      >
                    </div>
                  </div>
                  <div class="services-title">
                    <h3>
                      <router-link to="/services/service-details">{{
                        item.Title
                      }}</router-link>
                    </h3>
                  </div>
                  <ul class="services-user-info">
                    <li class="services-user">
                      <img
                        :src="require(`@/assets/img/user/${item.Avatar}`)"
                        alt="img"
                      />
                      <p>{{ item.Name }}</p>
                    </li>
                    <li class="services-loc">
                      <p><i class="feather-map-pin"></i>{{ item.Location }}</p>
                    </li>
                  </ul>
                  <div class="services-card-footer">
                    <h5>{{ item.Price }}</h5>
                    <span class="badge">{{ item.Delivery }}</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Service List -->

            <div class="col-md-12">
              <!-- Pagination -->
              <div class="pagination">
                <ul>
                  <li>
                    <a href="javascript:void(0);" class="previous"
                      ><i class="fa-solid fa-chevron-left"></i
                    ></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" class="active">1</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">2</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">3</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">4</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">5</a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" class="next"
                      ><i class="fa-solid fa-chevron-right"></i
                    ></a>
                  </li>
                </ul>
              </div>
              <!-- /Pagination -->
            </div>
          </div>
        </div>
        <!-- /Manage Services -->
      </div>
    </div>
  </div>
  <!-- /Page Content -->

  <layout-footer></layout-footer>
</template>

<script>
import { Carousel, Pagination, Slide } from "vue3-carousel";
import UserGigs from "@/assets/json/user-services.json";
import "vue3-carousel/dist/carousel.css";

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      title: "Home",
      text: "Manage Services",
      text1: "Manage Services",
      UserGigs: UserGigs,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 1,
          snapAlign: "start",
        },
      },
    };
  },
};
</script>
