<template>
  <user-header></user-header>
  <page-header :title="title" :text="text" :text1="text1" />
  <div class="page-content content">
    <div class="container">
      <div class="row">
        <!-- Service Details -->
        <profile-service></profile-service>
        <!-- /Service Details -->

        <!-- Member Details -->
        <div class="col-lg-4 theiaStickySidebar">
          <div class="stickysidebar">
            <div class="service-widget member-widget">
              <ul class="member-info">
                <li>
                  Last Project Delivery
                  <span>29 Jan 2024</span>
                </li>
                <li>
                  Avg. response time
                  <span>about 8 hours</span>
                </li>
              </ul>
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#contact_me"
                class="btn btn-primary mb-0 w-100"
                >Contact Me</a
              >
            </div>
          </div>
        </div>
        <!-- /Member Details -->
      </div>

      <!-- Recent Work -->
      <profile-work></profile-work>
      <!-- /Recent Work -->
    </div>
  </div>
  <layout-footer></layout-footer>
  <user-profile-modal></user-profile-modal>
</template>
<script>
export default {
  data() {
    return {
      title: "Home",
      text: "My Profile",
      text1: "My Profile",
    };
  },
};
</script>
