<template>
  <user-header></user-header>
  <div class="breadcrumb-bar breadcrumb-bar-info">
    <div class="breadcrumb-img">
      <div class="breadcrumb-left">
        <img src="@/assets/img/bg/banner-bg-03.png" alt="img" />
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-12">
          <nav aria-label="breadcrumb" class="page-breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link to="/services/service">Services</router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link to="/services/service"
                  >Digital Marketing</router-link
                >
              </li>
              <li class="breadcrumb-item" aria-current="page">Ecommerce-Seo</li>
            </ol>
          </nav>
          <h2 class="breadcrumb-title mb-0">
            Browse Ecommerce-Seo Services
            <span class="text-primary">“ 500 Services ”</span>
          </h2>
        </div>
      </div>
    </div>
  </div>
  <div class="page-content">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <!-- Category Section -->
          <div class="marketing-section">
            <div class="marketing-content">
              <h2>Ecommerce-Seo</h2>
              <p>
                Digital marketing is an essential component of modern business,
                given the widespread use of the internet and digital devices.
              </p>
              <div class="marketing-bg">
                <img
                  src="@/assets/img/bg/market-bg.png"
                  alt="img"
                  class="market-bg"
                />
                <img
                  src="@/assets/img/bg/market-bg-01.png"
                  alt="img"
                  class="market-img"
                />
              </div>
            </div>
          </div>
          <!-- /Category Section -->

          <!-- Filter -->
          <user-services-filter></user-services-filter>
          <!-- /Filter -->
        </div>
      </div>

      <!-- Service -->
      <div class="service-services">
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <!-- Service List -->
              <div
                v-for="item in ServiceCarousel"
                :key="item.id"
                class="col-lg-4 col-md-6 services-service-carousel"
              >
                <div class="services-grid">
                  <div class="services-img">
                    <div class="img-slider owl-carousel">
                      <Carousel
                        :wrap-around="true"
                        :settings-one="settingsOne"
                        :breakpoints-one="breakpointsOne"
                      >
                        <Slide
                          v-for="slideItem in item.Slider"
                          :key="slideItem.id"
                        >
                          <div class="slide-images">
                            <router-link to="/services/service-details">
                              <img
                                :src="
                                  require(
                                    `@/assets/img/services/${slideItem.Img}`,
                                  )
                                "
                                class="img-fluid"
                                alt="img"
                              />
                            </router-link>
                          </div>
                        </Slide>
                        <template #addons>
                          <Pagination />
                        </template>
                      </Carousel>
                    </div>
                    <div class="card-overlay-badge">
                      <router-link to="/services/service"
                        ><span :class="item.BadgeClass"
                          ><i :class="item.BadgeIcon"></i>{{ item.Badge }}</span
                        ></router-link
                      >
                      <router-link to="/services/service"
                        ><span :class="item.BadgeClass1"
                          ><i :class="item.BadgeIcon1"></i
                          >{{ item.Badge1 }}</span
                        ></router-link
                      >
                    </div>
                    <div class="fav-selection">
                      <a href="javascript:void(0);" class="video-icon"
                        ><i class="feather-video"></i
                      ></a>
                      <a href="javascript:void(0);" class="fav-icon"
                        ><i class="feather-heart"></i
                      ></a>
                    </div>
                    <div class="user-thumb">
                      <router-link to="/user/user-profile"
                        ><img
                          :src="require(`@/assets/img/user/${item.Avatar}`)"
                          alt="img"
                      /></router-link>
                    </div>
                  </div>
                  <div class="services-content">
                    <div class="services-info">
                      <router-link
                        to="/services/service-details"
                        class="badge bg-primary-light"
                        >{{ item.Roll }}</router-link
                      >
                      <small>{{ item.RollSmall }}</small>
                      <p><i class="feather-map-pin"></i>{{ item.Location }}</p>
                    </div>
                    <div class="services-title">
                      <h3>
                        <router-link to="/services/service-details">{{
                          item.Title
                        }}</router-link>
                      </h3>
                    </div>
                    <div class="star-rate">
                      <span
                        ><i class="fa-solid fa-star"></i>{{ item.Review }}</span
                      >
                    </div>
                    <div class="services-card-footer">
                      <div>
                        <a href="javascript:void(0);" class="share-icon"
                          ><i class="feather-share-2"></i
                        ></a>
                        <span class="badge">{{ item.Delivery }}</span>
                      </div>
                      <h5>{{ item.Price }}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Service List -->

              <div class="col-md-12">
                <!-- Pagination -->
                <div class="pagination">
                  <ul>
                    <li>
                      <a href="javascript:void(0);" class="previous"
                        ><i class="fa-solid fa-chevron-left"></i
                      ></a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" class="active">1</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">2</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">3</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">4</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">5</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);" class="next"
                        ><i class="fa-solid fa-chevron-right"></i
                      ></a>
                    </li>
                  </ul>
                </div>
                <!-- /Pagination -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Service -->
    </div>
  </div>
  <layout-footer></layout-footer>
</template>
<script>
import { Carousel, Pagination, Slide } from "vue3-carousel";
import ServiceCarousel from "@/assets/json/services-service-carousel.json";
import "vue3-carousel/dist/carousel.css";

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      ServiceCarousel: ServiceCarousel,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        575: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
      settingsOne: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpointsOne: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 1,
          snapAlign: "start",
        },
      },
    };
  },
};
</script>
