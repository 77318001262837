<template>
  <div class="services-card-slider owl-carousel">
    <Carousel
      :wrap-around="true"
      :settings="settings"
      :breakpoints="breakpoints"
    >
      <Slide v-for="item in ExplorePopular" :key="item.id">
        <div class="services-grid">
          <div class="services-img">
            <div class="img-slider owl-carousel">
              <Carousel
                :wrap-around="true"
                :settings-one="settingsOne"
                :breakpoints-one="breakpointsOne"
              >
                <Slide v-for="slideItem in item.Slider" :key="slideItem.id">
                  <!-- Display only the first image from the Slider array -->
                  <div class="slide-images">
                    <router-link to="/services/service-details">
                      <img
                        :src="
                          require(`@/assets/img/services/${slideItem.Image}`)
                        "
                        class="img-fluid"
                        alt="Services"
                      />
                    </router-link>
                  </div>
                </Slide>
                <template #addons>
                  <Pagination />
                </template>
              </Carousel>
            </div>

            <div class="card-overlay-badge">
              <router-link to="/services/service">
                <span :class="item.BadgeClass">
                  <i :class="item.BadgeIcon"></i>{{ item.Badge }}
                </span>
              </router-link>
              <router-link to="/services/service">
                <span :class="item.BadgeClass1">
                  <i :class="item.BadgeIcon1"></i>{{ item.Badge1 }}
                </span>
              </router-link>
            </div>
            <div class="fav-selection">
              <a href="javascript:void(0);" class="fav-icon">
                <i class="feather-heart"></i>
              </a>
            </div>
            <div class="user-thumb">
              <router-link to="/user/user-profile">
                <img
                  :src="require(`@/assets/img/user/${item.Avatar}`)"
                  alt="User"
                />
              </router-link>
            </div>
          </div>
          <div class="services-content text-start">
            <div class="services-info">
              <router-link
                to="/services/service-details"
                class="badge bg-primary-light"
              >
                {{ item.Roll }}
              </router-link>
              <small>{{ item.RollSmall }}</small>
              <p>
                <img src="@/assets/img/icons/map-pin-check.svg" alt="Icon" />{{
                  item.Location
                }}
              </p>
            </div>
            <div class="services-title">
              <h3>
                <router-link to="/services/service-details">
                  {{ item.Content }}
                </router-link>
              </h3>
            </div>
            <div class="star-rate">
              <span><i class="fa-solid fa-star"></i>{{ item.Review }}</span>
            </div>
            <div class="services-card-footer">
              <div class="services-share">
                <a href="javascript:void(0);">
                  <i class="feather-share-2"></i>
                </a>
                <span class="badge">{{ item.Delivery }}</span>
              </div>
              <h5>{{ item.Price }}</h5>
            </div>
          </div>
        </div>
      </Slide>
      <template #addons>
        <Navigation />
      </template>
    </Carousel>
  </div>
</template>

<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import ExplorePopular from "@/data/explore-popular.json";
import "vue3-carousel/dist/carousel.css";

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      ExplorePopular: ExplorePopular,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },

      breakpoints: {
        575: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
      settingsOne: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpointsOne: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 1,
          snapAlign: "start",
        },
      },
    };
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
