<template>
  <!-- Expert Section -->
  <section class="popular-section expert-section">
    <div class="popular-img">
      <div class="popular-img-left">
        <img src="@/assets/img/bg/banner-bg-04.png" alt="Shape" />
      </div>
      <div class="popular-img-right">
        <img src="@/assets/img/bg/shape-08.png" alt="Shape" />
      </div>
    </div>
    <div class="container">
      <div class="expert-header">
        <div class="section-header text-center aos" data-aos="fade-up">
          <h2><span>What</span> Makes Portfolavo Different</h2>
        </div>
      </div>
      <div class="expert-wrapper">
        <div class="row gx-0 justify-content-center">
          <div class="col-lg-4 col-md-6 aos" data-aos="fade-up">
            <div class="expert-item">
              <div class="expert-icon">
                <img src="@/assets/img/icons/flag-icon.svg" alt="img" />
              </div>
              <div class="expert-info">
                <h4>Trusted Talent</h4>
                <p>
                  Portfolavo ensures that you work with top-tier talent,
                  experienced audiovisual professionals.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 aos" data-aos="fade-up">
            <div class="expert-item">
              <div class="expert-icon">
                <img src="@/assets/img/icons/expert-icon.svg" alt="img" />
              </div>
              <div class="expert-info">
                <h4>Transparent Pricing</h4>
                <p>
                  Portfolavo promotes transparency, allowing you to negotiate
                  and agree on fair rates before starting any project.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 aos" data-aos="fade-up">
            <div class="expert-item">
              <div class="expert-icon">
                <img src="@/assets/img/icons/users-icon.svg" alt="img" />
              </div>
              <div class="expert-info">
                <h4>Tailored Project Management</h4>
                <p>
                  Portfolavo’s project dashboard helps you stay organized and
                  ensures smooth communication throughout the process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Expert Section -->
</template>

<script>
import Testimonials from "@/assets/json/home-testimonials.json";
import "vue3-carousel/dist/carousel.css";

export default {
  data() {
    return {
      Testimonials: Testimonials,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        575: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
};
</script>
