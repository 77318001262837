<template>
  <header class="header" :class="{ fixed: isHeaderFixed }">
    <div class="container-fluid">
      <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header">
          <a id="mobile_btn" href="javascript:void(0);" @click="toggleSidebar">
            <span class="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
          <router-link to="/" class="navbar-brand logo">
            <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
          </router-link>
          <router-link to="/" class="navbar-brand logo-small">
            <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
          </router-link>
        </div>
        <div class="main-menu-wrapper">
          <div class="menu-header">
            <router-link to="/" class="menu-logo">
              <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
            </router-link>
            <a
              id="menu_close"
              class="menu-close"
              href="#"
              @click="closeSidebar"
            >
              <i class="fas fa-times"></i
            ></a>
          </div>
          <header-menu></header-menu>
        </div>
        <ul v-if="!user" class="nav header-navbar-rht">
          <li><router-link to="/login" class="log-btn"> Login</router-link></li>
          <li>
            <router-link to="/signup" class="lbtn btn-primary">
              Sig Up
            </router-link>
          </li>
        </ul>
        <ul v-if="user" class="nav header-navbar-rht">
          <!-- User Menu -->
          <li
            class="nav-item dropdowns has-arrow logged-item"
            :class="{ 'is-active': isActive }"
          >
            <a href="#" class="nav-link toggle" @click="toggleActive">
              <span class="log-user dropdown-toggle">
                <span class="users-img me-1">
                  <img
                    class="rounded-circle"
                    :src="
                      'https://gravatar.com/avatar/' +
                      emailHash +
                      '?d=identicon'
                    "
                    alt="Profile"
                  />
                </span>
                <span class="user-text">{{
                  user.firstName + " " + user.lastName
                }}</span>
              </span>
            </a>
            <div class="dropdown-menu list-group">
              <div class="user-item">
                <img
                  :src="
                    'https://gravatar.com/avatar/' + emailHash + '?d=identicon'
                  "
                  alt="Profile"
                />
                <div class="user-name">
                  <h6>{{ user.firstName + " " + user.lastName }}</h6>
                  <p>Joined On : 14 Jan 2025</p>
                </div>
              </div>
              <router-link
                class="dropdown-item drop-line"
                to="/user/user-dashboard"
              >
                <img
                  src="@/assets/img/icons/dashboard-icon-01.svg"
                  class="img-fluid"
                  alt="img"
                />Dashboard
              </router-link>
              <router-link class="dropdown-item" to="/user/user-wallet">
                <img
                  src="@/assets/img/icons/dashboard-icon-09.svg"
                  class="img-fluid"
                  alt="img"
                />My Wallet
              </router-link>
              <hr />
              <router-link class="dropdown-item" to="/user/user-settings">
                <img
                  src="@/assets/img/icons/settings-cog.svg"
                  class="img-fluid"
                  alt="img"
                />Settings
              </router-link>
              <router-link class="dropdown-item" to="/user/user-profile">
                <img
                  src="@/assets/img/icons/user-cog.svg"
                  class="img-fluid"
                  alt="img"
                />My Profile
              </router-link>
              <hr />
              <a class="dropdown-item log-out" @click="logout">
                <img
                  src="@/assets/img/icons/logout.svg"
                  class="img-fluid"
                  alt="img"
                />Logout
              </a>
            </div>
          </li>
          <!-- /User Menu -->
        </ul>
      </nav>
    </div>
  </header>

  <!-- <services-scroll></services-scroll> -->
</template>
<script>
export default {
  data() {
    return {
      isActive: false,
      SellSel: ["Seller", "Buyer"],
      isHeaderFixed: false,
      user: null,
      emailHash: "",
    };
  },
  beforeMount() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.computeHash();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    logout() {
      localStorage.removeItem("user");
      this.$nextTick(() => {
        this.user = null;
        this.$router.push("/");
      });
    },
    async hash() {
      if (!this.user) return;
      const utf8 = new TextEncoder().encode(
        this.user.email.trim().toLowerCase(),
      );
      const hashBuffer = await crypto.subtle.digest("SHA-256", utf8);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map((bytes) => bytes.toString(16).padStart(2, "0"))
        .join("");

      console.log(hashHex);

      return hashHex;
    },
    async computeHash() {
      this.emailHash = await this.hash(); // Set the computed hash to the data property
    },
    toggleActive() {
      this.isActive = !this.isActive;
    },
    handleScroll() {
      // You can adjust the threshold as per your requirement
      const threshold = 100;
      if (window.scrollY > threshold) {
        this.isHeaderFixed = true;
      } else {
        this.isHeaderFixed = false;
      }
    },
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
      document.documentElement.classList.toggle("menu-opened");
    },
    closeSidebar() {
      this.isSidebarOpen = false;
      document.documentElement.classList.remove("menu-opened");
    },
  },
};
</script>
