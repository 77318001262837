<template>
  <user-header></user-header>
  <page-header :title="title" :text="text" :text1="text1" />
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!-- Sidebar -->
        <div class="col-lg-4 col-xl-3 theiaStickySidebar">
          <user-sidebar></user-sidebar>
        </div>
        <!-- /Sidebar -->

        <!--User Payments -->
        <div class="col-xl-9 col-lg-8">
          <div class="dashboard-header">
            <div class="main-title">
              <h3>Payments</h3>
              <div id="tableinfo"></div>
            </div>
            <div class="head-info">
              <p>Total Payments <span class="text-primary">(17)</span></p>
            </div>
          </div>
          <div class="table-filter">
            <ul class="filter-item">
              <li>
                <p>Filter</p>
              </li>
              <li>
                <div class="form-sort form-wrap">
                  <span class="form-icon">
                    <img
                      src="@/assets/img/icons/calendar-icon.svg"
                      alt="icon"
                    />
                  </span>
                  <date-picker
                    v-model="startdate"
                    class="form-control datetimepicker"
                    :editable="true"
                    :clearable="false"
                    :input-format="dateFormat"
                    placeholder="Select Date"
                  />
                </div>
              </li>
              <li>
                <div class="form-sort form-wrap">
                  <span class="form-icon">
                    <img
                      src="@/assets/img/icons/calendar-icon.svg"
                      alt="icon"
                    />
                  </span>
                  <date-picker
                    v-model="startdateOne"
                    class="form-control datetimepicker"
                    :editable="true"
                    :clearable="false"
                    :input-format="dateFormat"
                    placeholder="Select Date"
                  />
                </div>
              </li>
              <li>
                <div class="form-sort form-wrap">
                  <span class="form-icon">
                    <img src="@/assets/img/icons/user-heart.svg" alt="icon" />
                  </span>
                  <vue-select
                    id="buyersel"
                    :options="BuyerSel"
                    placeholder="Buyer"
                  />
                </div>
              </li>
            </ul>
            <div id="tablefilter"></div>
          </div>
          <div class="table-responsive custom-table">
            <table class="table table-stripe datatable">
              <thead class="thead-light">
                <tr>
                  <th>ID</th>
                  <th>Amount</th>
                  <th>Buyer</th>
                  <th>Date & time</th>
                  <th>Type</th>
                  <th>Amount</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="record in User_Payment" :key="record.id">
                  <td>{{ record.ID }}</td>
                  <td>
                    <h2 class="table-avatar d-flex align-items-center">
                      <a href="javascript:void(0);" class="avatar">
                        <img
                          :src="
                            require(`@/assets/img/services/${record.GigsImage}`)
                          "
                          class="rounded"
                          alt="User Image"
                        />
                      </a>
                      <a href="javascript:void(0);" class="text-dark">{{
                        record.Content
                      }}</a>
                    </h2>
                  </td>
                  <td>
                    <h2 class="table-avatar d-flex align-items-center">
                      <router-link to="/user/user-profile" class="avatar">
                        <img
                          :src="
                            require(`@/assets/img/user/${record.UserImage}`)
                          "
                          alt="User Image"
                        />
                      </router-link>
                      <router-link to="/user/user-profile">{{
                        record.Buyer
                      }}</router-link>
                    </h2>
                  </td>
                  <td>{{ record.Date }}</td>
                  <td>
                    <span class="badge bg-success new-badge"
                      ><i :class="record.Class"></i> {{ record.Type }}</span
                    >
                  </td>
                  <td>{{ record.Amount }}</td>
                  <td>
                    <div class="table-action">
                      <a href="javascript:void(0);"
                        ><i class="feather-eye"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="table-footer">
            <div id="tablepage"></div>
          </div>
        </div>
        <!-- /User Payments -->
      </div>
    </div>
  </div>
  <layout-footer></layout-footer>
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
import User_Payment from "@/assets/json/user-payment.json";
export default {
  data() {
    return {
      startdate: currentDate,
      startdateOne: currentDateOne,
      dateFormat: "dd-MM-yyyy",
      User_Payment: User_Payment,
      title: "Home",
      text: "Payments",
      text1: "Payments",
      BuyerSel: ["Buyer", "Jones", "Adrian", "Andrea", "Martin"],
    };
  },
};
</script>
