<template>
  <footer class="footer">
    <div class="section-bg">
      <img
        src="@/assets/img/bg/footer-bg-01.png"
        class="footer-bg-one"
        alt="img"
      />
      <img
        src="@/assets/img/bg/footer-bg-02.png"
        class="footer-bg-two"
        alt="img"
      />
    </div>
    <div class="container">
      <div class="footer-top">
        <div class="row">
          <div
            class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <div class="footer-widget">
              <router-link to="/">
                <img src="@/assets/img/white-logo.svg" alt="logo" />
              </router-link>
              <p>
                We are set to revolutionize the audiovisual industry by
                connecting talented freelancers with visionary clients.
                Empowering creatives to showcase their skills and clients to
                bring their projects to life, fostering innovation in a thriving
                digital platform.
              </p>
              <div class="social-links">
                <ul>
                  <li>
                    <a href="javascript:void(0);"
                      ><i class="fa-brands fa-facebook"></i
                    ></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"
                      ><i class="fa-brands fa-x-twitter"></i
                    ></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"
                      ><i class="fa-brands fa-instagram"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            class="col-xl-2 col-lg-2 col-md-6 col-sm-6"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            <div class="footer-widget">
              <h3>Legal</h3>
              <ul class="menu-items">
                <li>
                  <router-link to="/pages/about-us">About Us</router-link>
                </li>
                <li>
                  <router-link to="/terms-condition"
                    >Terms & Conditions</router-link
                  >
                </li>

                <li>
                  <router-link to="/privacy-policy"
                    >Privacy Policy
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="col-xl-2 col-lg-2 col-md-6 col-sm-6"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            <div class="footer-widget">
              <h3>Platform</h3>
              <ul class="menu-items">
                <li>
                  <router-link to="/services/categories-2"
                    >Categories</router-link
                  >
                </li>

                <li><router-link to="/pages/pricing">Pricing</router-link></li>
                <li><router-link to="/faq">FAQ</router-link></li>
              </ul>
            </div>
          </div>
          <!-- <div
            class="col-xl-2 col-lg-2 col-md-6 col-sm-6"
            data-aos="fade-up"
            data-aos-delay="800"
          >
            <div class="footer-widget">
              <h3>Dashboard</h3>
              <ul class="menu-items">
                <li>
                  <router-link to="/user/user-purchase">Purchase</router-link>
                </li>
                <li><router-link to="/user/user-sales">Sales</router-link></li>
                <li>
                  <router-link to="/user/user-payments">Payments</router-link>
                </li>
                <li><router-link to="/user/user-files">Files</router-link></li>
                <li>
                  <router-link to="/user/user-wishlist">Wishlist</router-link>
                </li>
              </ul>
            </div>
          </div> -->
          <!-- <div
            class="col-xl-4 col-lg-4 col-md-6 col-sm-6"
            data-aos="fade-up"
            data-aos-delay="700"
          >
            <div class="footer-widget">
              <h3>Featured Categories</h3>
              <div class="row">
                <div class="col-md-6">
                  <ul class="menu-items">
                    <li>
                      <router-link to="/services/categories"
                        >Programming & Tech</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/services/categories"
                        >Music & Audio</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/services/categories">Lifestyle</router-link>
                    </li>
                    <li>
                      <router-link to="/services/categories"
                        >Photography</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/services/categories">Business</router-link>
                    </li>
                  </ul>
                </div>
                <div class="col-md-6">
                  <ul class="menu-items extra-menu">
                    <li>
                      <router-link to="/services/categories"
                        >eBook Publishing</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/services/categories"
                        >AI Artists</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/services/categories"
                        >AI Services</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/services/categories">Data</router-link>
                    </li>
                    <li>
                      <router-link to="/services/categories"
                        >Consulting</router-link
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="contact-widget">
          <div class="row align-items-center">
            <div class="col-xl-9">
              <ul class="location-list">
                <li>
                  <span><i class="feather-map-pin"></i></span>
                  <div class="location-info">
                    <h6>Address</h6>
                    <p>Algiers, Algeria</p>
                  </div>
                </li>
                <li>
                  <span><i class="feather-phone"></i></span>
                  <div class="location-info">
                    <h6>Phone</h6>
                    <p>0XX XX XX XX</p>
                  </div>
                </li>
                <li>
                  <span><i class="feather-mail"></i></span>
                  <div class="location-info">
                    <h6>Email</h6>
                    <p>info@portfolavo.com</p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-xl-3 text-xl-end">
              <div class="paypal-icons">
                <a href="javascript:void(0);">
                  <img
                    height="32"
                    src="@/assets/img/payments/baridi.webp"
                    alt="icon"
                  />
                </a>
                <!-- <a href="javascript:void(0);">
                  <img src="@/assets/img/icons/paypal-icon.svg" alt="icon" />
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="copy-right">
              <p>Copyright © 2024 Portfolavo. All rights reserved.</p>
            </div>
          </div>
          <!-- <div class="col-lg-6">
            <div class="footer-bottom-links">
              <ul>
                <li>
                  <router-link to="/privacy-policy">Privacy Policy</router-link>
                </li>
                <li>
                  <router-link to="/terms-condition"
                    >Terms & Conditions</router-link
                  >
                </li>
              </ul>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </footer>
</template>
